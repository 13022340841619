/* eslint-disable */
import { CompanyRoles } from '../company/ResponseTypes';

export interface OrganisationSyncResponse {
    updates: OrganisationSyncItem[];
    lastSynced: number;
}

export interface OrganisationSyncItem {
    key: string;
    value: string;
    entityId: string;
    entityType: OrganisationSyncItemType
}

export enum OrganisationSyncItemType {
    UserProfile = 1,
    UserAvailability = 2,
    TimePresetList = 10,
    TeamWithMembersList = 11,
    GroupWithMembersList = 12,
    LocationList = 13,
    OrganisationReceivingFrom = 14
}