/* eslint-disable */
import React from 'react';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import { Glyphicon, Image, Badge, Button, Modal, OverlayTrigger, Popover, DropdownButton, MenuItem } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import withWindowDimensions from '../components/util/withWindowDimensions';
import Calendar from './../components/availability/Calendar';
import * as ContactProfileActionCreators from '../store/contactprofile/ActionCreators';
import { fromNow } from '../constants';
import { ApplicationState } from '../store';
import ContactNudgeButton from '../components/contacts/ContactNudgeButton';
import SimpleTooltip from '../components/ui-components/SimpleTooltip';
import theme from '../css/theme';
import CalendarList from '../components/availability/CalendarList';
import ContactRemovalProvider from '../components/availability/ContactRemovalProvider';
import history from '../history';
import WorkerRater from 'src/components/rating/WorkerRater';
import RatingListOfWorker from 'src/components/rating/RatingListOfWorker';
import DocumentViewer from './DocumentViewer';
import FileAPI from '../api/file/File';
import CompanyApi from '../api/company/Company';
import ProfileApi from '../api/profile/Profile';
import OrganisationApi from '../api/organisation/Organisation';
import { toast } from 'react-toastify';
import { FileType } from 'src/store/contacts/Models';
import InternalTracker from 'src/InternalTracker';
import Utilities from 'src/Utilities';
import { VerificationTypeId } from './ExternalVerification';
import DayPicker from 'react-day-picker';
import VerificationAPI from 'src/api/verification/Verification';
import { VerificationDetailDto } from 'src/api/availability/ResponseTypes';
import { Spinner } from 'src/components/ui-components/Spinner';
import FullScreenLoader from 'src/components/ui-components/FullScreenLoader';
import ReportModal from 'src/components/profile/ReportModal';
import SimplePrompt from 'src/components/ui-components/SimplePrompt';

type ContactProfileProps = ConnectedProps<typeof connector> &
    RouteComponentProps<{ 
        contactId: string 
    }> & { 
        windowWidth: number, 
        contactId: string,
        onClosed?: () => void
    };

interface State {
    displayConfirmationModal: boolean;
    blockBecauseOfRating?: boolean;
    reload?: boolean;
    openedFileUri?: string;
    noAccessFileId?: number;
    openedFileType: FileType | null;
    openedFileId: number | null;
    openedFileName?: string;
    openedFolderId: number | null;
    ueUser: boolean;
    focused: string | null;
    modal: boolean;
    userIsAgency: boolean;
    confirmEnding?: {
        dateSelected: boolean
        type: VerificationTypeId | null;
        end: Date;
        id: number;
    },
    confirmStarting?: {
        dateSelected: boolean
        type: VerificationTypeId | null;
        start: Date;
    },
    verifications: VerificationDetailDto[];
    verificationsLoading: boolean;
    identityVerification: boolean;
    showHistoric: boolean;
    justAddedVerificationId: number | null;
    loading?: boolean;
    reportModal?: {
        id: string;
        type: number;
        mode: "admin" | "contact",
        recipientContactName?: string;
    },
    telephoneNumberInput: string;
    emailInputOne: string;
    emailInputTwo: string;
    verificationLoading: boolean;
    identityValidated: boolean;
    agencyName: string;
    confirmDeleteAllActiveVerifications?: {
        type: VerificationTypeId;
        id: number;
        end: Date;
    };
}

export class ContactProfile extends React.Component<
    ContactProfileProps,
    State
> {
    state: State = {
        displayConfirmationModal: false,
        openedFileUri: "",
        openedFileType: null,
        openedFileId: null,
        openedFileName: "",
        openedFolderId: null,
        ueUser: localStorage.getItem("user") && JSON.parse(localStorage.getItem("user") || "{}").emailAddress.endsWith("@updatedge.com"),
        focused: null,
        modal: false,
        userIsAgency: localStorage.getItem("user") && JSON.parse(localStorage.getItem("user") || "").organisationIsAgency,
        agencyName: localStorage.getItem("user") && JSON.parse(localStorage.getItem("user") || "").organisationIsAgency ? JSON.parse(localStorage.getItem("user") || "").organisationName : "",
        verifications: [],
        showHistoric: false,
        verificationsLoading: false,
        justAddedVerificationId: null,
        loading: false,
        identityVerification: false,
        telephoneNumberInput: "",
        emailInputOne: "",
        emailInputTwo: "",
        verificationLoading: false,
        identityValidated: false,
    };

    componentDidMount() {
        this.loadContact();

        (window as any).onSignalREventsUpdatedContactProfile = (userId: string) => {
            this.reloadUI();
        }

        (window as any).contactProfileStoreRequestSuccess = () => {
            this.reloadUI();
        }
    }

    componentDidUpdate(prevProps: Readonly<ContactProfileProps>, prevState: Readonly<State>, snapshot?: any): void {
        // load verifications from parent only once
        if (this.props.contact.verifications && this.state.verifications.length === 0 && this.props.contact.verifications.length !== this.state.verifications.length) {
            this.setState({
                verifications: this.props.contact.verifications
            })
        }
    }

    reloadUI = () => {
        InternalTracker.trackEvent("Contact Profile Reloaded", {
            id: this.props.match ? this.props.match.params.contactId : this.props.contactId
        });
        this.setState({
            reload: true
        }, () => {
            this.setState({
                reload: false
            })
        })
        const urlHash = window.location.href.split("#")[1];
        if (urlHash && urlHash.startsWith("file-")) {
            const fileId = parseInt(urlHash.split("-")[1]);
            const file = this.props.contact.files?.find(file => file.id === fileId);
            if (file) {
                this.setState({
                    openedFileUri: file.accessLink,
                    openedFileType: file.fileType || null,
                    openedFileId: file.id || null,
                    openedFileName: file.fileName || "",
                })
            }
        }
        this.setState({
            focused: urlHash === "rate" ? "rate" : urlHash === "verifications" ? "verifications" : null
        })
    }

    loadContact = () => {
        if (!this.props.match || !this.props.match.params || !this.props.match.params.contactId) {
            this.setState({
                modal: true
            })
            this.props.getContact(this.props.contactId);
        } else {
            this.props.getContact(this.props.match.params.contactId);
        }
    };

    openConfirmationModal = (blockBecauseOfRating?: boolean) => {
        this.setState({ displayConfirmationModal: true, blockBecauseOfRating: blockBecauseOfRating });
    };

    closeConfirmationModal = () => {
        this.setState({ displayConfirmationModal: false });
    };

    createVerification = async (typeId: VerificationTypeId, startDate: Date) => {
        const contactId = this.props.match ? this.props.match.params.contactId : this.props.contactId;
        this.setState({ verificationsLoading: true })
        const res = await VerificationAPI.createVerification(typeId, startDate.toISOString(), contactId).catch(e => {
            toast.error("Failed to create verification")
            this.setState({ verificationsLoading: false })
        })
        if (res && res.id) {
            this.setState({ 
                verificationsLoading: false,
                justAddedVerificationId: res && res.id ? res.id : null
            })
            setTimeout(() => {
                this.setState({
                    justAddedVerificationId: null
                })
            }, 2000);
            toast.success("Saved")
            if (res) {
                this.setState({
                    verifications: this.state.verifications.concat([res]),
                    confirmStarting: undefined
                })
            }
        }
    }

    endVerification = async (verificationId: number, endDate: Date, type?: VerificationTypeId, removeAll?: boolean) => {
        this.setState({ verificationsLoading: true })
        let verifications = this.state.verifications
        let res;
        if (type === VerificationTypeId.Identity) {
            // clearing all other verifications - issue needs clearing first, then vetting, and then representation, and then suitability (type 4, type 3, type 2, type 1)

            if (removeAll) {
                const verificationsSorted = this.state.verifications.filter(v => !v.endedAt).sort((a, b) => a.typeId - b.typeId).reverse();
                for (let i = 0; i < verificationsSorted.length; i++) {
                    res = await VerificationAPI.endVerification(verificationsSorted[i].id, endDate.toISOString()).catch(e => {
                        toast.error("Failed to end verification")
                    })
                    if (res) {
                        verifications = verifications.map(v => {
                            if (v.id === verificationsSorted[i].id) {
                                return res;
                            }
                            return v;
                        })
                    }
                }
            } else {
                res = await VerificationAPI.endVerification(verificationId, endDate.toISOString()).catch(e => {
                    toast.error("Failed to end verification")
                })
                if (res) {
                    verifications = verifications.map(v => {
                        if (v.id === verificationId) {
                            return res;
                        }
                        return v;
                    })
                    this.setState({ 
                        justAddedVerificationId: res && res.id ? res.id : null
                    })
                }
                setTimeout(() => {
                    this.setState({
                        justAddedVerificationId: null
                    })
                }, 2000);
            }
        } else {
            res = await VerificationAPI.endVerification(verificationId, endDate.toISOString()).catch(e => {
                toast.error("Failed to end verification")
            })
            if (res) {
                verifications = verifications.map(v => {
                    if (v.id === verificationId) {
                        return res;
                    }
                    return v;
                })
                this.setState({ 
                    justAddedVerificationId: res && res.id ? res.id : null
                })
            }
            setTimeout(() => {
                this.setState({
                    justAddedVerificationId: null
                })
            }, 2000);
        }

        if (res) {
            toast.success("Removed")
        }
        this.setState({
            verifications: verifications,
            confirmEnding: undefined,
            verificationsLoading: false
        })

        if (type === VerificationTypeId.Identity) {
            this.setState({
                reportModal: {
                    id: this.props.contact.id,
                    mode: "admin",
                    type: 1 // Profile report
                }
            })
        }
    }

    render() {
        const ratingsList = this.props.contact.externalRatings ? this.props.contact.ownOrganisationRatings?.concat(this.props.contact.externalRatings).concat(this.props.contact.ownRating ? [this.props.contact.ownRating] : []) : [];
        const loading = !this.props.contact.id;
        const hasAnyActiveVerifications = this.state.verifications && this.state.verifications.find(v => !v.endedAt && v.typeId !== VerificationTypeId.Rejected) ? true : false;

        const CONTENT_DOM = <ContactRemovalProvider
            displayConfirmationModal={this.state.displayConfirmationModal}
            blockBecauseOfRating={this.state.blockBecauseOfRating}
            closeConfirmationModal={this.closeConfirmationModal}
            onSuccess={history.goBack}
            workersToRemove={[{ contact: this.props.contact }]}
            render={() => (
                <Wrapper>
                    <FullScreenLoader loadingMessage={loading ? " " : ""} />
                    { (!this.state.focused || true) &&
                        <div className="row contact-profile" data-embedded={window.location.href.indexOf("/external/") !== -1 ? 'true' : 'false'}>
                            { (!this.props.contact.notSharing) &&
                                <div className="hpanel pageHeader">
                                    <div className="panel-body">
                                        <div className="glyph pull-right">
                                            <Glyphicon
                                                glyph="cloud"
                                                bsClass="glyphicon-6x text-primary"
                                            />
                                        </div>
                                        <div className="pull-right">
                                            <Image
                                                data-report-blurred={this.props.contact.reported}
                                                data-user-id={this.props.contact.userId}
                                                src={
                                                    this.props.contact
                                                        .profileImageUrl
                                                }
                                                circle
                                                alt={this.props.contact.fullName}
                                                style={{
                                                    width: 60,
                                                    height: 60
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <h2 className="font-light m-b-xs">
                                                <span 
                                                    data-report-blurred={this.props.contact.reported}
                                                    data-user-id={this.props.contact.userId}
                                                >
                                                    {this.props.contact.fullName}
                                                </span>
                                                <SimpleTooltip
                                                    id="contact-verified-status"
                                                    text={this.props.contact.verified ? "Profile Approved" : "Profile Awaiting Approval"}
                                                >
                                                    <i
                                                        className="fa fa-shield m-l-sm"
                                                        style={{
                                                            color: this.props
                                                                .contact.verified
                                                                ? theme.colours
                                                                    .green
                                                                : theme.colours.red,
                                                            fontSize: '22px',
                                                            opacity: this.props
                                                                .contact.verified
                                                                ? 1
                                                                : 0.3
                                                        }}
                                                    />
                                                </SimpleTooltip>
                                            </h2>
                                            {this.props.contact.headline &&
                                                <label
                                                    data-report-blurred={this.props.contact.reported}
                                                    data-user-id={this.props.contact.userId}
                                                >
                                                    {this.props.contact.headline}
                                                </label>
                                            }
                                            { ( (this.props.contact.workerAttributes && this.props.contact.workerAttributes.length > 0) || (this.props.contact.avgRating && this.props.contact.totalRatings ) ) &&
                                                <div className="contact-skills">
                                                    { this.props.contact.workerAttributes && this.props.contact.workerAttributes.map(attribute => {
                                                        return (
                                                            <div 
                                                                data-report-blurred={this.props.contact.reported}
                                                                data-user-id={this.props.contact.userId}
                                                            >
                                                                {attribute.skillName}
                                                            </div>
                                                        )
                                                    }) }
                                                    {/* { (this.props.contact.avgRating && this.props.contact.totalRatings ) &&
                                                        <div className='stars-wrapper avg-rating'>
                                                            <i className="fas fa-star"></i>
                                                            <span>{this.props.contact.avgRating} from {this.props.contact.totalRatings} ratings</span>
                                                        </div>
                                                    }    */}
                                                </div>
                                            }
                                            { this.props.contact.qualifications &&
                                                <div className="qualifications">
                                                { this.props.contact.qualifications.map((qualification, i) => {
                                                    let PFormatted = "";
                                                    let H3Formatted = "Empty Education";
                                                    if (qualification.field) {
                                                        H3Formatted = qualification.field;
                                                        if (qualification.school) {
                                                            H3Formatted += " (" + qualification.school + ")"
                                                        }
                                                    }
                                                    if (qualification.degree || qualification.grade || qualification.endDate || qualification.description) {
                                                        if (qualification.degree) PFormatted += " · " + qualification.degree;
                                                        if (qualification.grade) PFormatted += " · " + qualification.grade;
                                                        if (qualification.endDate && qualification.endDate.split("-").length === 2) {
                                                            const month = parseInt(qualification.endDate.split("-")[1]);
                                                            const shortMonth = month === 1 ? "Jan" : month === 2 ? "Feb" : month === 3 ? "Mar" : month === 4 ? "Apr" : month === 5 ? "May" : month === 6 ? "Jun" : month === 7 ? "Jul" : month === 8 ? "Aug" : month === 9 ? "Sep" : month === 10 ? "Oct" : month === 11 ? "Nov" : "Dec"
                                                            PFormatted += " · " + shortMonth + " " + qualification.endDate.split("-")[0];
                                                        }
                                                        if (qualification.description) PFormatted += " · " + qualification.description;
                                                        PFormatted = PFormatted.substr(2, PFormatted.length-1)
                                                    }
                                                    return (
                                                        <div
                                                            data-report-blurred={this.props.contact.reported}
                                                            data-user-id={this.props.contact.userId}
                                                        >
                                                            {H3Formatted} {PFormatted}
                                                        </div>
                                                    )
                                                }) }
                                                </div>
                                            }
                                            { (!this.state.userIsAgency && this.props.contact.verifications && this.state.verifications && this.state.verifications.find(v => v.organisationId || v.contactEmail)) &&
                                                <div className='represented-by'>
                                                    <div className='orgs'>
                                                        { [...new Set(this.state.verifications.filter(v => (v.organisationId || v.contactEmail) && v.organisationName).map(v => v.organisationName))].map(organisationName => {
                                                            const organisationVerificationRecords = this.state.verifications ? this.state.verifications.filter(v => (v.organisationId || v.contactEmail) && v.organisationName === organisationName) : [];
                                                            let verificationTypes = organisationVerificationRecords.filter(v => !v.endedAt).map(v => v.typeId);

                                                            const identityVerified = organisationVerificationRecords.find(v => v.typeId === VerificationTypeId.Identity && !v.end);
                                                            const identityVerifiedStartFormatted = identityVerified ? Utilities.formatDate(new Date(identityVerified.start), "DD MMM YYYY") : "";
                                                            const identityVerificationEndFormatted = identityVerified && identityVerified.end ? Utilities.formatDate(new Date(identityVerified.end), "DD MMM YYYY") : "";

                                                            const representationVerified = organisationVerificationRecords.find(v => v.typeId === VerificationTypeId.Representation && !v.end);
                                                            const representationVerifiedStartFormatted = representationVerified ? Utilities.formatDate(new Date(representationVerified.start), "DD MMM YYYY") : "";
                                                            const representationVerificationEndFormatted = representationVerified && representationVerified.end ? Utilities.formatDate(new Date(representationVerified.end), "DD MMM YYYY") : "";

                                                            const vettingVerified = organisationVerificationRecords.find(v => v.typeId === VerificationTypeId.Vetting && !v.end);
                                                            const vettingVerifiedStartFormatted = vettingVerified ? Utilities.formatDate(new Date(vettingVerified.start), "DD MMM YYYY") : "";
                                                            const vettingVerificationEndFormatted = vettingVerified && vettingVerified.end ? Utilities.formatDate(new Date(vettingVerified.end), "DD MMM YYYY") : "";

                                                            const suspended = organisationVerificationRecords.find(v => v.typeId === VerificationTypeId.Suspension && !v.end);
                                                            const suspendedStartFormatted = suspended ? Utilities.formatDate(new Date(suspended.start), "DD MMM YYYY") : "";
                                                            const suspendedEndFormatted = suspended && suspended.end ? Utilities.formatDate(new Date(suspended.end), "DD MMM YYYY") : "";

                                                            const atLeastOneRepresentationEndedInLast6Months = !representationVerified && organisationVerificationRecords.find(v => v.typeId === VerificationTypeId.Representation && v.end && moment(v.end).toDate() > Utilities.dateSub(new Date(), "month", 6));

                                                            let verificationSubTitle = 
                                                                <div className='verification-tooltip-wrapper'>
                                                                    { (verificationTypes.includes(VerificationTypeId.Identity)) && 
                                                                        <div>Profile Approved {/*from {identityVerifiedStartFormatted} - {identityVerificationEndFormatted}</div> */}</div>
                                                                    }
                                                                    { (verificationTypes.includes(VerificationTypeId.Representation)) && 
                                                                        <div>Represented {/*from {representationVerifiedStartFormatted} - {representationVerificationEndFormatted}</div> */}</div>
                                                                    }
                                                                    { (atLeastOneRepresentationEndedInLast6Months && !verificationTypes.includes(VerificationTypeId.Representation)) &&
                                                                        <div>Previously Represented</div>
                                                                    }
                                                                    { (verificationTypes.includes(VerificationTypeId.Vetting)) && 
                                                                        <div>Vetted {/*from {vettingVerifiedStartFormatted} - {vettingVerificationEndFormatted}</div> */}</div>
                                                                    }
                                                                    { (verificationTypes.includes(VerificationTypeId.Suspension)) && 
                                                                        <div>Issue Raised {/*from {suspendedStartFormatted} - {suspendedEndFormatted}</div> */}</div>
                                                                    }
                                                                    <div>{ " by " + organisationName}</div>
                                                                </div>

                                                            const imageUrl = 
                                                                organisationVerificationRecords[0].organisationId ? CompanyApi.getOrganisationProfileImageUrl(organisationVerificationRecords[0].organisationId) :
                                                                organisationVerificationRecords[0].contactEmail ? OrganisationApi.getExtOrgPicture(organisationVerificationRecords[0].contactEmail) :
                                                                null

                                                            return (
                                                                <SimpleTooltip
                                                                    id="contact-represented-by"
                                                                    text={verificationSubTitle}
                                                                >
                                                                    <div className='org'>
                                                                        <img src={imageUrl || ""} />
                                                                        <div className='status' data-status={atLeastOneRepresentationEndedInLast6Months ? 10 : suspended ? 4 : 3}>
                                                                            <i className={atLeastOneRepresentationEndedInLast6Months ? "fas fa-hourglass" : suspended ? "fa fa-ban" : "fa fa-check"} />
                                                                        </div>
                                                                        <p className='name'>{organisationName}</p>
                                                                    </div>
                                                                </SimpleTooltip>
                                                            )
                                                        }) }
                                                    </div>
                                                </div>
                                            }
                                            { this.props.contact.files && this.props.contact.files.length !== 0 &&
                                                <div className='files'>
                                                    { (this.state.openedFolderId) &&
                                                        <div 
                                                            data-report-blurred={this.props.contact.reported} 
                                                            className='folder' 
                                                            data-user-id={this.props.contact.userId}
                                                            onClick={() => {
                                                                this.setState({
                                                                    openedFolderId: null
                                                                })
                                                            }}
                                                        >
                                                            <div
                                                                className='preview'
                                                            >
                                                                <div>
                                                                    <i 
                                                                        className="fas fa-level-up-alt"
                                                                        style={{
                                                                            "fontSize": 32,
                                                                            "transform": "rotateY(180deg)",
                                                                            "marginBottom": 10,
                                                                            "position": "relative",
                                                                            "right": 4
                                                                        }}
                                                                    ></i>
                                                                    <label>Exit Folder</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    { this.props.contact.folders?.map(folder => {
                                                        if (this.state.openedFolderId) {
                                                            return null;
                                                        }
                                                        return (
                                                            <div className='folder' data-name={folder.name} onClick={() => {
                                                                this.setState({
                                                                    openedFolderId: folder.id
                                                                })
                                                            }}>
                                                                <div
                                                                    className='preview'
                                                                >
                                                                    <div>
                                                                        <div className='file-format'>
                                                                            <i className="fas fa-folder"></i>
                                                                            {/* <label>{folder.name}</label> */}
                                                                        </div>
                                                                        <label className='file-name'>{folder.name}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) }
                                                    { this.props.contact.files.map(file => {
                                                        if ( (this.state.openedFolderId && file.folderId !== this.state.openedFolderId) || (!this.state.openedFolderId && file.folderId) ) {
                                                            return null;
                                                        }
                                                        return (
                                                            <div 
                                                                data-report-blurred={this.props.contact.reported}
                                                                data-user-id={this.props.contact.userId}
                                                                className='file'
                                                                data-name={file.fileName}
                                                                data-has-access={file.accessLink ? "true" : "false"}
                                                                style={ file.previewAccessLink ? { backgroundImage: 'url(' + file.previewAccessLink + ')' } : {} }
                                                                onClick={() => {
                                                                    if (!file.accessLink) {
                                                                        this.setState({
                                                                            noAccessFileId: file.id
                                                                        })
                                                                    } else {
                                                                        if (
                                                                            ["docx", "doc", "jpg", "jpeg", "png", "pdf", "ppt", "pptx", "xls", "xlsx"].indexOf(file.fileFormat || "") !== -1 ||
                                                                            file.fileType === FileType.VideoLink || 
                                                                            file.fileType === FileType.Link
                                                                        ) {
                                                                            this.setState({
                                                                                openedFileUri: file.accessLink,
                                                                                openedFileType: file.fileType || null
                                                                            })                                                                    
                                                                        } else {
                                                                            window.open(file.accessLink)
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <div
                                                                    className='preview'
                                                                >
                                                                    <div style={ !file.previewAccessLink ? { display: 'block' } : {} }>
                                                                        <div className='file-format'>
                                                                            { (file.fileType === FileType.TextDocument || file.fileType === FileType.Image) &&
                                                                                <i className="fas fa-file"></i>
                                                                            }
                                                                            { (file.fileType === FileType.VideoLink) &&
                                                                                <i className="fas fa-video"></i>
                                                                            }
                                                                            { (file.fileType === FileType.Link) &&
                                                                                <i className="fas fa-globe"></i>
                                                                            }
                                                                            { (file.fileFormat) &&
                                                                                <label>{file.fileFormat}</label>
                                                                            }
                                                                        </div>
                                                                        <label className='file-name'>{file.fileName}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) }
                                                </div>
                                            }
                                            <Modal
                                                show={this.state.noAccessFileId !== undefined}
                                                onHide={() => { this.setState({ noAccessFileId: undefined }) } }
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                        You don't have access to this private file.
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Footer>
                                                    <Button onClick={() => { this.setState({ noAccessFileId: undefined }) }} >
                                                        Dismiss
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            InternalTracker.trackEvent("File Requested", {
                                                                contactId: this.props.contact.id,
                                                                fileId: this.state.noAccessFileId
                                                            })
                                                            FileAPI
                                                                .requestFile(this.state.noAccessFileId || 0)
                                                                .then(() => {
                                                                    toast.success("File Requested")
                                                                })
                                                                .catch(() => {
                                                                    toast.success("Failed to request file")
                                                                })
                                                            this.setState({
                                                                noAccessFileId: undefined
                                                            })
                                                        }}
                                                    >
                                                        Request Access to File
                                                    </Button>
                                                    { (this.state.openedFolderId) &&
                                                        <Button
                                                            onClick={() => {
                                                                InternalTracker.trackEvent("Folder Requested", {
                                                                    contactId: this.props.contact.id,
                                                                    fileId: this.state.noAccessFileId
                                                                })
                                                                FileAPI
                                                                    .requestFileFolder(this.state.openedFolderId || 0)
                                                                    .then(() => {
                                                                        toast.success("Folder Requested")
                                                                    })
                                                                    .catch(() => {
                                                                        toast.success("Failed to request folder")
                                                                    })
                                                                this.setState({
                                                                    noAccessFileId: undefined
                                                                })
                                                            }}
                                                        >
                                                            Request Access to Folder
                                                        </Button>
                                                    }
                                                </Modal.Footer>
                                            </Modal>
                                            { (this.state.openedFileUri) &&
                                                <DocumentViewer
                                                    uri={this.state.openedFileUri}
                                                    type={this.state.openedFileType}
                                                    id={this.state.openedFileId}
                                                    name={this.state.openedFileName || ""}
                                                    close={() => {
                                                        this.setState({
                                                            openedFileUri: undefined
                                                        })
                                                    }}
                                                />
                                            }
                                            <div
                                                className="text-muted font-bold m-b-xs"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    marginBottom: 12
                                                }}
                                            >
                                                Last Availability Update:{' '}
                                                <Badge
                                                    style={{
                                                        marginLeft: '5px',
                                                        backgroundColor: this.props.contact.lastAvailabilityUpdateOn && moment(this.props.contact.lastAvailabilityUpdateOn).toDate() < Utilities.dateSub(new Date(), "month", 1) ? theme.colours.red2 : undefined
                                                    }}
                                                >
                                                    { !this.state.ueUser && this.props.contact.lastAvailabilityUpdateOn && moment(this.props.contact.lastAvailabilityUpdateOn).toDate() < Utilities.dateSub(new Date(), "month", 1) ?
                                                        "More than 1 month ago" :
                                                        !this.props.contact.lastAvailabilityUpdateOn ? 
                                                            "Never" : 
                                                            Utilities.timeSince(moment(this.props.contact.lastAvailabilityUpdateOn).toDate())
                                                    }
                                                </Badge>
                                            </div>
                                            <div className="options">
                                                <ContactNudgeButton
                                                    contactId={
                                                        this.props.contact.id
                                                    }
                                                    class=""
                                                />
                                                {this.props.contact &&
                                                    this.props.contact.userId && (
                                                        <div onClick={() => { this.openConfirmationModal() }}>
                                                            <i
                                                                aria-label="Remove Contact"
                                                                title="Remove Contact"
                                                                style={{ marginLeft: '5px' }}
                                                                className="fa fa-user-times fa-hover"
                                                            />
                                                            <span>Remove Contact</span>
                                                        </div>
                                                    )}
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        InternalTracker.trackEvent("Send Offer Button", {
                                                            source: "contact"
                                                        });
                                                        if (localStorage.getItem("timesheet-otp-link")) {
                                                            window.open(localStorage.getItem("timesheet-otp-link") || "")
                                                        } else {
                                                            window.open("/offers#create/worker/" + this.props.contact.userId);
                                                        }
                                                    }}
                                                >
                                                    <i 
                                                        className="fas fa-briefcase-medical"
                                                    />
                                                    <span>Send Offer</span>
                                                </div>
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        InternalTracker.trackEvent("Report User Profile Inidiated");
                                                        this.setState({
                                                            reportModal: {
                                                                id: this.props.contact.id,
                                                                type: 1, // Profile report
                                                                mode: "admin"
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <i className="fas fa-bug"></i>
                                                    <span>Report Unsuitable Profile</span>
                                                </div>
                                                {this.props.contact &&
                                                    this.props.contact.userId && (
                                                        <div
                                                            className="contact-btn"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                InternalTracker.trackEvent("Open Chat Button", {
                                                                    source: "contact"
                                                                });
                                                                if (localStorage.getItem("timesheet-otp-link")) {
                                                                    window.open(localStorage.getItem("timesheet-otp-link") || "")
                                                                } else {
                                                                    const chatBtn = document.getElementById("chat-btn");
                                                                    if ((window as any).openChatForUser && chatBtn) {
                                                                        if (!document.querySelector('.floating-btn.shown')) {
                                                                            chatBtn.click();
                                                                        }
                                                                        (window as any).openChatForUser(this.props.contact.userId);
                                                                    } else {
                                                                        window.open(`/chat/${this.props.contact.userId}`);
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <i
                                                                className="fa fa-comments m-r-xs"
                                                                style={{
                                                                    fontSize:
                                                                        '16px'
                                                                }}
                                                            />
                                                            <span>Open Chat</span>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    { (this.state.userIsAgency && this.state.verifications && this.props.contact.verifications) &&
                        <div className="row own-verifications" data-embedded={window.location.href.indexOf("/external/") !== -1 ? 'true' : 'false'}>
                            { (this.state.verificationsLoading) &&
                                <FullScreenLoader loadingMessage={" "} />
                            }
                            { (this.state.identityVerification && !this.state.identityValidated && (this.props.contact.maskedEmailAddress || this.props.contact.maskedPhoneNumber)) &&
                                <div className='identity-verification-wrapper'>
                                    <div className='mask' onClick={() => {
                                        this.setState({
                                            identityVerification: false
                                        })
                                    }}></div>
                                    <div className='verification'>
                                        <p>Verify {this.props.contact.firstName}'s' identity by completing the missing characters in their email {(this.props.contact.maskedPhoneNumber && this.props.contact.maskedPhoneNumber[0] && this.props.contact.maskedPhoneNumber[1]) ? " or phone number" : ""}</p>

                                        <div className='body identity'>
                                            <div className='sensitive-information'>
                                                { (this.props.contact.maskedPhoneNumber && this.props.contact.maskedPhoneNumber[0] && this.props.contact.maskedPhoneNumber[1]) &&
                                                    <React.Fragment>
                                                        <div className='info'>
                                                            <i className="fas fa-phone"></i>
                                                            <span>+{this.props.contact.maskedPhoneNumber[0]}</span>
                                                            <input type='text' value={this.state.telephoneNumberInput} onChange={e => {
                                                                this.setState({ telephoneNumberInput: e.target.value });
                                                            }} />
                                                            <span>{this.props.contact.maskedPhoneNumber[1]}</span>
                                                        </div>
                                                        <div className='or'>
                                                            <span>or</span>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                { (this.props.contact.maskedEmailAddress) &&
                                                    <div className='info'>
                                                        <i className="fas fa-envelope"></i>
                                                        <span>{this.props.contact.maskedEmailAddress[0]}</span>
                                                        <input type='text' value={this.state.emailInputOne} onChange={(e) => {
                                                            this.setState({ emailInputOne: e.target.value });
                                                        }} />
                                                        <span>{this.props.contact.maskedEmailAddress[1]}@{this.props.contact.maskedEmailAddress[2]}</span>
                                                        <input type='text' value={this.state.emailInputTwo} onChange={(e) => {
                                                            this.setState({ emailInputTwo: e.target.value });
                                                        }} />
                                                        <span>{this.props.contact.maskedEmailAddress[3]}</span>

                                                    </div>
                                                }
                                                { (this.state.verificationLoading) &&
                                                    <Spinner text="Validating..." hide={false} />
                                                }
                                                <div className='options'>
                                                    <button className='suitable' onClick={ async () => {
                                                        const filledPhoneNumber = (this.state.telephoneNumberInput && this.props.contact.maskedPhoneNumber) ? this.props.contact.maskedPhoneNumber[0] + this.state.telephoneNumberInput + this.props.contact.maskedPhoneNumber[1] : "";
                                                        const filledEmail = (this.state.emailInputOne && this.state.emailInputTwo && this.props.contact.maskedEmailAddress) ? (this.props.contact.maskedEmailAddress[0] +  this.state.emailInputOne + this.props.contact.maskedEmailAddress[1] + "@" + this.props.contact.maskedEmailAddress[2] + this.state.emailInputTwo + this.props.contact.maskedEmailAddress[3]) : "";
                                                        let validated = false;

                                                        if (!filledPhoneNumber && !filledEmail) {
                                                            toast.error("Please fill in at least one of the above fields");
                                                            return;
                                                        }

                                                        this.setState({
                                                            verificationLoading: true
                                                        })

                                                        if (filledPhoneNumber) {
                                                            const isValid = await VerificationAPI.validateInformationAuth(this.props.contact.userId, "phone", filledPhoneNumber).catch(err => {})
                                                            if (isValid) {
                                                                validated = true;
                                                            }
                                                        }

                                                        if (filledEmail) {
                                                            const isValid = await VerificationAPI.validateInformationAuth(this.props.contact.userId, "email", filledEmail).catch(err => {})
                                                            if (isValid) {
                                                                validated = true;
                                                            }
                                                        }

                                                        if (!validated) {
                                                            toast.error('The information you provided does not match our records');
                                                            this.setState({
                                                                verificationLoading: false
                                                            })
                                                        } else {
                                                            toast.success('Information validated successfully');
                                                            this.createVerification(VerificationTypeId.Identity, new Date());
                                                            this.setState({
                                                                verificationLoading: false,
                                                                identityValidated: true,
                                                                identityVerification: false
                                                            })
                                                        }
                                                    }}>Verify Information</button>
                                                    <button className='unsuitable' onClick={() => {
                                                        this.setState({
                                                            reportModal: {
                                                                id: this.props.contact.id,
                                                                type: 1, // Profile report
                                                                mode: "contact",
                                                                recipientContactName: this.props.contact.firstName
                                                            }
                                                        })
                                                    }}>I don't have any of the above information</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            { (true) && /* TODO restore once verifications are ready*/
                            <div className="hpanel pageHeader">
                                <div className="panel-body">
                                    <h2 className="font-light m-b-xs">
                                        <span>Screening</span>
                                    </h2>
                                    <div className='table'>
                                        <div className='thead'>
                                            <div className='verification-row tr'>
                                                <div className='th'>Type</div>
                                                <div className='th'>Start Date</div>
                                                <div className='th'>End Date</div>
                                                <div className='th'></div>
                                            </div>
                                        </div>
                                        <div className="tbody">
                                            { (this.state.verifications.find(v => v.typeId === VerificationTypeId.Rejected)) && !hasAnyActiveVerifications &&
                                                <div className="verification-row rejected">
                                                    <span>
                                                        <img src={ProfileApi.getProfileImageUrl(this.state.verifications.find(v => v.typeId === VerificationTypeId.Rejected)?.verifyingUserId || "")} />
                                                        <span>{this.state.verifications.find(v => v.typeId === VerificationTypeId.Rejected)?.verifyingUserName} have previously rejected the verification request by marking as unsuitable. Your contact can re-request it in the future</span>
                                                    </span>
                                                </div>
                                            }
                                            { [VerificationTypeId.Identity, VerificationTypeId.Representation, VerificationTypeId.Vetting, VerificationTypeId.Suspension].map(typeId => {
                                                const active = this.state.verifications?.find(v => v.typeId === typeId && !v.end);
                                                if (active) {
                                                    return null;
                                                }

                                                return (
                                                    <div 
                                                        key={"tv-" + typeId}
                                                        className='verification-row tr'
                                                        style={ ( (this.state.confirmEnding || this.state.confirmStarting) && (this.state.confirmStarting?.type === typeId) ) || (!this.state.confirmEnding && !this.state.confirmStarting) ? { } : { opacity: 0.3 } }
                                                    >
                                                        <div className="td">
                                                            <div>
                                                                <i
                                                                    data-active="false" 
                                                                    className={"fas fa-" + (typeId === VerificationTypeId.Identity ? "id-badge" : typeId === VerificationTypeId.Representation ? "handshake" : typeId === VerificationTypeId.Vetting ? "clipboard-check" : "exclamation-circle" )}
                                                                    style={{
                                                                        color: typeId === VerificationTypeId.Suspension ? "gainsboro" : undefined
                                                                    }}
                                                                ></i>
                                                                <span>
                                                                    {
                                                                        (typeId === VerificationTypeId.Identity ? `Is ${this.props.contact.firstName}'s Profile Suitable` : "") +
                                                                        (typeId === VerificationTypeId.Representation ? `Do you currently represent ${this.props.contact.firstName}` : "") + 
                                                                        (typeId === VerificationTypeId.Vetting ? `All all necessary sector specific compliance requirements met for ${this.props.contact.firstName} ?` : "") +
                                                                        (typeId === VerificationTypeId.Suspension ? `Is there a temporary issue for ${this.props.contact.firstName} that needs explanation.` : "")
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        { (this.state.confirmStarting && this.state.confirmStarting.type === typeId) ?
                                                            <React.Fragment>
                                                                <div className="td">
                                                                    { (this.state.confirmStarting && this.state.confirmStarting.type !== VerificationTypeId.Suspension) &&
                                                                        <OverlayTrigger
                                                                            rootClose
                                                                            trigger="click"
                                                                            placement="top"
                                                                            overlay={
                                                                                <Popover
                                                                                    id="daypicker-popover"
                                                                                    className="popover-no-margin"
                                                                                >
                                                                                    <DayPickerWrapper>
                                                                                        <DayPicker
                                                                                            initialMonth={new Date()}
                                                                                            firstDayOfWeek={1}
                                                                                            selectedDays={this.state.confirmStarting.start}
                                                                                            onDayClick={(day: Date) => {
                                                                                                this.setState({
                                                                                                    confirmStarting: {
                                                                                                        type: typeId,
                                                                                                        start: day,
                                                                                                        dateSelected: true,
                                                                                                    }
                                                                                                })
                                                                                            }}  
                                                                                            onTodayButtonClick={() => {
                                                                                                this.setState({
                                                                                                    confirmStarting: {
                                                                                                        type: typeId,
                                                                                                        start: new Date(),
                                                                                                        dateSelected: true,
                                                                                                    }
                                                                                                })
                                                                                            }}
                                                                                            todayButton="Today"
                                                                                        />
                                                                                    </DayPickerWrapper>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <button className={'input' + (!this.state.confirmStarting.dateSelected ? " pulse no-size" : "")}>
                                                                                <i className="fas fa-calendar-alt"></i>
                                                                                <span>{this.state.confirmStarting.dateSelected ? Utilities.formatDate(new Date(this.state.confirmStarting.start), "DD MMM YYYY") : "Set Start Date"}</span>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    }
                                                                </div>
                                                                <div className="td">
                                                                    
                                                                </div>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <div className="td empty"> </div>
                                                                <div className="td empty"> </div>
                                                            </React.Fragment>
                                                        }
                                                        <div className={"td " + (!this.state.confirmStarting && typeId === VerificationTypeId.Identity && !this.state.verifications.find(v => v.typeId === VerificationTypeId.Rejected) ? " multi-line" : "")}>
                                                            <div className='toggle-button'>
                                                                <button 
                                                                    className={"start" + (this.state.confirmStarting && this.state.confirmStarting.type === typeId && this.state.confirmStarting.dateSelected ? " pulse no-size" : "")}
                                                                    onClick={() => {
                                                                        const identityVerified = this.state.verifications.find(v => v.typeId === VerificationTypeId.Identity && !v.end);
                                                                        if (!identityVerified) {
                                                                            if (typeId !== VerificationTypeId.Identity) {
                                                                                toast.error("You must verify the identity of the contact first")
                                                                            } else {
                                                                                if (this.props.contact.invitedByOrg) {
                                                                                    this.createVerification(VerificationTypeId.Identity, new Date()); 
                                                                                } else {
                                                                                    this.setState({
                                                                                        identityVerification: true
                                                                                    })
                                                                                }
                                                                            }
                                                                        } else if (typeId === VerificationTypeId.Vetting && !this.state.verifications?.find(v => v.typeId === VerificationTypeId.Representation && !v.end)) {
                                                                            toast.error("You must represent the contact first")
                                                                        } else if (typeId === VerificationTypeId.Suspension && !this.state.verifications?.find(v => v.typeId === VerificationTypeId.Representation && !v.end)) {
                                                                            toast.error("To raise an issue, you must have an active representation")
                                                                        } else if (this.state.confirmStarting && this.state.confirmStarting.type === typeId) {
                                                                            this.createVerification(this.state.confirmStarting.type, this.state.confirmStarting.start)
                                                                        } else {
                                                                            this.setState({
                                                                                confirmStarting: {
                                                                                    type: typeId,
                                                                                    start: new Date(),
                                                                                    dateSelected: false
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                    data-active={(this.state.confirmStarting && this.state.confirmStarting.type === typeId) ? "true": "false"}
                                                                    data-confirm={(this.state.confirmStarting && this.state.confirmStarting.type === typeId) ? "true": "false"}
                                                                >
                                                                    { (this.state.confirmStarting && this.state.confirmStarting.type === typeId) ? "Confirm" : "Yes" } {/* (( typeId === VerificationTypeId.Representation ? "Start Representing" : typeId === VerificationTypeId.Vetting ? "Start Vetting" : typeId === VerificationTypeId.Suspension ? "Raise Issue" : "Verify Identity" ) ) } */}
                                                                </button>
                                                                { (!this.state.confirmStarting) &&
                                                                    <button data-active="true" onClick={() => {
                                                                        this.setState({
                                                                            reportModal: {
                                                                                id: this.props.contact.id,
                                                                                type: 1, // Profile report
                                                                                mode: "contact",
                                                                                recipientContactName: this.props.contact.firstName
                                                                            }
                                                                        })
                                                                    }}>
                                                                        No
                                                                    </button>
                                                                }
                                                            </div>
                                                            { (!this.state.confirmEnding && !this.state.confirmStarting) &&
                                                                <SimpleTooltip
                                                                    id={"verification-help-" + typeId}
                                                                    text={
                                                                        typeId === VerificationTypeId.Identity ? 
                                                                            <div style={{ textAlign: 'left' }}>
                                                                                <span style={{ fontWeight: 700, marginBottom: 10 }}>Confirm you know {this.props.contact.firstName} and confirm their profile is suitable</span>
                                                                                <ul>
                                                                                    <li>Contacts Image is: recognizable, & clear with 80% head 20% shoulders.</li>
                                                                                    <li>Headline: Short factual sentence covering core sector/s, skills, & experience.</li>
                                                                                    <li>Skills: Core skills for their sector/s have been added</li>
                                                                                    <li>Qualifications:  Core qualifications for their sector/s have been added.</li>
                                                                                </ul>
                                                                            </div> : 
                                                                        typeId === VerificationTypeId.Representation ? ("Is " + this.state.agencyName + " representing " + this.props.contact.firstName + "?") :
                                                                        typeId === VerificationTypeId.Vetting ? (this.state.agencyName + " confirm " + this.props.contact.firstName + " met all sector specific compliance requirements e.g. right to work, safeguarding, etc") :
                                                                        "Temporarily raise an issue about " + this.props.contact.firstName + " (will show an exclamation mark for hirers indicating the contact is not available to book for the time being)"
                                                                    }
                                                                >
                                                                    <i className="fas fa-question-circle"></i>
                                                                </SimpleTooltip>
                                                            }
                                                            { ((this.state.confirmStarting && this.state.confirmStarting.type === typeId)) &&
                                                                <button 
                                                                    onClick={() => { this.setState({ confirmStarting: undefined }) }}
                                                                    style={{ minWidth: "unset", width: "unset" }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>    
                                                )
                                            }) }
                                            { this.state.verifications.sort((a, b) => {
                                                // always put active ones at top (no endedAt date), and then sort by existennce of id (onces with id at top)
                                                if (!a.end && b.end) {
                                                    return -1;
                                                }
                                                if (a.end && !b.end) {
                                                    return 1;
                                                }
                                                if (a.end && b.end) {
                                                    return 0;
                                                }
                                                return a.id - b.id;
                                            }).map(verification => {
                                                const active = !verification.end;

                                                if (!active && !this.state.showHistoric) {
                                                    return null;
                                                }

                                                if (verification.typeId === VerificationTypeId.Rejected) {
                                                    return null;
                                                }

                                                return (
                                                    <div 
                                                        key={"v-" + verification.id}
                                                        className='verification-row tr'
                                                        data-just-added={this.state.justAddedVerificationId && this.state.justAddedVerificationId === verification.id}
                                                        style={ ( (this.state.confirmEnding || this.state.confirmStarting) && (this.state.confirmEnding?.id === verification.id) ) || (!this.state.confirmEnding && !this.state.confirmStarting) ? { } : { opacity: 0.3 } }
                                                    >
                                                        <div className="td">
                                                            <div>
                                                                <i 
                                                                    data-active={active ? "true" : "false"} 
                                                                    className={"fas fa-" + (verification.typeId === VerificationTypeId.Identity ? "id-badge" : verification.typeId === VerificationTypeId.Representation ? "handshake" : verification.typeId === VerificationTypeId.Suspension ? "exclamation-circle" : "clipboard-check" )}
                                                                    style={{
                                                                        color: verification.typeId === VerificationTypeId.Suspension ? theme.colours.red2 : undefined
                                                                    }}
                                                                ></i>
                                                                <span
                                                                    style={{
                                                                        color: verification.typeId === VerificationTypeId.Suspension ? theme.colours.red2 : undefined
                                                                    }}
                                                                >
                                                                    {
                                                                        (verification.typeId === VerificationTypeId.Identity ? "Approved & Suitable Profile" : "") +
                                                                        (verification.typeId === VerificationTypeId.Representation ? "Represented" : "") + 
                                                                        (verification.typeId === VerificationTypeId.Suspension ? "Active Issue" : "") +
                                                                        (verification.typeId === VerificationTypeId.Vetting ? "Compliance Requirements Met" : "")
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {verification.start ? 
                                                        <div className="td">
                                                        <SimpleTooltip
                                                            id={"started-by-" + verification.verifyingContactId}
                                                            text={"Added by " + verification.verifyingUserName}
                                                        >
                                                            <div>
                                                                <span className='guide-label'>Started on</span>
                                                                <span>{moment(verification.start).format("DD MMM YYYY")} by </span>
                                                                <img src={verification.verifyingUserId ? ProfileApi.getProfileImageUrl(verification.verifyingUserId) + "?v=2" : ""} />
                                                            </div>
                                                        </SimpleTooltip> 
                                                        </div> : ""}
                                                        <div className="td">{verification.end ? 
                                                            <SimpleTooltip
                                                                id={"ended-by-" + verification.endedByUserId}
                                                                text={"Ended by " + verification.endedByUserName}
                                                            >
                                                                <div>
                                                                    <span className='guide-label'>Ended on</span>
                                                                    <span>{moment(verification.end).format("DD MMM YYYY")} by </span>
                                                                    <img src={verification.endedByUserId ? ProfileApi.getProfileImageUrl(verification.endedByUserId) + "?v=2" : ""} />
                                                                </div> 
                                                            </SimpleTooltip>
                                                            : 
                                                            this.state.confirmEnding && this.state.confirmEnding.id === verification.id ?
                                                            ( this.state.confirmEnding.type === VerificationTypeId.Suspension ? null : <OverlayTrigger
                                                                rootClose
                                                                trigger="click"
                                                                placement="top"
                                                                overlay={
                                                                    <Popover
                                                                        id="daypicker-popover"
                                                                        className="popover-no-margin"
                                                                    >
                                                                        <DayPickerWrapper>
                                                                            <DayPicker
                                                                                initialMonth={new Date()}
                                                                                firstDayOfWeek={1}
                                                                                selectedDays={this.state.confirmEnding.end}

                                                                                onDayClick={(day: Date) => {
                                                                                    this.setState({
                                                                                        confirmEnding: {
                                                                                            type: verification.typeId,
                                                                                            end: day,
                                                                                            id: verification.id,
                                                                                            dateSelected: true
                                                                                        }
                                                                                    });
                                                                                }}  
                                                                                onTodayButtonClick={() => {
                                                                                    this.setState({
                                                                                        confirmEnding: {
                                                                                            type: verification.typeId,
                                                                                            end: new Date(),
                                                                                            id: verification.id,
                                                                                            dateSelected: true
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                todayButton="Today"
                                                                            />
                                                                        </DayPickerWrapper>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <button className={'input ' + (!this.state.confirmEnding.dateSelected ? "pulse no-size" : "")}>
                                                                    <i className="fas fa-calendar-alt"></i>
                                                                    <span>{this.state.confirmEnding.dateSelected ? Utilities.formatDate(new Date(this.state.confirmEnding.end), "DD MMM YYYY") : "Set End Date"}</span>
                                                                </button>
                                                            </OverlayTrigger>) :
                                                            <span></span>
                                                        }</div>
                                                        <div className="td">
                                                            { (!verification.end) ?
                                                                <React.Fragment>
                                                                    <div className='toggle-button'>
                                                                        <button 
                                                                            data-active="true"
                                                                            className={"end " + (this.state.confirmEnding && this.state.confirmEnding.id === verification.id && this.state.confirmEnding.dateSelected ? "pulse no-size" : "")}
                                                                            onClick={() => {
                                                                                if (this.state.confirmEnding && this.state.confirmEnding.id === verification.id) {
                                                                                    this.setState({
                                                                                        confirmDeleteAllActiveVerifications: {
                                                                                            id: verification.id,
                                                                                            type: this.state.confirmEnding.type || verification.typeId,
                                                                                            end: this.state.confirmEnding.end
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }}
                                                                            style={{ width: "unset", minWidth: "unset" }}
                                                                            data-confirm={(this.state.confirmEnding && this.state.confirmEnding.id === verification.id) ? "true": "false"}
                                                                        >
                                                                            { this.state.confirmEnding && this.state.confirmEnding.id === verification.id ? ("Confirm") : ( "Yes" /*( verification.typeId === VerificationTypeId.Representation ? "End Representation" : verification.typeId === VerificationTypeId.Vetting ? "Expire Vetting" : verification.typeId === VerificationTypeId.Suspension ? "Remove Issue" : "Unsuitable Profile" ) */) }
                                                                        </button>
                                                                        { (!(this.state.confirmEnding && this.state.confirmEnding.id === verification.id)) &&
                                                                            <button data-active="false" onClick={() => {
                                                                                if (verification.typeId === VerificationTypeId.Representation && this.state.verifications.find(v => v.typeId === VerificationTypeId.Vetting && !v.end)) {
                                                                                    toast.error("You need to end the vetting verification first")
                                                                                    return;
                                                                                }

                                                                                this.setState({
                                                                                    confirmEnding: {
                                                                                        type: verification.typeId,
                                                                                        end: new Date(),
                                                                                        id: verification.id,
                                                                                        dateSelected: false
                                                                                    }
                                                                                })
                                                                            }}>
                                                                                No
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                    { ((this.state.confirmEnding && this.state.confirmEnding.id === verification.id)) &&
                                                                        <button 
                                                                            onClick={() => { this.setState({ confirmEnding: undefined }) }}
                                                                            style={{ marginTop: 5, minWidth: "unset", width: "unset" }}
                                                                        >Cancel</button>
                                                                    }
                                                                    <SimpleTooltip
                                                                        id={"verification-end-help-type"}
                                                                        text={
                                                                            verification.typeId === VerificationTypeId.Identity ? ("Is " + this.props.contact.firstName + "'s profile suitable?") : 
                                                                            verification.typeId === VerificationTypeId.Representation ? ("Is " + this.state.agencyName + " still representing " + this.props.contact.firstName + "?") : 
                                                                            verification.typeId === VerificationTypeId.Suspension ? ("Current active issue for " + this.props.contact.firstName + "?") :
                                                                            "Met all sector specific compliance requirements"}
                                                                    >
                                                                        <i className="fas fa-question-circle"></i>
                                                                    </SimpleTooltip>
                                                                </React.Fragment>
                                                                :
                                                                <span>Historical record</span>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }) }
                                            { (this.state.verifications.find(v => v.end) && !this.state.showHistoric) &&
                                                <div className='show-historic tr'>
                                                    <div className="td" style={{ textAlign: 'center', flexBasis: '100%' }}>
                                                        <button
                                                            onClick={() => {
                                                                this.setState({
                                                                    showHistoric: true
                                                                })
                                                            }}
                                                        >
                                                            Show {this.state.verifications.filter(v => v.end).length } Historic Verifications
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    }
                    { (
                        window.location.href.indexOf("/external/") === -1 && 
                        this.props.contact.userId &&
                        (this.state.focused === "rate" || !this.state.focused)
                    ) &&
                        <div className="row contact-profile" data-embedded={window.location.href.indexOf("/external/") !== -1 ? 'true' : 'false'}>
                            <div className="hpanel pageHeader">
                                <div className="panel-body">
                                    <WorkerRater
                                        title={this.props.contact.ownRating?.publicComment || this.props.contact.ownRating?.privateComment || this.props.contact.ownRating?.stars ? ("Your Rating of " + this.props.contact.firstName) : ("Rate " + this.props.contact.firstName)}
                                        mode="inline"
                                        contacts={[{
                                            userId: this.props.contact.userId,
                                            stars: this.props.contact.ownRating?.stars || 0,
                                            publicComment: this.props.contact.ownRating?.publicComment || "",
                                            privateComment: this.props.contact.ownRating?.privateComment || "",
                                            name: this.props.contact.firstName + " " + this.props.contact.lastName
                                        }]}
                                        onSaveDone={(stars) => {
                                            this.props.getContact(this.props.contact.id);
                                            console.log(stars, "<<<");
                                            if (stars === 1) {
                                                this.openConfirmationModal(true);
                                            }
                                        }}
                                        onEdit={(contact) => {
                                            return contact;
                                        }}
                                        showProfile={false}
                                        showSave={true}
                                        extendedHeightWithoutFocus={false}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    { (
                        !this.state.focused &&
                        window.location.href.indexOf("/external/") === -1 &&
                        this.props.contact.userId &&
                        this.props.contact.ownOrganisationRatings &&
                        this.props.contact.externalRatings &&
                        !this.state.reload &&
                        !this.props.contact.notSharing &&
                        this.props.contact.ownOrganisationRatings?.concat(this.props.contact.externalRatings).concat(this.props.contact.ownRating ? [this.props.contact.ownRating] : []).length !== 0
                    ) &&
                        <div className="row contact-profile" data-embedded={window.location.href.indexOf("/external/") !== -1 ? 'true' : 'false'}>
                            <div className="hpanel pageHeader">
                                <div className="panel-body">
                                    <RatingListOfWorker
                                        ratings={ratingsList || []}
                                        avgRating={this.props.contact.avgRating}
                                        totalRatings={this.props.contact.totalRatings}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    { (!this.state.reload && !this.state.focused && !this.props.contact.notSharing) &&
                        <React.Fragment>
                            {this.props.windowWidth >= 649 ? (
                                <div className="row contact-calendar" data-embedded={window.location.href.indexOf("/external/") !== -1 ? 'true' : 'false'}>
                                    <div className="animate-panel">
                                        <div className="panel panel-default">
                                            {/* <div className="panel-heading">
                                                Calendar
                                            </div> */}
                                            <div
                                                className="panel-body"
                                                id="availabilityGridTodayTomorrow"
                                                style={{
                                                    minHeight: 150, 
                                                    marginTop: 36
                                                }}
                                            >
                                                <Calendar />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="calendar-list" data-embedded={window.location.href.indexOf("/external/") !== -1 ? 'true' : 'false'}>
                                    <CalendarList
                                        userId={this.props.contact.userId}
                                        userName={this.props.contact.firstName}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    }
                    { (this.state.focused) &&
                        <div className='load-all'>
                            <button className='main' onClick={() => { this.setState({ focused: null }) }}>Show Complete Profile</button>
                        </div>
                    }
                    { (this.state.reportModal) &&
                        <ReportModal options={this.state.reportModal} onClose={() => {
                            this.setState({ reportModal: undefined });
                        }} />
                    }
                    { (this.state.confirmDeleteAllActiveVerifications !== undefined) &&
                        <SimplePrompt
                            id="verification-delete-all"
                            title='Remove All Active Screenings?'
                            body="Tap Yes to remove active issue, representation, and safeguarding checks. This will not remove historical records."
                            buttons={[
                                {
                                    text: "Yes, remove all active screenings",
                                    onClick: () => {
                                        this.endVerification(this.state.confirmDeleteAllActiveVerifications!.id, this.state.confirmDeleteAllActiveVerifications!.end, this.state.confirmDeleteAllActiveVerifications!.type, true);
                                        this.setState({
                                            confirmDeleteAllActiveVerifications: undefined
                                        })
                                    }
                                },
                                {
                                    text: "No, just mark as unsuitable",
                                    onClick: () => {
                                        this.endVerification(this.state.confirmDeleteAllActiveVerifications!.id, this.state.confirmDeleteAllActiveVerifications!.end, this.state.confirmDeleteAllActiveVerifications!.type, false);
                                        this.setState({
                                            confirmDeleteAllActiveVerifications: undefined
                                        })
                                    }
                                }
                            ]}
                        />
                    }
                </Wrapper>
            )}
        />

        if (this.state.modal) {
            return (
                <Modal
                    show={this.state.modal}
                    onHide={() => { 
                        if (this.props.onClosed) {
                            this.props.clearContact();
                            this.props.onClosed();
                        }
                    }}
                    className="own-verifications-modal"
                >
                    <Modal.Body>
                        {CONTENT_DOM}
                    </Modal.Body>
                </Modal>
            )
        }

        return CONTENT_DOM;
    }
}

const Wrapper = styled.div`
    .load-all {
        text-align: center;
    }

    .calendar-list {
        margin: 0 -10px;
        overflow: hidden;
    }

    .own-verifications {
        position: relative;
        overflow: hidden;

        .full-screen-loader-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: calc(100% - 22px);
            border-radius: 24px;
        }

        .table {
            width: 100%;
            margin: 0;

            .thead {
                border-bottom: 2px solid gainsboro;
            }

            .th {
                padding: 5px;
                text-align: left;
                font-weight: 600;
            }

            .guide-label {
                display: none;
                margin-right: 4px;
            }

            .td {
                padding: 5px;
                text-align: left;
                border-bottom: 1px solid gainsboro;
                display: inline-flex;
                align-items: center;

                &:nth-child(1) {
                    font-weight: 700;

                    div {
                        display: inline-flex;
                        align-items: center;
                    }
                }

                &.multi-line {
                    display: flex;
                    align-items: center;
                }

                button.unsuitable {
                    background-color: ${theme.colours.red2};
                    color: white;
                    margin-bottom: 4px;
                }
                
                .toggle-button {
                    display: inline-block;
                    background-color: gainsboro;
                    border-radius: 8px;
                    padding: 5px;
                    margin-right: 5px;

                    button {
                        background: none !important;
                        color: black !important;
                        padding: 4px 10px;
                        font-weight: 300;

                        &[data-active="true"] {
                            background: ${theme.colours.darkBlue} !important;
                            color: white !important;
                            font-weight: 800;
                        }

                        &[data-confirm="true"] {
                            background: ${theme.colours.green2} !important;
                        }
                    }
                }
            }

            .tr {
                
                &:not(:last-child) {
                    boder-bottom: 1px solid gainsboro;
                }

                &:last-child {

                    td {
                        border-bottom: none;
                    }
                }

                &:hover {
                    background-color: whitesmoke;
                }
            }
            
            .show-historic {

                .td {
                    border: none;
                    text-align: center;
                    display: block;

                    button {
                        border: none;
                        background: none;
                        font-size: 16px;
                    }
                }
            }

            .verification-row {
                display: flex;
                flex-wrap: nowrap;
                background-color: white;
                transition: 0.4s background-color linear;

                &[data-just-added="true"] {
                    background-color: #ffffac;
                }

                .td:nth-child(4), .th:nth-child(4) {
                    flex-basis: 180px;
                }

                .td:nth-child(3), .td:nth-child(2), .th:nth-child(3), .th:nth-child(2) {
                    flex-basis: 160px;

                    img {
                        margin-left: 4px;
                    }
                }

                .td:nth-child(1), .th:nth-child(1) {
                    flex-basis: calc(100% - 500px);
                }

                button {
                    border: none;
                    background-color: gainsboro;
                    display: inline-flex;
                    align-items: center;
                    padding: 6px 12px;
                    justify-content: center;
                    border-radius: 5px;
    
                    i {
                        font-size: 15px;
                    }
    
                    &.start {
                        background-color: ${theme.colours.darkBlue};
                        color: white;
                    }
    
                    &.end {
                        background-color: ${theme.colours.darkBlue};
                        color: white;
                    }

                    &[data-color="grey"] {
                        background-color: gainsboro;
                    }
                }

                &.rejected {
                    background-color: ${theme.colours.amber};
                    color: white;
                    display: flex;
                    justify-content: center;
                    padding: 10px;
                    font-size: 13px;
                    line-height: 13px;
                    font-weight: 600;
                }

                @media (max-width: 980px) {
                    flex-wrap: wrap;

                    .td:nth-child(4), .th:nth-child(4) {
                        flex-basis: 100%;
                    }

                    .th:nth-child(4) {
                        display: none;
                    }
    
                    .td:nth-child(3), .td:nth-child(2), .th:nth-child(3), .th:nth-child(2) {
                        flex-basis: 160px;
                    }
    
                    .td:nth-child(1), .th:nth-child(1) {
                        flex-basis: calc(100% - 320px);
                    }
                }

                @media (max-width: 550px) {

                    .th, .td.empty {
                        display: none;
                    }

                    .td {
                        flex-basis: 100% !important;

                        &:nth-child(4) {
                            margin-bottom: 20px;
                            
                            button {
                                flex-basis: 100%;
                            }
                        }
                    }

                    .guide-label {
                        display: inline-block;
                    }
                }
            }
        }

        i {
            font-size: 24px;
            margin-right: 8px;

            &[data-active="true"] {
                color: ${theme.colours.green2};
            }

            &[data-active="false"] {
                color: ${theme.colours.amber};
            }
        }

        img {
            border-radius: 100%;
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }
    }

    .represented-by {
        display: block;

        .orgs {

            &>div {
                display: inline-flex;
                align-items: center;
                background-color: ${theme.colours.blue3};
                padding: 6px;
                border-radius: 4px;
                margin: 0 8px 8px 0;
                position: relative;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                }

                p {
                    color: white;
                    margin: 0 0 0 6px;
                }

                .status {
                    position: relative;
                    padding: 2px;
                    border-radius: 100%;
                    right: 2px;
                    margin-right: -4px;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                
                    i {
                        font-size: 10px;
                        color: white;
                        position: relative;
                        top: -1px;
                    }

                    &[data-status="3"] {
                        background: ${theme.colours.green2};
                    }

                    &[data-status="4"] {
                        background: ${theme.colours.red2};
                    }

                    &[data-status="10"] {
                        background: ${theme.colours.amber};
                    }
                }
            }
        }
    }

    .qualifications {
        
        &>div {
            background: ${theme.colours.darkBlue};
            padding: 2px 8px;
            margin: 0 8px 8px 0;
            border-radius: 5px;
            color: white;
            width: auto;
            display: inline-block;
        }
    }

    .files {

        .file, .folder {
            overflow: hidden;
            display: inline-block;
            background-color: ${theme.colours.blue2};
            width: 100px;
            height: 100px;
            margin: 0 10px 10px 0;
            border-radius: 12px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            label {
                padding: 0;
            }

            .preview {
                position: relative;
                padding: 10px;
                text-align: center;
                color: white;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &>div {
                    display: none;

                    .file-format {
                        display: flex;
                        margin-bottom: 10px;
                        line-height: 1;
                        align-items: center;
                        justify-content: center;
    
                        label {
                            text-transform: uppercase;
                            margin-left: 8px;
                        }
                    }
        
                    .file-name {
                        line-height: 1;
                    }
                }
            }

            &:hover {

                .preview {
                    background: rgba(0,0,0,0.35);

                    &>div {
                        display: block !important;
                    }
                }
            }

            &.folder {
                .preview>div {
                    display: block !important;
                }
            }
        }
    }

    .contact-skills {
        margin: 6px 0 0 0;

        &>div {
            white-space: nowrap;
            border-radius: 5px;
            background: ${theme.colours.blue2};
            display: inline-block;
            padding: 2px 8px;
            margin: 0 8px 8px 0;
            color: white;
        }

        .stars-wrapper.avg-rating {
                font-size: 0.9em;
                background-color: $blue-2;
                color: white;
                padding: 2px 6px;
                border-radius: 4px;
                margin-top: 3px;
        
                span {
                    margin-left: 4px;
                    display: inline-block;
                }
            }
        }
    }

    label {
        font-weight: 400;
        padding: 0 0 12px 0;
        margin: 0;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .options {
        & > div {
            background: #555;
            color: white;
            border: none;
            padding: 4px 10px;
            border-radius: 8px;
            font-size: 1em;
            cursor: pointer;
            display: inline-block;
            margin: 0 6px 6px 0;

            i {
                margin-right: 5px;
                margin-left: 0 !important;
                color: white !important;
                opacity: 1 !important;
            }
        }
    }

    .panel-body {
        max-width: 1020px;
        margin-bottom: 0;
    }

    .panel-default {
        border-radius: 20px;
    }

    .contact-calendar {
        max-width: 1020px;
        margin: auto !important;
    }

    .identity-verification-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        z-index: 2;
        display: flex;

        .mask {
            background-color: rgba(0,0,0,0.5);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }

        .verification {
            // position: relative;
            background-color: white;
            border-radius: 12px;
            padding: 20px;
            z-index: 2;
            width: calc(100% - 40px);
            position: relative;
            max-width: 520px;
            text-align: center;

            .options {
                display: flex;
                align-items: center;
                justify-content: center;
                background: white;
                padding: 10px;
                border-radius: 10px;
                flex-wrap: wrap;

                &>button, &>.dropdown {
                    flex-basis: calc(50% - 5px);
                    border: none;
                }

                &>.dropdown {

                    button {
                        background-color: $red-2;
                        width: 100%;
                        margin-left: 5px;
                    }
                }

                button {
                    padding: 12px;
                    border-radius: 8px;
                    font-weight: 600;
                    color: white;
                    border: none;
                    margin-right: 5px;

                    &.suitable {
                        background-color: ${theme.colours.green2};
                    }

                    &.unsuitable {
                        background-color: ${theme.colours.red2};
                        margin-right: 0;
                        margin-left: 5px;
                    }
                }
            }

            @media (max-width: 450px) {
                padding: 15px;

                .options {
                    display: block;

                    button {
                        width: 100%;
                        margin-left: 0 !important;
                        margin-right: 0 !important;

                        &:not(:last-child) {
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    
        .sensitive-information {
            margin: auto;
            display: inline-block;
            width: 100%;

            .options {
                padding: 0;
                flex-wrap: wrap;
            }

            .error {
                color: $red-2;
                font-size: 16px;
                margin-bottom: 10px;
            }
            
            .or {
                margin-bottom: 10px;
                font-size: 18px;
            }

            input {
                border: 1px solid gainsboro;
                background-color: whitesmoke;
                font-size: 16px;
                margin: 4px 4px;
                display: inline-block;
                width: 120px;
                border-radius: 6px;
                padding: 2px 4px;
            }

            .info {
                font-size: 16px;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }

                i {
                    margin-right: 6px;
                }
                
                span {
                    
                }
            }

            &[data-completed="true"] {

                .options {
                    margin: -5px;

                    button {
                        flex-basis: 100%;
                        margin: 0;
                        min-height: unset;
                    }
                }
            }
        }
    }

    @media (max-width: 649px) {

        .worker-rater {
            margin: 0 !important;
            width: unset !important;

            .stars-wrapper {
                margin-bottom: 10px;

                h2 {
                    margin: 0 10px 0 0;
                    line-height 1.6em;
                }
            }

            .save-rating-btn {
                margin-bottom: 0 !important;
            }
        }

        .hpanel.pageHeader {
            margin-bottom: 10px;
        }
    }
`;

const connector = connect(
    (state: ApplicationState) => state.contactProfile,
    ContactProfileActionCreators.actionCreators
);

export default withWindowDimensions(connector(ContactProfile));

const DayPickerWrapper = styled.div`
    .DayPicker-wrapper {
        z-index: 2;
        padding-bottom: 0;
        background: white;
    }

    .DayPicker-Footer {
        padding: 5px 0;
        font-weight: bold;
        text-align: center;
        background: #f0f0f0;
        border-top: 1px solid #aeaeae;
        cursor: pointer;

        button {
            width: 100%;
            color: #000;
        }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: #34495e;
    }
`;