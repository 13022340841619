/* eslint-disable */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import history from '../../history';
import '../../css/People.css';
import CompanyApi from '../../api/company/Company';
import OrganisationApi from '../../api/organisation/Organisation';
import OrganisationSyncApi from '../../api/organisationsync/OrganisationSync';
import { OrganisationSyncItemType } from '../../api/organisationsync/ResponseTypes';

import { ApplicationState } from '../../store';
import * as ProfileStore from '../../store/Profile';

import { bulkPut, getAll } from 'src/db/SyncCache';
import Utilities from 'src/Utilities';
import { UserQualification } from 'src/api/contacts/ResponseTypes';
import ProfileApi from 'src/api/profile/Profile';
import { ContactAvailabilityState } from 'src/store/Availability';
import { deleteValue, getValue, setValue } from 'src/db/KeyValueCache';
import { Badge, Checkbox, FormControlLabel, ToggleButtonGroup, ToggleButton, CircularProgress } from '@mui/material';
import { VerificationTypeId } from 'src/pages/ExternalVerification';
import ContactProfileModal from '../../pages/ContactProfile';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import ReactStars from "react-rating-stars-component";
import moment from 'moment';
import TimePresetSlider from '../availability/TimePresetSlider';
import theme from 'src/css/theme';

export interface OrganisationSyncNormalizedItem {
    key: string;
    value: OrganisationSyncUserProfile | OrganisationSyncUserAvailability | OrganisationSyncOrgTimePreset | OrganisationSyncOrgTeamWithMembers | OrganisationSyncOrgLocation | OrganisationSyncOrgGroupWithMembers | OrganisationAllSharingRelationships
    entityId: string;
    entityType: OrganisationSyncItemType
} 

export interface WorkerAttribute {
    id: number;
    userId: string; // todo can remove
    sectorId: number;
    skillId: number;
    subSectorId: number;
    sectorName: string;
    skillName: string;
    subSectorName: string;
}

// Ratings = ratings,
// Files = new UserFileAndFolderAndAccess() // TODO access control
// {
//     Access = access,
//     UserFiles = files,
//     UserFileFolders = folders
// },
// Verifications = verifications // TODO

export interface WorkerVerificationDetail {
    id: number;
    typeId: VerificationTypeId;
    verifyingContactId: string;
    verifiedContactId: string;
    start: string;
    end: string;
    startedAt: string;
    endedAt: string;
    contactName: string;
    organisationId: string;
    organisationName: string;
    contactEmail: string;
    endedByContactId: string;
    endedByUserId: string;
    endedByUserName: string;
    // verificationTrustId: VerificationTrust;
    verifyingUserName: string;
    verifyingUserId: string;
    verifiedUserName: string;
    verifiedUserId: string;
    verifyingOrgName: string;
    verifyingOrgId: string;
}

export interface OrganisationSyncUserProfile {
    id: string;
    contactId: string;
    firstName: string;
    lastName: string;
    headline: string;
    maxDistance: number;
    reported: boolean;
    sMSNotifications: boolean;
    availabilityLastConfirmed: string;
    lastTimelineUpdateAt: string;
    verified: boolean;
    latitude: number;
    longitude: number;
    qualifications: UserQualification[];
    attributes: WorkerAttribute[];
    verifications: WorkerVerificationDetail[];
    ratings: WorkerRating[];

    lastUpdatedTs?: number;
    totalRatings?: number;
    avgRating?: number;
    ownRating?: WorkerRating;
    milesDistance?: number;
    totalAvailableMinutesForSelectedDays?: number;
}

export interface WorkerRating {
    id: number;
    raterUserId: string;
    raterFirstName: string;
    raterLastName: string;
    raterOrganisationName: string;
    raterOrganisationId: string;
    udatedAt: string;
    stars: number;
    publicComment: string;
    privateComment: string;
    // RaterExternalId\":null,\"RaterExternalOrgId\":null,\"RaterExternalOrgName\":null,\"RaterExternalOrgDomain\":null,\"RaterExternalContactName
}

interface MergedUserProfile extends OrganisationSyncUserProfile {
    totalAvailableMinutesPerDay: {
        [key: string]: number
    },
    compiledAvailability: {
        [key: string]: MiniAvailabilityGridDaySlot[] // Array<number[]>
    }
}

interface MiniAvailabilityGridDaySlot {
    available: boolean;
    top: number; // pixel
    height: number; // pixel
}

interface MergedUserProfileState {
    [key: string]: MergedUserProfile
}

interface RepresentingAgency {
    id: string;
    name: string;
}

interface CachedUserRepresentingAgencies extends RepresentingAgency {
    userIds: string[];
}

interface CachedOrgLocationToUserDistances {
    locationId: string;
    latitude: number;
    longitude: number;
    users: {
        id: string;
        milesDistance: number;
        latitude: number;
        longitude: number;
    }[]
}

interface Props {
    onNavigate?: () => void;
}

interface Preset {
    id?: string;
    name?: string;
    startHour: number;
    endHour: number;
    startMinute: number;
    endMinute: number;
}

interface State {
    view: "expanded" | "hovered" | "collapsed";
    userProfiles: MergedUserProfileState;
    searchParams: {
        timePreset: Preset,
        customTimePreset: Preset | null,
        globalSearch?: string;
        teamId: number,
        groupId: number,
        locationId: string,
        attributeIds: string[],
        name: string,
        representingAgencies: string[],
        mostAvailbleDates: string[],
        sortType: "distance" | "overall-rating" | "own-rating" | "most-available" | "last-updated" | "name" // pinned
    },
    skillsFilter: string;
    representationsFilter: string;
    skillsSearchType: "AND" | "OR";
    representationsSearchType: "AND" | "OR";
    timePresets: Preset[],
    groups: EntitledGroupsWithMembers[],
    locations: CachedLocation[],
    teams: CachedTeamWithMembers[],
    userAttributes: CachedUserAttribute[],
    filterDropdown: 'sort' | 'timepreset' | 'locations' | 'attributes' | 'representations' | null;
    touchScreen: boolean;
    ownUserId: string;
    organisationIsAgency: boolean;
    lastSynced: number;
    syncing: boolean;
    initedRefreshHooks: boolean;
    userRepresentations: CachedUserRepresentingAgencies[];
    openContactId: string;
    scrollBarWidth: number;
    orgToUserLocations: CachedOrgLocationToUserDistances[];
    showFilters?: boolean;
}

interface OrganisationSyncUserAvailability extends Array<number[]> {}

interface OrganisationSyncOrgTimePreset extends Array<CachedTimePreset> {}

interface CachedTimePreset {
    id: string;
    name: string;
    startHour: number;
    startMinute: number;
    endHour: number;
    endMinute: number;
    userId: string;
}

interface OrganisationSyncOrgTeamWithMembers extends Array<CachedTeamWithMembers> {}

interface CachedTeamWithMembers {
    id: number;
    name: string;
    userId: string;
}

interface OrganisationSyncOrgLocation extends Array<CachedLocation> {}

interface CachedLocation {
    id: string;
    locationPlaceId: string;
    locationPlaceName: string;
    locationFriendlyName: string;
    locationFriendlyAddress: string;
    postCode: string;
    isDefault: boolean;
    latitude: number;
    longitude: number;
}

interface OrganisationSyncOrgGroupWithMembers extends Array<CachedGroupWithMembers> {}

interface CachedGroupWithMembers {
    id: number;
    name: string;
    teamId: number;
    workerUserId: string;
}

interface EntitledGroupsWithMembers {
    teamId: number;
    id: number;
    name: string;
    userIds: string[];
}

interface OrganisationAllSharingRelationships extends Array<CachedOrganisationSharingRelationship> {}

interface CachedOrganisationSharingRelationship {
    workerUserId: string;
    hirerUserId: string;
    delete: boolean;
}

// interface CachedUserAttributes extends Array<CachedSector> {}

// interface CachedSkill {
//     id: number;
//     name: string;
//     userIds: string[];
// }

// interface CachedSubSector {
//     id: number;
//     name: string;
//     skills: CachedSkill[];
// }

// interface CachedSector {
//     id: number;
//     name: string;
//     subSectors: CachedSubSector[];
// }

interface CachedUserAttribute {
    sectorId: number;
    sectorName: string;
    subSectorId: number;
    subSectorName: string;
    skillId: number;
    skillName: string;
    userIds: string[];
}

class People extends React.Component<
    Props,
    State
> {
    datesRef: React.RefObject<HTMLDivElement> = React.createRef();
    peopleRef: React.RefObject<HTMLDivElement> = React.createRef();
    state = {
        view: "collapsed" as "expanded" | "hovered" | "collapsed",
        userProfiles: {} as MergedUserProfileState,
        searchParams: {
            timePreset: {
                startHour: 8,
                endHour: 17,
                startMinute: 0,
                endMinute: 0,
                name: "All Day"
            } as Preset,
            globalSearch: "",
            teamId: 0,
            groupId: 0,
            locationId: "",
            attributeIds: [] as string[],
            name: "",
            representingAgencies: [] as string[],
            mostAvailbleDates: [] as string[],
            sortType: "last-updated" as "distance" | "overall-rating" | "own-rating" | "most-available" | "last-updated" | "name",
            customTimePreset: null as Preset | null
        },
        timePresets: [] as CachedTimePreset[],
        groups: [] as EntitledGroupsWithMembers[],
        locations: [] as CachedLocation[],
        teams: [] as CachedTeamWithMembers[],
        userAttributes: [] as CachedUserAttribute[],
        filterDropdown: null,
        expandedDaysToDisplay: 90, // maybe start at 30 increase by 30, up to 90
        // if devices has touch screen
        touchScreen: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
        skillsFilter: "",
        skillsSearchType: "AND" as "AND" | "OR",
        ownUserId: "",
        lastSynced: 0,
        syncing: false,
        initedRefreshHooks: false,
        userRepresentations: [] as CachedUserRepresentingAgencies[],
        representationsFilter: "",
        representationsSearchType: "AND" as "AND" | "OR",
        openContactId: "",
        scrollBarWidth: 0,
        organisationIsAgency: false,
        orgToUserLocations: [] as CachedOrgLocationToUserDistances[],
        showFilters: true
    };

    componentDidMount() {
        this.setState({
            lastSynced: localStorage.getItem('lastSynced') ? parseInt(localStorage.getItem('lastSynced') || "0") : 0,
            scrollBarWidth: Utilities.getScrollbarWidth()
        })
        this.attemptToGetUserId();
    }

    async attemptToGetUserId() {
        const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || "{}") : {};
        if (localUser && localUser.id && localUser.organisationId) { 
            console.log("FOUND all... getting data")
            const lastSynced = localStorage.getItem('lastSynced') ? parseInt(localStorage.getItem('lastSynced') || "0") : 0;
            if (lastSynced === 0) {
                await new Promise((resolve, reject) => { setTimeout(resolve, 2000) }); // todo check if enough 
            }
            this.setState({
                ownUserId: localUser.id,
                organisationIsAgency: localUser.organisationIsAgency
            }, () => {
                this.reloadFromLocalData(undefined, true);
                this.sync();
            })
        } else {
            console.log("NOT FOUND all...")
            setTimeout(() => {
                this.attemptToGetUserId();
            }, 1000);
            return;
        }
    }

    async initRefreshHooks() {
        // on window focus sync
        window.addEventListener('focus', () => {
            this.sync();
        });
        (window as any).triggerPeopleGridRefresh = () => {
            setTimeout(() => {
                this.sync();
            }, 1000);
        }
        this.setState({
            initedRefreshHooks: true
        })
    }

    async recalculateUserOrgLocations(recalculateForWorkerIds: string[], eraseAndRecalcuateForLocations: CachedLocation[]) {
        // changed an org location, complete recompile
        if (eraseAndRecalcuateForLocations && eraseAndRecalcuateForLocations.length) {
            await deleteValue("orgUserLocations");
            recalculateForWorkerIds = Object.values(this.state.userProfiles).map((user) => user.id);
        }

        const locations = this.state.locations;
        const cacheStr = await getValue("orgUserLocations");
        let cache = (cacheStr ? JSON.parse(cacheStr) : []) as CachedOrgLocationToUserDistances[];
        console.log("____ LOcation cache init", cache, recalculateForWorkerIds, locations);

        for (let i = 0; i < recalculateForWorkerIds.length; i++) {
            let user = this.state.userProfiles[recalculateForWorkerIds[i]];
            if (!user || !user.latitude || !user.longitude) {
                continue;
            }

            let userLatitude = user.latitude;
            let userLongitude = user.longitude;

            for (let j = 0; j < locations.length; j++) {
                let location = locations[j];
                let locationLatitude = location.latitude;
                let locationLongitude = location.longitude;
                let distance = Utilities.distanceBetweenTwoLatLngCoordinatesInMiles(userLatitude, userLongitude, locationLatitude, locationLongitude);
                let cacheIndex = cache.findIndex(item => item.locationId === location.id);
                if (cacheIndex > -1) {
                    let userIndex = cache[cacheIndex].users.findIndex(item => item.id === user.id);
                    if (userIndex > -1) {
                        cache[cacheIndex].users[userIndex].milesDistance = distance;
                    } else {
                        cache[cacheIndex].users.push({
                            id: user.id,
                            milesDistance: distance,
                            latitude: userLatitude,
                            longitude: userLongitude
                        });
                    }
                } else {
                    cache.push({
                        locationId: location.id,
                        latitude: locationLatitude,
                        longitude: locationLongitude,
                        users: [{
                            id: user.id,
                            milesDistance: distance,
                            latitude: userLatitude,
                            longitude: userLongitude
                        }]
                    })
                }
            }
        }

        console.log("____ LOcation cache", cache);

        await setValue("orgUserLocations", JSON.stringify(cache));
        this.setState({
            orgToUserLocations: cache,
            searchParams: {
                ...this.state.searchParams,
                locationId: cache.length ? cache[0].locationId : ""
            }
        })
    }
    
    async recalculateUserRepresentations(recalculateForWorkerIds: string[]) {
        const cachesStr = await getValue("userRepresentations");
        let cache = (cachesStr ? JSON.parse(cachesStr) : []) as CachedUserRepresentingAgencies[];
    
        // todo need to cache full mutual list of org, or just show where at least one worker has that?? if need to show all then new cached type needed
        for (let userKey in this.state.userProfiles) {
            if (recalculateForWorkerIds.includes(userKey)) {
                let user = this.state.userProfiles[userKey];
                let representations = user.verifications ? user.verifications.filter(verification => verification.typeId === VerificationTypeId.Representation && !verification.endedAt) : [];
                // delete all worker representations from cache
                for (let i = 0; i < representations.length; i++) {
                    let index = cache.findIndex(attr => attr.id === representations[i].organisationId);
                    if (index > -1) {
                        cache[index].userIds = cache[index].userIds.filter(id => id !== user.id);
                        if (cache[index].userIds.length === 0) {
                            cache.splice(index, 1);
                        }
                    }
                }

                // add all worker representations to cache
                for (let i = 0; i < representations.length; i++) {
                    let index = cache.findIndex(attr => attr.id === representations[i].organisationId);
                    if (index > -1) {
                        cache[index].userIds.push(user.id);
                    } else {
                        cache.push({
                            id: representations[i].organisationId,
                            name: representations[i].organisationName,
                            userIds: [user.id]
                        });
                    }
                }
            }
        }

        console.log(cache, "______ CACHE REP");
        await setValue("userRepresentations", JSON.stringify(cache));
        this.setState({
            userRepresentations: cache
        })
    }

    async recalculateUserAttributes(recalculateForWorkerIds: string[]) {
        const cacheStr = await getValue("userAttributes");
        let cache = (cacheStr ? JSON.parse(cacheStr) : []) as CachedUserAttribute[];

        for (let userKey in this.state.userProfiles) {
            if (recalculateForWorkerIds.includes(userKey)) {
                let user = this.state.userProfiles[userKey];
                let attributes = user.attributes;
                // delete all worker attributes from cache
                for (let i = 0; i < attributes.length; i++) {
                    let index = cache.findIndex(attr => attr.sectorId === attributes[i].sectorId && attr.subSectorId === attributes[i].subSectorId && attr.skillId === attributes[i].skillId);
                    if (index > -1) {
                        cache[index].userIds = cache[index].userIds.filter(id => id !== user.id);
                        if (cache[index].userIds.length === 0) {
                            cache.splice(index, 1);
                        }
                    }
                }
                
                // add all worker attributes to cache, create sector, subsector, skill if not exists
                for (let i = 0; i < attributes.length; i++) {
                    let index = cache.findIndex(attr => attr.sectorId === attributes[i].sectorId && attr.subSectorId === attributes[i].subSectorId && attr.skillId === attributes[i].skillId);
                    if (index > -1) {
                        cache[index].userIds.push(user.id);
                    } else {
                        cache.push({
                            sectorId: attributes[i].sectorId,
                            sectorName: attributes[i].sectorName,
                            subSectorId: attributes[i].subSectorId,
                            subSectorName: attributes[i].subSectorName,
                            skillId: attributes[i].skillId,
                            skillName: attributes[i].skillName,
                            userIds: [user.id]
                        });
                    }
                }
            }
        }

        await setValue("userAttributes", JSON.stringify(cache)); // todo needs to adjust when group changes?
        this.setState({
            userAttributes: cache
        })
    }

    clearFilters() {
        this.setState({
            searchParams: {
                ...this.state.searchParams,
                globalSearch: "",
                attributeIds: [],
                name: "",
                representingAgencies: [],
                sortType: "last-updated" // todo clear this as well?
            }
        }, () => {
            this.reloadFromLocalData();
        })
    }

    // run when timepreset changes, todo maybe only compile avaialbility data on hovered/expanded
    async reloadFromLocalData(newUserIds?: string[], firstLoad?: boolean) {
        return new Promise<void>( async (resolve, reject) => {
            const data = await getAll();
            let mergedData: MergedUserProfileState = {};
            const profiles = data.filter((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.UserProfile);
            const availabilities = data.filter((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.UserAvailability);
            const timepresets = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.TimePresetList);
            const teams = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.TeamWithMembersList);
            const locations = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.LocationList);
            const groups = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.GroupWithMembersList);
            const allOrgAvailabilityShares = data.find((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.OrganisationReceivingFrom);
            const workersNoLongerSharing = allOrgAvailabilityShares ? allOrgAvailabilityShares.value.filter((item: CachedOrganisationSharingRelationship) => item.delete).map((item: CachedOrganisationSharingRelationship) => item.workerUserId) : [];
            const workersStillSharingWithMe = allOrgAvailabilityShares ? allOrgAvailabilityShares.value.filter((item: CachedOrganisationSharingRelationship) => !item.delete && item.hirerUserId === this.state.ownUserId).map((item: CachedOrganisationSharingRelationship) => item.workerUserId) : [];
            const workersStillsharingWithMyOrg = allOrgAvailabilityShares ? allOrgAvailabilityShares.value.filter((item: CachedOrganisationSharingRelationship) => !item.delete).map((item: CachedOrganisationSharingRelationship) => item.workerUserId) : [];

            // TODO remove no-longer sharing

            if (timepresets || teams || locations || groups) {
                let allTeams: CachedTeamWithMembers[] = teams ? teams.value : [];
                allTeams.unshift({
                    id: 0,
                    name: "Just Me",
                    userId: ""
                })

                // todo autoselecct colleagues team if no other, or use last
                let allEntitledGroups = groups ? groups.value.filter(group => teams.value.map(t => t.id).includes(group.teamId)) : [];
                let allEntitledGroupList: EntitledGroupsWithMembers[] = [...new Set(allEntitledGroups.map(group => group.id))].map(id => {
                    return {
                        id: id,
                        name: allEntitledGroups.find(g => g.id === id)!.name,
                        userIds: groups.value.filter(g => g.id === id).map(g => g.workerUserId),
                        teamId: groups.value.find(g => g.id === id)!.teamId
                    } as EntitledGroupsWithMembers
                })

                for (let i = 0; i < allTeams.length; i++) {
                    allEntitledGroupList.push({
                        id: allTeams[i].id,
                        name:  allTeams[i].id === 0 ? "Everyone sharing with me" : "Everyone sharing with " + allTeams[i].name,
                        userIds: (allTeams[i].id === 0) ? workersStillSharingWithMe : workersStillsharingWithMyOrg,
                        teamId: allTeams[i].id
                    })
                }

                console.log({
                    // workersNoLongerSharing,
                    // workersStillSharingWithMe,
                    // workersStillsharingWithMyOrg,
                    // teams, locations, groups, timepresets,
                    allEntitledGroupList,
                    allTeams,
                    locations: locations ? locations.value : [],
                    groups: allEntitledGroupList,
                    timepresets,
                    profile: profiles.find(item => item.entityId === "3f6cc7b1-63f6-4348-8c3f-93188cd62a60")
                }, "<<<COMPILED")

                this.setState({
                    timePresets: timepresets ? timepresets.value : [],
                    teams: allTeams,
                    locations: locations ? locations.value : [],
                    groups: allEntitledGroupList,
                })

                if (firstLoad) {
                    console.log("STATES__", timepresets.value[0])
                    this.setState({
                        showFilters: false,
                        searchParams: {
                            ...this.state.searchParams,
                            // teamId: allTeams && allTeams[0] ? allTeams[0].id : 0, // todo this breaks
                            // groupId: allEntitledGroupList && allEntitledGroupList[0].id ? allEntitledGroupList[0].id : 0,
                            timePreset: (timepresets && timepresets.value) ? timepresets.value[0] : (this.state.timePresets[0] || 0)
                        }
                    }, () => {
                        this.setState({
                            showFilters: true
                        })
                    })
                }
            }

            for (let i = 0; i < profiles.length; i++) {
                let availability = availabilities.find((item: OrganisationSyncNormalizedItem) => item.entityId === profiles[i].entityId)?.value;
                // construct availability based on timepresets for next 90 days in given timeprset to be rendered top to down in 50 height x 20 width grid
                let now = new Date();
                let todayAvailableRanges: Array<number[]> = [];
                let until = Utilities.dateAdd(new Date(), "day", this.state.expandedDaysToDisplay);
                let compiledAvailability: MiniAvailabilityGridDaySlot[] = [];
                let totalAvailableMinutesPerDay: { [key: string]: number } = {};

                if ("68b32a5a-6db5-412e-8fe9-76fdce89b066" === profiles[i].entityId) {
                    console.log("68b32a5a-6db5-412e-8fe9-76fdce89b066", availability.map(a => [Utilities.formatDate(new Date(a[0] * 1000), "YYYY-MM-DD HH:mm"), Utilities.formatDate(new Date(a[1] * 1000), "YYYY-MM-DD HH:mm")]));
                }

                let timePresetToUse = this.state.searchParams.customTimePreset || this.state.searchParams.timePreset;
                console.log("Preset: " + timePresetToUse.startHour + ":" + timePresetToUse.startMinute + " - " + timePresetToUse.endHour + ":" + timePresetToUse.endMinute);

                while (now < until) {
                    const dateHash = Utilities.formatDate(now, "YYYY-MM-DD");
                    todayAvailableRanges = [];
                    const dayStartTs = Math.round(new Date(now.getFullYear(), now.getMonth(), now.getDate(), timePresetToUse.startHour, timePresetToUse.startMinute).getTime() / 1000);
                    const dayEndTs = Math.round(new Date(now.getFullYear(), now.getMonth(), now.getDate(), timePresetToUse.endHour, timePresetToUse.endMinute).getTime() / 1000);
                    const inclusiveRanges = availability.filter(availableRange => (availableRange[0] <= dayEndTs && availableRange[0] >= dayStartTs) || (availableRange[1] <= dayEndTs && availableRange[1] >= dayStartTs) || (availableRange[0] <= dayStartTs && availableRange[1] >= dayEndTs));
                    for (let i = 0; i < inclusiveRanges.length; i++) {
                        let start = inclusiveRanges[i][0] < dayStartTs ? dayStartTs : inclusiveRanges[i][0];
                        let end = inclusiveRanges[i][1] > dayEndTs ? dayEndTs : inclusiveRanges[i][1];
                        todayAvailableRanges.push([start, end]);
                    }

                    let adjustedDaySlot: MiniAvailabilityGridDaySlot[] = [];
                    const DAY_SLOT_HEIGHT = 50;
                    const MINUTES_PER_PIXEL = (timePresetToUse.endHour - timePresetToUse.startHour) * 60 / DAY_SLOT_HEIGHT;
                    
                    // create today's avaialble and unavailable ranges, in order to render them in grid that is 50px height, 8am is 0 pixel, 5pm is 50 pixel
                    for (let i = 0; i < todayAvailableRanges.length; i++) {
                        const start = todayAvailableRanges[i][0];
                        const end = todayAvailableRanges[i][1];
                        const startPixel = Math.round(Math.round((start - dayStartTs) / MINUTES_PER_PIXEL) / 60);
                        const endPixel = Math.round(Math.round((end - dayStartTs) / MINUTES_PER_PIXEL) / 60);
                        adjustedDaySlot.push({
                            available: true,
                            top: startPixel,
                            height: endPixel - startPixel
                        });
                    }
                    
                    compiledAvailability[dateHash] = adjustedDaySlot;
                    totalAvailableMinutesPerDay[dateHash] = todayAvailableRanges.reduce((acc, range) => acc + (range[1] - range[0]) / 60, 0);
                    now = Utilities.dateAdd(now, "day", 1);
                }

                if ("68b32a5a-6db5-412e-8fe9-76fdce89b066" === profiles[i].entityId) {
                    console.log("68b32a5a-6db5-412e-8fe9-76fdce89b066", compiledAvailability, totalAvailableMinutesPerDay);
                }
                
                mergedData[profiles[i].entityId] = {
                    ...profiles[i].value,
                    compiledAvailability: compiledAvailability,
                    totalAvailableMinutesPerDay: totalAvailableMinutesPerDay
                }
            }

            // console.log("___ reload from local data", data, mergedData['3f6cc7b1-63f6-4348-8c3f-93188cd62a60']);
            this.setState({ 
                userProfiles: mergedData
            }, () => {
                console.log("COMPILED____", this.state.userProfiles)//, "=====", this.state.userProfiles['3f6cc7b1-63f6-4348-8c3f-93188cd62a60']?.compiledAvailability);
                this.recalculateUserAttributes(newUserIds || []);
                this.recalculateUserRepresentations(newUserIds || []);
                this.recalculateUserOrgLocations(newUserIds || [], locations ? locations.value : []);
                resolve();
            });
        });
    }

    async sync() {
        this.setState({
            syncing: true
        })
        const lastSynced = localStorage.getItem('lastSynced') ? parseInt(localStorage.getItem('lastSynced') || "0") : 0;
        const updates = await OrganisationSyncApi.sync(lastSynced);
        let normalized: OrganisationSyncNormalizedItem[] = [];
        for (let i = 0; i < updates.updates.length; i++) {
            const parsedValue = JSON.parse(updates.updates[i].value);
            normalized.push({
                ...updates.updates[i],
                value: Utilities.pascalCaseToCamelCaseRecursive(parsedValue)
            })
        }
        let newUserIds = normalized.filter((item: OrganisationSyncNormalizedItem) => item.entityType === OrganisationSyncItemType.UserProfile).map((item: OrganisationSyncNormalizedItem) => item.entityId);
        console.log("___ updates", normalized);
        // @ts-ignore
        await bulkPut(normalized).then( async () => {
            localStorage.setItem('lastSynced', updates.lastSynced.toString());
            await this.reloadFromLocalData(newUserIds);
            this.setState({
                lastSynced: updates.lastSynced,
                syncing: false
            })
            if (!this.state.initedRefreshHooks) {
                this.initRefreshHooks();
            }
        }).catch((e) => {
            this.setState({
                syncing: false
            })
            // console.log("___ failed to bulk put");
        })
        
    }

    public render() {
        const numberOfAvailabilityDays = this.state.view === "expanded" ? this.state.expandedDaysToDisplay : this.state.view === "hovered" ? 6 : 0;
        const startDay = new Date();
        const dayHashRange = [...Array(numberOfAvailabilityDays).keys()].map((i) => {
            return Utilities.formatDate(Utilities.dateAdd(startDay, "day", i), "YYYY-MM-DD");
        });

        const groupUserIds = this.state.groups.find(group => group.id === this.state.searchParams.groupId)?.userIds || [];
        const currentOgLocationUserDistances = this.state.orgToUserLocations.find(item => item.locationId === this.state.searchParams.locationId)?.users || [];

        return (
            <aside 
                id="people-wrapper" 
                data-view={this.state.view}
                onMouseEnter={() => {
                    if (this.state.view === "collapsed" && !this.state.touchScreen) {
                        this.setState({ view: "hovered" });
                    }
                }}
                onMouseLeave={() => {
                    if (this.state.view === "hovered" && !this.state.touchScreen) {
                        this.setState({ 
                            view: "collapsed",
                            filterDropdown: null
                        });
                    }
                }}
                onClick={() => {
                    if (this.state.view === "collapsed" && this.state.touchScreen) {
                        this.setState({ view: "expanded" });
                    }
                }}
                style={{
                    width: this.state.view === "collapsed" ? 60 : this.state.view === "hovered" ? (320 + this.state.scrollBarWidth) : "100%"
                }}
            >
                <div className='filters'>
                    { (this.state.view === "collapsed") &&
                        <button onClick={() => {
                            this.setState({ view: "expanded" });
                        }}>
                            <i className="fas fa-search"></i>
                        </button>
                    }
                    { (this.state.view === "hovered" || this.state.view === "expanded") &&
                        <React.Fragment>
                            <button onClick={() => {
                                this.setState({ 
                                    view: this.state.view === "expanded" ? "collapsed" : "expanded"
                                });
                            }}>
                                <i className={"fas " + (this.state.view === "hovered" ? "fa-arrows-alt-h" : "fa-times")}></i>
                            </button>
                            <div className="filter timepreset">
                                <h3>
                                    <i className="fas fa-clock"></i>
                                    <span>Preset / Period</span>
                                </h3>
                                <div className='simple-select'>
                                    <select 
                                        onClick={(e) => {
                                            if (this.state.searchParams.customTimePreset) {
                                                e.preventDefault();
                                                // @ts-ignore
                                                e.target.blur();
                                                this.setState({
                                                    filterDropdown: "timepreset"
                                                });
                                            }
                                        }}
                                        value={this.state.searchParams.customTimePreset ? 0 : this.state.searchParams.timePreset.id}
                                        onChange={(e) => {
                                            const preset = this.state.timePresets.find(p => p.id === e.target.value);
                                            if (preset) {
                                                this.setState({
                                                    searchParams: {
                                                        ...this.state.searchParams,
                                                        timePreset: preset,
                                                        customTimePreset: null
                                                    },
                                                    filterDropdown: null,
                                                }, () => {
                                                    this.reloadFromLocalData();
                                                })
                                            } else {
                                                this.setState({
                                                    filterDropdown: "timepreset",
                                                    searchParams: {
                                                        ...this.state.searchParams,
                                                        customTimePreset: {
                                                            startHour: 4,
                                                            endHour: 22,
                                                            startMinute: 0,
                                                            endMinute: 0
                                                        }
                                                    }
                                                }, () => {
                                                    this.reloadFromLocalData()
                                                });
                                            }
                                        }}
                                    >
                                        {this.state.timePresets.map((preset, i) => {
                                            return (
                                                <option value={preset.id}>
                                                    {(preset.startHour === 0 ? "00" : preset.startHour < 10 ? ("0" + preset.startHour) : preset.startHour)}:
                                                    {(preset.startMinute === 0 ? "00" : preset.startMinute < 10 ? ("0" + preset.startMinute) : preset.startMinute)} - 
                                                    {(preset.endHour === 0 ? "00" : preset.endHour < 10 ? ("0" + preset.endHour) : preset.endHour)}:
                                                    {(preset.endMinute === 0 ? "00" : preset.endMinute < 10 ? ("0" + preset.endMinute) : preset.endMinute)}{' '}
                                                    [{preset.name}]
                                                </option>
                                            )
                                        }) }
                                        <option value={0}>
                                            { (this.state.searchParams.customTimePreset) &&
                                                (this.state.searchParams.customTimePreset.startHour === 0 ? "00" : this.state.searchParams.customTimePreset.startHour < 10 ? ("0" + this.state.searchParams.customTimePreset.startHour) : this.state.searchParams.customTimePreset.startHour) + ":" + 
                                                (this.state.searchParams.customTimePreset.startMinute === 0 ? "00" : this.state.searchParams.customTimePreset.startMinute < 10 ? ("0" + this.state.searchParams.customTimePreset.startMinute) : this.state.searchParams.customTimePreset.startMinute) + "-" +
                                                (this.state.searchParams.customTimePreset.endHour === 0 ? "00" : this.state.searchParams.customTimePreset.endHour < 10 ? ("0" + this.state.searchParams.customTimePreset.endHour) : this.state.searchParams.customTimePreset.endHour) + ":" + 
                                                (this.state.searchParams.customTimePreset.endMinute === 0 ? "00" : this.state.searchParams.customTimePreset.endMinute < 10 ? ("0" + this.state.searchParams.customTimePreset.endMinute) : this.state.searchParams.customTimePreset.endMinute) + " "
                                            }
                                            [Custom]
                                        </option>
                                    </select>
                                </div>
                                { (this.state.filterDropdown === "timepreset" && this.state.searchParams.customTimePreset) &&
                                    <div className='filter-dropdown'>
                                        <div className='header'>
                                            <h3>Set the required start, and end time by adjusting the below slider</h3>
                                        </div>
                                        <TimePresetSlider
                                            compactDrag={true}
                                            overnight={ this.state.searchParams.customTimePreset.startHour > this.state.searchParams.customTimePreset.endHour}
                                            values={[this.state.searchParams.customTimePreset.startHour + this.state.searchParams.customTimePreset.startMinute / 60, this.state.searchParams.customTimePreset.endHour + this.state.searchParams.customTimePreset.endMinute / 60]}
                                            onFinalChange={(values) => {
                                                let customPreset: any = { };
                                                customPreset!.startHour = Math.floor(values[0]);
                                                customPreset!.startMinute = Utilities.getDecimal(values[0]) * 60;
                                                customPreset!.endHour = Math.floor(values[1]);
                                                customPreset!.endMinute = Utilities.getDecimal(values[1]) * 60;

                                                customPreset.startMinute = Math.round(customPreset.startMinute);
                                                customPreset.endMinute = Math.round(customPreset.endMinute);

                                                customPreset!.startTime = 
                                                    (customPreset.startHour < 10 ? "0" + customPreset.startHour : customPreset.startHour) + 
                                                    ":" +
                                                    (customPreset.startMinute < 10 ? "0" + customPreset.startMinute : customPreset.startMinute);
                                                customPreset!.endTime = 
                                                    (customPreset.endHour < 10 ? "0" + customPreset.endHour : customPreset.endHour) + 
                                                    ":" +
                                                    (customPreset.endMinute < 10 ? "0" + customPreset.endMinute : customPreset.endMinute);

                                                this.setState({
                                                    searchParams: {
                                                        ...this.state.searchParams,
                                                        customTimePreset: customPreset
                                                    }
                                                }, () => {
                                                    this.reloadFromLocalData();
                                                })
                                            }}
                                            onChange={(values) => {

                                            }}
                                        />
                                        <div className='buttons' style={{ display: "flex" }}>
                                            <button className='reset' style={{ background: theme.colours.red2 }} onClick={() => {
                                                this.setState({
                                                    searchParams: {
                                                        ...this.state.searchParams,
                                                        customTimePreset: null
                                                    },
                                                    filterDropdown: null
                                                }, () => {
                                                    this.reloadFromLocalData();
                                                })
                                            }}>Back to Presets</button>
                                            <button onClick={() => {
                                                this.setState({
                                                    filterDropdown: null
                                                });
                                            }}>Apply & Close</button>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="filter timepreset">
                                <h3>
                                    <i className="fas fa-infinity"></i>
                                    <span>Global Search</span>
                                </h3>
                                <div className="simple-input">
                                    <input
                                        type="text"
                                        value={this.state.searchParams.globalSearch}
                                        id="search-by-all-input"
                                        onChange={({ target: { name, value } }) => {
                                            let searchParams = this.state.searchParams;
                                            searchParams.globalSearch = value;
                                            this.setState({
                                                searchParams: searchParams
                                            }, () => {
                                                // TODO debounce
                                                // not needed since only keyword no availability rerender this.reloadFromLocalData();
                                            })
                                        }}
                                        placeholder="Search in all Fields"
                                    />
                                    {/* { (this.state.searchParams.globalSearch) &&
                                        <i onClick={() => {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    globalSearch: ""
                                                }
                                            }, () => {
                                                this.reloadFromLocalData();
                                            })
                                        }} className='fas fa-times-circle' />
                                    } */}
                                </div>
                            </div>
                            <div className="filter timepreset">
                                <h3>
                                    <i className="fas fa-infinity"></i>
                                    <span>Order By</span>
                                </h3>
                                <div className='simple-select'>
                                    <select
                                        value={this.state.searchParams.sortType}
                                        onChange={(e) => {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    sortType: e.target.value as "distance" | "overall-rating" | "own-rating" | "most-available" | "last-updated" | "name",
                                                    mostAvailbleDates: e.target.value !== "most-available" ? [] : [0, 1, 2, 3, 4, 5, 6].map((i) => {
                                                        return Utilities.formatDate(Utilities.dateAdd(new Date(), "day", i), "YYYY-MM-DD");
                                                    })
                                                }
                                            });
                                        }}
                                    >
                                        {[
                                            { id: "last-updated", name: "Last Updated" },
                                            { id: "name", name: "Name" },
                                            { id: "overall-rating", name: "Overall Rating" },
                                            { id: "own-rating", name: "My Rating" },
                                            { id: "distance", name: "Distance" },
                                            { id: "most-available", name: "Most Available" }
                                        ].map((sort, i) => {
                                            return (
                                                <option value={sort.id}>
                                                    {sort.name}
                                                </option>
                                            )
                                        }) }
                                    </select>
                                </div>
                            </div>
                            <div className="filter timepreset">
                                <h3>
                                    <i className="fas fa-signature"></i>
                                    <span>Name</span>
                                </h3>
                                <div className="simple-input">
                                    <input
                                        type="text"
                                        value={this.state.searchParams.name}
                                        id="search-by-all-input"
                                        onChange={({ target: { name, value } }) => {
                                            let searchParams = this.state.searchParams;
                                            searchParams.name = value;
                                            this.setState({
                                                searchParams: searchParams
                                            }, () => {
                                                // TODO debounce
                                                // not needed since only keyword no availability rerender this.reloadFromLocalData();
                                            })
                                        }}
                                        placeholder="Search in all Fields"
                                    />
                                </div>
                            </div>
                            <div className="filter timepreset">
                                <h3>
                                    <i className="fas fa-users"></i>
                                    <span>Teams</span>
                                </h3>
                                <div className='simple-select'>
                                    <select 
                                        onClick={(e) => {
                                            this.setState({
                                                filterDropdown: null
                                            });
                                        }}
                                        value={this.state.searchParams.teamId}
                                        onChange={(e) => {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    teamId: parseInt(e.target.value),
                                                    groupId: parseInt(e.target.value) // deault everyone group has same id as team
                                                }
                                            })
                                        }}
                                    >
                                        {this.state.teams.map((team, i) => {
                                            return (
                                                <option value={team.id}>
                                                    {team.name}
                                                </option>
                                            )
                                        }) }
                                    </select>
                                </div>
                            </div>
                            <div className="filter timepreset">
                                <h3>
                                    <i className="far fa-folder"></i>
                                    <span>Groups</span>
                                </h3>
                                <div className='simple-select'>
                                    <select 
                                        onClick={(e) => {
                                            this.setState({
                                                filterDropdown: null
                                            });
                                        }}
                                        value={this.state.searchParams.groupId}
                                        onChange={(e) => {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    groupId: parseInt(e.target.value) 
                                                }
                                            })
                                        }}
                                    >
                                        {this.state.groups.filter(g => g.teamId === this.state.searchParams.teamId).map((group, i) => {
                                            return (
                                                <option value={group.id}>
                                                    {group.name}
                                                </option>
                                            )
                                        }) }
                                    </select>
                                </div>
                            </div>
                            <div className="filter timepreset" onClick={() => {
                                if (this.state.filterDropdown !== "attributes") {
                                    this.setState({
                                        filterDropdown: "attributes"
                                    })
                                }
                            }}>
                                <div className='head' onClick={() => {
                                    if (this.state.filterDropdown === "attributes") {
                                        this.setState({
                                            filterDropdown: null
                                        })
                                    }
                                }}>
                                    <h3>
                                        <i className="fas fa-tag"></i>
                                        <span>Sectors, Skills</span>
                                    </h3>
                                    <div className="simple-tags">
                                        { this.state.searchParams.attributeIds.length === 0 ? "No Skills Selected" : (this.state.searchParams.attributeIds.length > 1 ? (this.state.searchParams.attributeIds.length + " Skills Selected") : "1 Skill Selected") }
                                    </div>
                                    </div>
                                { (this.state.filterDropdown === "attributes") &&
                                    <div className='filter-dropdown'>
                                        <div className='header'>
                                            <h3>Select/Search Sectors, Subsectors, Skills</h3>
                                            <div className='sub-filters'>
                                                <input
                                                    type="text"
                                                    value={this.state.skillsFilter}
                                                    id="search-by-all-input"
                                                    onChange={({ target: { name, value } }) => {
                                                        this.setState({
                                                            skillsFilter: value.toLowerCase()
                                                        })
                                                    }}
                                                    placeholder="Search Skills"
                                                />
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    value={this.state.skillsSearchType}
                                                    exclusive
                                                    onChange={(e, newType) => {
                                                        this.setState({
                                                            skillsSearchType: newType as "AND" | "OR"
                                                        })
                                                    }}
                                                >
                                                    <ToggleButton value="AND">AND</ToggleButton>
                                                    <ToggleButton value="OR">OR</ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        </div>
                                        <div className='attributes-wrapper'>
                                            { [... new Set(this.state.userAttributes.map(attr => attr.sectorId))].map((sectorId) => {
                                                const sectorName = this.state.userAttributes.find(attr => attr.sectorId === sectorId)?.sectorName;
                                                const anySkillsMatch = !this.state.skillsFilter || this.state.userAttributes.filter(attr => attr.sectorId === sectorId).some(attr => attr.skillName.toLowerCase().includes(this.state.skillsFilter));
                                            
                                                if (!anySkillsMatch) {
                                                    return null;
                                                }

                                                return (
                                                    <React.Fragment>
                                                        <h4>{sectorName || "General Sectors"}</h4>
                                                        { [...new Set(this.state.userAttributes.filter(attr => attr.sectorId === sectorId).map(s => s.subSectorId))].map((subSectorId) => {
                                                            const subSector = this.state.userAttributes.find(attr => attr.subSectorId === subSectorId);
                                                            if (!subSector) {
                                                                return null;
                                                            }

                                                            const anySkillsMatch = !this.state.skillsFilter || this.state.userAttributes.filter(attr => attr.subSectorId === subSectorId).some(attr => attr.skillName.toLowerCase().includes(this.state.skillsFilter));
                                                            if (!anySkillsMatch) {
                                                                return null;
                                                            }

                                                            return (
                                                                <React.Fragment>
                                                                    <h5>{subSector.subSectorName || "General Sub-sectors"}</h5>
                                                                    <div className='skills'>
                                                                        { [...new Set(this.state.userAttributes.filter(attr => attr.subSectorId === subSector.subSectorId).map(ss => ss.skillId))].map((skillId) => {
                                                                            const skill = this.state.userAttributes.find(attr => attr.skillId === skillId);
                                                                            if (!skill) {
                                                                                return null;
                                                                            }

                                                                            const match = !this.state.skillsFilter || skill.skillName.toLowerCase().includes(this.state.skillsFilter);
                                                                            if (!match) {
                                                                                return null;
                                                                            }
                                                                            
                                                                            const compoundId = `${sectorId}-${subSectorId}-${skillId}`;
                                                                            return (
                                                                                <div className='skill'>
                                                                                    <FormControlLabel
                                                                                        label={<span>
                                                                                            <span>{skill.skillName}</span>
                                                                                            <Badge badgeContent={skill.userIds.length} color="primary"></Badge>
                                                                                        </span>}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={this.state.searchParams.attributeIds.includes(compoundId)}
                                                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                                                                onChange={(e) => {
                                                                                                    let searchParams = this.state.searchParams;
                                                                                                    if (e.target.checked) {
                                                                                                        searchParams.attributeIds.push(compoundId);
                                                                                                    } else {
                                                                                                        searchParams.attributeIds = searchParams.attributeIds.filter(id => id !== compoundId);
                                                                                                    }
                                                                                                    this.setState({
                                                                                                        searchParams: searchParams
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }) }
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }) }
                                                    </React.Fragment>
                                                )
                                            }) }
                                        </div>
                                        <div className='buttons'>
                                            <button onClick={() => {
                                                this.setState({
                                                    filterDropdown: null
                                                })
                                            }}>Close</button>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="filter timepreset" onClick={(e) => {
                                e.stopPropagation();
                                if (this.state.filterDropdown !== "representations") {
                                    this.setState({
                                        filterDropdown: "representations"
                                    })
                                }
                            }}>
                                <div className='head' onClick={() => {
                                    if (this.state.filterDropdown === "representations") {
                                        this.setState({
                                            filterDropdown: null
                                        })
                                    }
                                }}>
                                    <h3>
                                        <i className="fas fa-clock"></i>
                                        <span>Representing Agencies</span>
                                    </h3>
                                    <div className="simple-tags">
                                        { this.state.searchParams.representingAgencies.length === 0 ? "None Selected" : (this.state.searchParams.representingAgencies.length > 1 ? (this.state.searchParams.representingAgencies.length + " Selected") : "1 Selected") }
                                    </div>
                                </div>
                                { (this.state.filterDropdown === "representations") &&
                                    <div className='filter-dropdown'>
                                        <div className='header'>
                                            <h3>Select Represeting Agencies</h3>
                                            <div className='sub-filters'>
                                                <input
                                                    type="text"
                                                    value={this.state.representationsFilter}
                                                    id="search-by-all-input"
                                                    onChange={({ target: { name, value } }) => {
                                                        this.setState({
                                                            representationsFilter: value.toLowerCase()
                                                        })
                                                    }}
                                                    placeholder="Search Agencies"
                                                />
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    value={this.state.representationsSearchType}
                                                    exclusive
                                                    onChange={(e, newType) => {
                                                        this.setState({
                                                            representationsSearchType: newType as "AND" | "OR"
                                                        })
                                                    }}
                                                >
                                                    <ToggleButton value="AND">AND</ToggleButton>
                                                    <ToggleButton value="OR">OR</ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        </div>
                                        <div className='representations-wrapper'>
                                            { this.state.userRepresentations.map((agency) => {
                                                const orgNameMatches = !this.state.representationsFilter || agency.name.toLowerCase().includes(this.state.representationsFilter);
                                                if (!orgNameMatches || !agency.id) {
                                                    return null;
                                                }
                                                return (
                                                    <div className='representing-agency' data-id={agency.id}>
                                                        <FormControlLabel
                                                            label={<span>
                                                                <img src={CompanyApi.getOrganisationProfileImageUrl(agency.id)} />
                                                                <span>{agency.name}</span>
                                                                <Badge badgeContent={agency.userIds.length} color="primary"></Badge>
                                                            </span>}
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.searchParams.representingAgencies.includes(agency.id)}
                                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                                                    onChange={(e) => {
                                                                        let searchParams = this.state.searchParams;
                                                                        if (e.target.checked) {
                                                                            searchParams.representingAgencies.push(agency.id);
                                                                        } else {
                                                                            searchParams.representingAgencies = searchParams.representingAgencies.filter(id => id !== agency.id);
                                                                        }
                                                                        this.setState({
                                                                            searchParams: searchParams
                                                                        })
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                )
                                            }) }
                                        </div>
                                        <div className='buttons'>
                                            <button onClick={() => {
                                                this.setState({
                                                    filterDropdown: null
                                                })
                                            }}>Close</button>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="filter timepreset">
                                <h3>
                                    <i className="fas fa-route"></i>
                                    <span>Show Distance from</span>
                                </h3>
                                <div className='simple-select'>
                                    <select 
                                        onClick={(e) => {
                                            this.setState({
                                                filterDropdown: null
                                            });
                                        }}
                                        value={this.state.searchParams.locationId}
                                        onChange={(e) => {
                                            this.setState({
                                                searchParams: {
                                                    ...this.state.searchParams,
                                                    locationId: e.target.value
                                                }
                                            })
                                        }}
                                    >
                                        {this.state.locations.map((location, i) => {
                                            return (
                                                <option value={location.id}>
                                                    {location.locationFriendlyAddress}
                                                    {location.locationPlaceName ? " -" + location.locationPlaceName + "" : ""}
                                                    {location.postCode ? " -" + location.postCode + "" : ""}
                                                </option>
                                            )
                                        }) }
                                    </select>
                                </div>
                            </div>

                            {/* <div className="filter timepreset">
                                <h3>
                                    <i className="fas fa-clock"></i>
                                    <span>MORE FILTERS</span>
                                </h3>
                                <div className="simple-input">
                                    <input
                                        type="text"
                                        value={""}
                                        id="search-by-all-input"
                                        placeholder="TODO"
                                    />
                                </div>
                            </div> */}
                        </React.Fragment>
                    }
                </div>
                <div 
                    className='people'
                    ref={this.peopleRef}
                    onScroll={(e) => {
                        // if (!this.datesRef.current || !this.peopleRef.current) {
                        //     return;
                        // }
                        // this.datesRef.current.scrollLeft = this.peopleRef.current.scrollLeft;
                    }}
                >
                    { (this.state.view === "expanded" || this.state.view === "hovered") &&
                        <div 
                            className='dates'
                            style={{
                                width: (numberOfAvailabilityDays * 25 + 170) + "px"
                            }}
                            ref={this.datesRef}
                            onScroll={(e) =>{ 
                                // if (!this.datesRef.current || !this.peopleRef.current) {
                                //     return;
                                // }
                                // this.peopleRef.current.scrollLeft = this.datesRef.current.scrollLeft;
                            }}
                        >
                            <div className='sync-status' onClick={() => {
                                if (!this.state.syncing) {
                                    this.sync();
                                }
                            }}>
                                { (this.state.syncing) ?
                                    <span>
                                        <CircularProgress thickness={6} size={20} />
                                        <span>Fetching Updates</span>
                                    </span>
                                    :
                                    <span>
                                        <span>Last Synced {Utilities.timeSince(this.state.lastSynced*1000)}</span>
                                    </span>
                                }
                            </div>
                            { [...Array(numberOfAvailabilityDays).keys()].map((i) => {
                                const date = Utilities.dateAdd(startDay, "day", i);
                                return (
                                    <div 
                                        className='date'
                                        data-weekend={date.getDay() === 0 || date.getDay() === 6}
                                        data-selected={this.state.searchParams.mostAvailbleDates.includes(Utilities.formatDate(date, "YYYY-MM-DD"))}
                                        onClick={() => {
                                            let selectedDays = this.state.searchParams.mostAvailbleDates;
                                            if (selectedDays.includes(Utilities.formatDate(date, "YYYY-MM-DD"))) {
                                                selectedDays = selectedDays.filter(d => d !== Utilities.formatDate(date, "YYYY-MM-DD"));
                                            } else {
                                                selectedDays.push(Utilities.formatDate(date, "YYYY-MM-DD"));
                                            }
                                            console.log("___ selectedDays", selectedDays);
                                            if (!selectedDays.length) {
                                                this.setState({
                                                    searchParams: {
                                                        ...this.state.searchParams,
                                                        sortType: "last-updated",
                                                        mostAvailbleDates: []
                                                    }
                                                })
                                            } else {
                                                this.setState({
                                                    searchParams: {
                                                        ...this.state.searchParams,
                                                        mostAvailbleDates: selectedDays,
                                                        sortType: "most-available"
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        <span>{Utilities.formatDate(date, "d")}</span>
                                        <span>{Utilities.formatDate(date, "ms")}</span>
                                    </div>
                                )
                            }) }
                        </div>
                    }
                    { Object.values(this.state.userProfiles).map(user => {
                        user.totalRatings = user.ratings ? user.ratings.length : 0;
                        user.avgRating = user.totalRatings ? Math.round(user.totalRatings > 0 ? user.ratings.reduce((acc, r) => acc + r.stars, 0) / user.totalRatings : 0) * 10 / 10 : 0;
                        user.ownRating = user.totalRatings ? user.ratings.find(r => r.raterUserId === this.state.ownUserId) || undefined : undefined;
                        user.milesDistance = currentOgLocationUserDistances.find(u => u.id === user.id)?.milesDistance ? Math.round(currentOgLocationUserDistances.find(u => u.id === user.id)?.milesDistance || 1000000) : 1000000;
                        const lastTimelineUpdatedAtTs = user.lastTimelineUpdateAt ? new Date(user.lastTimelineUpdateAt).getTime() : 0;
                        const lastAvailabilityCofirmedTs = user.availabilityLastConfirmed ? new Date(user.availabilityLastConfirmed).getTime() : 0;
                        user.lastUpdatedTs = Math.max(lastTimelineUpdatedAtTs, lastAvailabilityCofirmedTs) || 0;
                        user.totalAvailableMinutesForSelectedDays = this.state.searchParams.mostAvailbleDates.reduce((acc, date) => acc + (user.totalAvailableMinutesPerDay[date] || 0), 0);
                        return user;
                    }).sort((a, b) => {
                        switch (this.state.searchParams.sortType) {
                            case "name":
                                return a.firstName.localeCompare(b.firstName);
                            case "last-updated":
                                return (b.lastUpdatedTs || 0) - (a.lastUpdatedTs || 0);
                            case "overall-rating":
                                return (b.avgRating || 0) - (a.avgRating || 0);
                            case "own-rating":
                                return (b.ownRating ? b.ownRating.stars : 0) - (a.ownRating ? a.ownRating.stars : 0);
                            case "distance":
                                return (a.milesDistance || 0) - (b.milesDistance || 0);
                            case "most-available":
                                return (b.totalAvailableMinutesForSelectedDays || 0) - (a.totalAvailableMinutesForSelectedDays || 0);
                            default:
                                return 0;
                        }
                    }).map((user) => {
                        // const user = this.state.userProfiles[user.id];
                        if (!user || !user.firstName) {
                            console.log("___ no user", user);
                            return null;
                        }

                        // unique based on oranisationId+typeId (only if both preset)
                        // cannot do this, otherwise active, and recently represented collides user.verifications = user.verifications ? user.verifications.filter((v, i, a) => a.findIndex(t => t.organisationId === v.organisationId && t.typeId === v.typeId) === i) : [];

                        if (user.id === '3f6cc7b1-63f6-4348-8c3f-93188cd62a60')
                            console.log(user.verifications.filter(a => a.typeId === 2), "VERI ______")

                        const loweredKeywords = this.state.searchParams.globalSearch.toLowerCase();
                        const loweredFirstName = user.firstName.toLowerCase();
                        const loweredLastName = user.lastName.toLowerCase();

                        const invisible = (
                            !groupUserIds.includes(user.id) ||
                            this.state.searchParams.attributeIds.length > 0 && ( 
                                (this.state.skillsSearchType === "OR" && !this.state.searchParams.attributeIds.some(attrId => user.attributes.find(attr => `${attr.sectorId}-${attr.subSectorId}-${attr.skillId}` === attrId)) ) ||
                                (this.state.skillsSearchType === "AND" && !this.state.searchParams.attributeIds.every(attrId => user.attributes.find(attr => `${attr.sectorId}-${attr.subSectorId}-${attr.skillId}` === attrId)) )
                            ) ||
                            this.state.searchParams.name && !loweredFirstName.includes(this.state.searchParams.name.toLowerCase()) && !loweredLastName.includes(this.state.searchParams.name.toLowerCase()) ||
                            ( this.state.searchParams.globalSearch &&
                                (
                                    !(user.firstName && loweredFirstName.includes(loweredKeywords)) &&
                                    !(user.lastName && loweredLastName.includes(loweredKeywords)) &&
                                    !(user.headline && user.headline.toLowerCase().includes(loweredKeywords)) &&
                                    !user.attributes.find(attr => attr.skillName.toLowerCase().includes(loweredKeywords)) &&
                                    !(user.qualifications.find(qual => qual.field.toLowerCase().includes(loweredKeywords) || qual.degree.toLowerCase().includes(loweredKeywords)))
                                )
                            ) ||
                            (this.state.searchParams.representingAgencies.length > 0 && (
                                (this.state.representationsSearchType === "OR" && !this.state.userRepresentations.filter(rep => this.state.searchParams.representingAgencies.includes(rep.id)).some(rep => rep.userIds.includes(user.id))) ||
                                (this.state.representationsSearchType === "AND" && !this.state.userRepresentations.filter(rep => this.state.searchParams.representingAgencies.includes(rep.id)).every(rep => rep.userIds.includes(user.id)))
                            ))
                        )

                        return (
                            <div className='person' data-hidden={invisible}>
                                <div className='profile'>
                                    <div className='scrolling-user-profile' onClick={() => {
                                        this.setState({
                                            openContactId: user.contactId
                                        })
                                    }}>
                                        <div className='verified-badge' data-verified={user.verified}>
                                            <i className="fas fa-shield-alt"></i>
                                        </div>
                                        <div className='profile-image'>
                                            <img src={ProfileApi.getProfileImageUrl(user.id)} />
                                        </div>
                                        { (this.state.view !== "collapsed") &&
                                            <div className='meta'>
                                                <p>{user.firstName} {user.lastName}</p>
                                                <div className='options'>
                                                    <button>
                                                        <i className="fas fa-briefcase"></i>
                                                    </button>
                                                    <button>
                                                        <i className="fas fa-user"></i>
                                                    </button>
                                                    <button>
                                                        <i className="fas fa-comment"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='user-profile'>
                                        {/* <div className='row headline'>
                                            {user.headline}
                                        </div> */}
                                        <div className='row skills'>
                                            {user.attributes.length > 0 ? [...new Set(user.attributes.map(attr => attr.skillName))].map((skillName) => {
                                                return (
                                                    <span>{skillName}</span>
                                                )
                                            }) : <span>No Skills</span>}
                                        </div>
                                        <div className='row others'>
                                            <SimpleTooltip
                                                placement='bottom'
                                                id={user.id + "-verifications"}
                                                text={
                                                    <div className={'last-updated-tooltip' + user.id}>
                                                        <div style={{ fontWeight: 700 }}>Last Confirmed Availability</div>
                                                        <div>{Utilities.formatDate(new Date(user.lastUpdatedTs || 0), "YYYY MMM DD HH:MM")}</div>
                                                        <div>({Utilities.timeSince(user.lastUpdatedTs)})</div>
                                                    </div>
                                                }>
                                                    <span className='last-updated'>
                                                        <i className="fas fa-clock"></i>
                                                        <span>{Utilities.timeSince(user.lastUpdatedTs, true)}</span>
                                                    </span>
                                            </SimpleTooltip>
                                            <SimpleTooltip
                                                placement='bottom'
                                                id={user.id + "-verifications"}
                                                text={
                                                    <div className={'last-updated-tooltip' + user.id}>
                                                        <div style={{ fontWeight: 700 }}>Distance from you</div>
                                                        <div>{user.milesDistance !== 1000000 ? (user.milesDistance + "mi") : "-"}</div>
                                                        <div style={{ fontWeight: 700 }}>Max Distance willing to travel</div>
                                                        <div>{user.maxDistance ? (user.maxDistance + "mi") : "-"}</div>
                                                    </div>
                                                }>
                                                <span className='distance'>
                                                    <i className="fas fa-location-arrow"></i>
                                                    <span>{user.milesDistance !== 1000000 ? (user.milesDistance + "mi") : "-"}</span>
                                                </span>
                                            </SimpleTooltip>
                                            <SimpleTooltip
                                                placement='bottom'
                                                id={user.id + "-rating"}
                                                text={
                                                    <div className={'rating-tooltip' + user.id}>
                                                        { (user.ownRating) ?
                                                            <React.Fragment>
                                                                <div style={{ fontWeight: 700 }}>Your Rating</div>
                                                                <div>
                                                                    {user.ownRating.stars} Stars
                                                                </div>
                                                                <div style={{ fontWeight: 700 }}>Public Comment</div>
                                                                <div>{user.ownRating.publicComment || "-"}</div>
                                                                <div style={{ fontWeight: 700 }}>Private Comment</div>
                                                                <div>{user.ownRating.privateComment + "-"}</div>
                                                            </React.Fragment>
                                                            :
                                                            <div>
                                                                You haven't left a rating yet
                                                            </div>
                                                        }
                                                    </div>
                                                }>
                                                    <span className='own-rating'>
                                                        <i className="fas fa-star"></i>
                                                        <span>{user.ownRating ? user.ownRating.stars : "-"}</span>
                                                    </span>
                                            </SimpleTooltip>
                                            <SimpleTooltip
                                                placement='bottom'
                                                id={user.id + "-rating"}
                                                text={
                                                    <div className={'rating-tooltip' + user.id}>
                                                        { (user.totalRatings) ?
                                                            <React.Fragment>
                                                                <div style={{ fontWeight: 700 }}>Overall Rating</div>
                                                                <div>{user.avgRating}</div>
                                                                <div style={{ fontWeight: 700 }}>Total Ratings</div>
                                                                <div>{user.totalRatings}</div>
                                                            </React.Fragment>
                                                            :
                                                            <div>
                                                                No ratings yet
                                                            </div>
                                                        }
                                                    </div>
                                                }>
                                                    <span className='global-rating'>
                                                        <i className="fas fa-star"></i>
                                                        <span>{user.totalRatings ? (user.avgRating + " (" + user.totalRatings + ")") : "-"}</span>
                                                    </span>
                                            </SimpleTooltip>
                                            { (this.state.organisationIsAgency) ?
                                                <span></span>
                                                :
                                                (user.verifications && user.verifications.find(v => v.organisationId)) ?
                                                    <SimpleTooltip
                                                        placement='bottom'
                                                        id={user.id + "-verifications"}
                                                        text={
                                                            <div className='verification-tooltip'>
                                                                {
                                                                    [{
                                                                        id: VerificationTypeId.Identity,
                                                                        label: "Profile Approved By",
                                                                    }, {
                                                                        id: VerificationTypeId.Representation,
                                                                        label: "Represented By"
                                                                    }, {
                                                                        id: VerificationTypeId.Representation,
                                                                        type: "past",
                                                                        label: "Previously Represented By"
                                                                    }, {
                                                                        id: VerificationTypeId.Vetting,
                                                                        label: "Vetted By"
                                                                    }, {
                                                                        id: VerificationTypeId.Suspension,
                                                                        label: "Temporarily Suspended By"
                                                                    }].map((v) => {
                                                                        // @ts-ignore
                                                                        let verifications = user.verifications ? user.verifications.filter(ve => {
                                                                            return ve.organisationId &&
                                                                            ve.typeId === v.id &&
                                                                            (v.type === "past" ? (ve.end && (moment(ve.end).add(6, "months") > moment())) : !ve.end)
                                                                        }) : []
    
                                                                        // if any active of this type, then cannot be past even if there are ended in the past
                                                                        if (v.type === "past" && (user.verifications || []).find(ve => ve.typeId === v.id && !ve.end)) {
                                                                            return null;
                                                                        }
    
                                                                        if (!verifications?.length) {
                                                                            return null;
                                                                        }
    
                                                                        return (
                                                                            <div className="verification-type">
                                                                                <span>{v.label}</span>
                                                                                <div>
                                                                                    { verifications && [...new Set(verifications.filter(ve => ve.organisationId).map(ve => ve.organisationId))].map(ve => {
                                                                                        const orgId = ve
                                                                                        const orgName = verifications?.find(v => 
                                                                                            (orgId && v.organisationId === orgId))?.organisationName
    
                                                                                        return (
                                                                                            <div>
                                                                                                <img width={22} height={22} src={CompanyApi.getOrganisationProfileImageUrl(orgId)} alt={orgName || ""} />
                                                                                            </div>
                                                                                        )
                                                                                    }) }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    >
                                                        <div className='verifications'>
                                                            { [...new Set(
                                                                user.verifications.filter(v =>
                                                                    (v.organisationId) && 
                                                                    (v.typeId === VerificationTypeId.Representation || v.typeId === VerificationTypeId.Suspension)
                                                                ).map(v => (v.organisationId || "null")))
                                                            ].map((key) => {
                                                                const orgId = key
                                                                const orgName = user.verifications?.find(v => (orgId && v.organisationId === orgId))?.organisationName

                                                                const imageUrl = orgId ? CompanyApi.getOrganisationProfileImageUrl(orgId) : null
                                                                const suspended = user.verifications?.find(v => ((orgId && v.organisationId === orgId)) && v.typeId === VerificationTypeId.Suspension)
                                                                const activeRepresentation = user.verifications?.find(v => ((orgId && v.organisationId === orgId)) && v.typeId === VerificationTypeId.Representation && !v.endedAt)
                                                                const coolDownPeriod = !suspended && !activeRepresentation && user.verifications?.find(v => ((orgId && v.organisationId === orgId)) && v.typeId === VerificationTypeId.Representation && v.endedAt && moment(v.end).add(6, "months") > moment()) // there was a representation, where end date is not more than 6 months away from today

                                                                if ("3f6cc7b1-63f6-4348-8c3f-93188cd62a60" === user.id && orgId === "AACEE532-A923-4F4E-8913-15204138DBBA")
                                                                    console.log("Active: :", activeRepresentation, coolDownPeriod, user.verifications.filter(a => a.organisationId === "AACEE532-A923-4F4E-8913-15204138DBBA"), user.verifications, "<<<<VERI2 ________")

                                                                if ((imageUrl) && (suspended || activeRepresentation || coolDownPeriod)) {
                                                                    return (
                                                                        <div className='verification-status-with-org'>
                                                                            <img src={imageUrl} alt={orgName || ""} />
                                                                            <div className='status' data-status={suspended ? 4 : activeRepresentation ? 3 : 10}>
                                                                                <i className={suspended ? "fas fa-exclamation" : activeRepresentation ? "fa fa-check" : "fas fa-hourglass" } />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                return null;
                                                            }) }
                                                        </div>
                                                    </SimpleTooltip>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    { (this.state.view !== "collapsed") &&
                                        <div className='availability'>
                                            { (dayHashRange.map((dayHash) => {
                                                const weekend = new Date(dayHash).getDay() === 0 || new Date(dayHash).getDay() === 6;

                                                if (!user.compiledAvailability[dayHash]) {
                                                    return (
                                                        <div className='day' data-weekend={weekend}></div>
                                                    )
                                                }

                                                return (
                                                    <div className='day' data-weekend={weekend}>
                                                        { user.compiledAvailability[dayHash].map((slot) => {
                                                            return (
                                                                <div 
                                                                    className='slot'
                                                                    style={{
                                                                        top: slot.top,
                                                                        height: slot.height
                                                                    }}
                                                                >
                                                                    { (slot.height > 18) &&
                                                                        <i className="fas fa-check"></i>
                                                                    }
                                                                </div>
                                                            )
                                                        }) }
                                                    </div>
                                                )
                                            })) }
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    }) }
                    { (this.state.searchParams.globalSearch || this.state.searchParams.attributeIds.length > 0 || this.state.searchParams.name || this.state.searchParams.representingAgencies.length > 0) &&
                        <div className='clear-filters'>
                            <button onClick={() => {
                                this.clearFilters();
                            }}>Clear Filters</button>
                        </div>

                    }
                    { (this.state.openContactId) &&
                        <ContactProfileModal
                            // @ts-ignore
                            contactId={this.state.openContactId}
                            onClosed={() => {
                                this.setState({
                                    openContactId: ""
                                })
                            }}
                        />
                    }
                </div>
            </aside>
        );
    }
}

export default People;