/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import { 
    Image,
    DropdownButton,
    MenuItem,
    Modal,
    OverlayTrigger,
    Popover,
    Button
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import ContactProfileModal from '../../pages/ContactProfile';

import * as ContactAvailabilityStore from '../../store/Availability';
import { ApplicationState } from '../../store';
import { DateFormats, fromNowDays, PubSubTopics } from '../../constants';
import { Spinner } from '../ui-components/Spinner';
import ContactNudgeButton from '../contacts/ContactNudgeButton';
import theme from '../../css/theme';
import SimpleTooltip from '../ui-components/SimpleTooltip';
import SignalRService from '../../services/signalr.service';
import ContactRemovalProvider, {
    WorkerToRemoveModel
} from './ContactRemovalProvider';
import { SmallInputVariant } from '../ui-components/SmallInput';
import { sortOption } from './AvailabilityGridToolbar';
import { ContactAvailabilitySummaryModel } from '../../store/Availability';
import Dialog from '../ui-components/Dialog';
import Utilities from '../../Utilities';
import { EphemeralAccessTokenStorageKey } from '../layout/EphemeralAccessLayout';
import ReactStars from "react-rating-stars-component";
import { RatingType } from 'src/api/ratings/ResponseTypes';
import AvailabilityAPI from '../../api/availability/Availability';
import CompanyApi from '../../api/company/Company';
import OrganisationApi from '../../api/organisation/Organisation';
import InternalTracker from 'src/InternalTracker';
import DayPicker from 'react-day-picker';
import ReferContactModal from '../contacts/ReferContactModal';
import { VerificationTypeId } from 'src/pages/ExternalVerification';
import ContactProfile from 'src/pages/ContactProfile';

const DEMO_ACCOUNTS = [
    "dabf471d-86fc-4a3a-aa73-79d2b28e2fcf",
    "871425be-de60-4b18-a6aa-ab6f3d37c3ea",
    "2583d191-183c-422e-ab0d-a4e949233a11",
    "27135be7-83ca-44c0-adf6-a26dc40ab750",
    "28f82a82-5d2c-4d53-9ea4-78ed59d0ce28",
    "32243106-1262-4ba6-9aa4-1e8c6e857864",
    "55a8784e-4903-40b2-808a-7bfefbec7876",
    "68b32a5a-6db5-412e-8fe9-76fdce89b066",
    "6d339cc1-1b50-4f10-93e9-399ad594364c",
    "6dfcc883-a3fa-485f-a0ce-f1f939d54fc4",
    "724ca1b6-1ff4-44e1-b25b-935be4a7fe4e",
    "abadc433-f89d-4e51-84a8-f86ff109d7e7",
    "ba5bea3e-9fe4-4b13-b70d-9e208e276c44",
    "d4b9d42e-e4df-4156-800a-55ce3c3ead27",
    "ddfc0f9c-432f-4acc-8823-a5886221e528",
    "deed3f40-7989-4173-acfe-c34fd32439ff",
    "eb6f6025-7a3e-4aa0-919c-d17fb8381091",
    "f010e492-4859-4d4a-99f6-ea43bd735697",
    "18dbd2dc-7039-407e-8805-1e71be5c06f6",
    "f5af2f44-c659-4bda-998b-fd535195c69b",
    "3d549426-ccf8-4c3b-98b7-86b59659178f",
    "e9857004-25ea-40a6-9bc5-e44c288e9259",
    "a9445650-7108-432a-90bd-eedc007bceff",
    "ffcc0264-4c2b-423f-9978-7bed8a996d78",
    "37edb48d-f275-49d3-b2bf-0e9595110a80",
    "a6471ce8-f9be-43e0-bc9b-7d29f61451e9",
    "27285ef8-7078-44d0-a3a1-43e704087a7f",
    "12e929c0-84f9-4e60-808c-d310b0d4a8c3",
    "92bb2147-5bb3-409b-a396-85294dd3886a"
]

const SHOWCASE_CONTACTS = [
    {
        "contact": {
            "id": "1f12ff1c-9599-4472-924c-7c1ec06241d4",
            "userId": "1f12ff1c-9599-4472-924c-7c1ec06241d4",
            "firstName": "Mark",
            "lastName": "Payne",
            "fullName": "Mark Payne",
            "profileImageUrl": "https://portal.updatedge.com/legacyapi/publicapi/user/image/1f12ff1c-9599-4472-924c-7c1ec06241d4",
            "lastAvailabilityUpdateOn": moment(),
            "verified": true,
            "dummy": true,
            "email": "",
            "companyId": "", 
            "companyName": "",
            notificationStatusTypeId: 0,
            distance: 43,
            representedByOrganisations: []
        },
        "occupancy": {
            "totalIntervalMinutes": 3000,
            "totalOccupancyMinutes": 540,
            "totalOccupancyPercentage": 18,
            "occupancies": [
            ]
        }
    }
]

type LocalProps = {
    searchParams: ContactAvailabilityStore.AvailabilityParams;
    supportsDaySelection: boolean;
    supportsUserSelection: boolean;
    contactAvailabilityActions: typeof ContactAvailabilityStore.actionCreators;
    key: RouteComponentProps['location']['key'];
    setAvailabilityPrinter: (availabilityPrinter: () => void) => void;
    joyrideShowing?: boolean;
};

export type ContactAvailabilitySummaryGridProps = LocalProps &
    ContactAvailabilityStore.ContactAvailabilityState;

interface LocalState {
    filterValue: string;
    workerToRemove: WorkerToRemoveModel | null;
    onlyShowContact: string | null;
    hideWeekends: boolean;
    configureViewModal?: boolean;
    componentViewPreferences: { [key: string]: boolean }
    calendarIsOpen: boolean;
    lastContacts: ContactAvailabilityStore.ContactAvailabilitySummaryModel[];
    lastContactMarker: number;
    ueUser: boolean;
    referredContacts: ContactAvailabilityStore.ContactAvailabilitySummaryModel['contact'][] | null;
    hiddenWorkers: string[];
    lastRemovedContactId: string | null;
    selectedWorkerPreset: string;
    openContactId: string;
}

interface XSmallCalendarButtonProps {
    onClick: (event: React.MouseEvent<Button>) => void;
    text: string;
}

class XSmallCalendarButton extends React.Component<
    XSmallCalendarButtonProps,
    {}
> {
    render() {
        return (
            <Button
                bsStyle="primary"
                bsSize="xsmall"
                onClick={this.props.onClick}
            >
                <i className="fa fa-calendar" />
                &nbsp;{this.props.text}
            </Button>
        );
    }
}

export class AvailabilityGrid extends React.Component<
    ContactAvailabilitySummaryGridProps,
    LocalState
> {
    private teamUpdatedSub?: string;

    initialState: LocalState = {
        filterValue: '',
        workerToRemove: null,
        onlyShowContact: null,
        calendarIsOpen: false,
        hideWeekends: 
            localStorage.getItem('offersContinueOnWeekdays') === 'true' || location.pathname.startsWith('/external/'),
        componentViewPreferences: 
            localStorage.getItem("availabilityViewPreferences") ? 
                JSON.parse(localStorage.getItem("availabilityViewPreferences") || "{}") :
                { },
        lastContacts: [],
        lastContactMarker: 0,
        ueUser: localStorage.getItem("user") && JSON.parse(localStorage.getItem("user") || "{}").emailAddress.endsWith("@updatedge.com"),
        referredContacts: null,
        hiddenWorkers: [],
        lastRemovedContactId: null,
        selectedWorkerPreset: "",
        openContactId: ""
    };

    state: LocalState = this.initialState;

    calendarToggle = React.createRef<XSmallCalendarButton>();

    private availabilityPrintPageLocation =
        '/app-static/printable-availability.html';

    availabilityPrinter = () => {
        sessionStorage.setItem(
            'AvailabilityData',
            JSON.stringify(this.formatPrintOptions())
        );
        window.open(`${this.availabilityPrintPageLocation}`);
    };

    formatPrintOptions = () => {
        return {
            days: this.days,
            contacts: this.contacts
        };
    };

    startContactRemoval = (workerToRemove: LocalState['workerToRemove']) => {
        this.setState({ workerToRemove });
    };

    startReferral = (contact) => {
        console.log(contact)
    }

    closeRemoveContactsConfirmationModal = () => {
        this.setState({ workerToRemove: null });
    };

    /**
     * Reloads the grid
     */
    refreshGrid = () => {
        this.props.contactAvailabilityActions.getAvailability();
    };

    /**
     * Creates a repeating table header cell with each day
     * @param days Days to render
     */
    private renderDayColHeaders(days: ContactAvailabilityStore.DaySlotModel[]) {
        return days.map((dayInRange) => {
            const currentDayText = moment(dayInRange.start).format(
                DateFormats.ShortDateWithDay
            );
            const currentTimeRangeText =
                (dayInRange.start.hours() < 10 ? ("0" + dayInRange.start.hours()) : dayInRange.start.hours()) + ":" +
                (dayInRange.start.minutes() < 10 ? ("0" + dayInRange.start.minutes()) : dayInRange.start.minutes()) + "-" +
                (dayInRange.end.hours() < 10 ? ("0" + dayInRange.end.hours()) : dayInRange.end.hours()) + ":" +
                (dayInRange.end.minutes() < 10 ? ("0" + dayInRange.end.minutes()) : dayInRange.end.minutes());

            const isWeekend = dayInRange.start.isoWeekday() === 6 || dayInRange.start.isoWeekday() === 7
            return (
                <th
                    style={{ width: 150 }}
                    className={
                        'text-center col-date ' +
                        (this.props.supportsDaySelection &&
                        this.isDaySelected(dayInRange)
                            ? 'selected'
                            : '') + 
                        ((isWeekend)
                            ? " weekend"
                            : ""
                        ) +
                        ((this.state.hideWeekends && isWeekend)
                            ? " skip-weekend"
                            : ""
                        )
                    }
                    key={currentDayText}
                >
                    {(isWeekend && this.state.hideWeekends) ? (
                        (dayInRange.start.isoWeekday() === 6 ? "SAT" : "SUN") 
                    ) : moment().isSame(dayInRange.start, 'day') ? (
                        <div className="badge badge-primary">Today</div>
                    ) : (
                        <React.Fragment>
                            {currentDayText}
                            <span className="time">{currentTimeRangeText}</span>
                        </React.Fragment>
                    )}
                </th>
            );
        });
    }

    private toggleContactInSelection(
        contact: ContactAvailabilityStore.ContactAvailabilitySummaryModel
    ) {
        if (!this.props.supportsUserSelection) return;
        InternalTracker.trackEvent("Contact Selection Toggled", {
            contactId: contact.contact.id
        })
        this.props.contactAvailabilityActions.toggleContactInSelection(contact);
    }

    onFocus = () => {
        if (localStorage.getItem("justUpdatedContactRating")) {
            localStorage.removeItem("justUpdatedContactRating")
            this.refreshGrid();
        }
    }

    componentDidMount() {
        localStorage.removeItem("justUpdatedContactRating")

        window.addEventListener("focus", this.onFocus.bind(this));

        (window as any).onSignalREventsUpdatedAvailabilityGrid = (userId: string) => {
            this.props.contactAvailabilityActions.getAvailabilityForUser(
                userId
            );
        }

        (window as any).onSignalRContactStatusChangedAvailabilityGrid = (userId: string, contactId?: string, adding?: boolean) => {
            if (adding) {
                this.props.contactAvailabilityActions.getAvailabilityForUser(
                    userId,
                    contactId
                );
            } else {
                this.props.contactAvailabilityActions.getAvailability();
            }
        }

        if (!window.location.pathname.startsWith("/external")) {

            localStorage.removeItem("timesheetWorkersForLastPeriod")

        } else {

            if (!window.location.pathname.includes("/worker")) {
                setInterval(() => {
                    if (localStorage.getItem("timesheetWorkerSelected")) {
                        this.setState({
                            onlyShowContact: localStorage.getItem("timesheetWorkerSelected")
                        })
                        localStorage.removeItem("timesheetWorkersForLastPeriod")
                    }
                }, 1000)
            }

        }

        this.refreshGrid();

        this.props.setAvailabilityPrinter(this.availabilityPrinter.bind(this));

        (window as any).multiContactReferral = (contacts) => {
            this.setState({
                referredContacts: contacts
            })
        }
    }

    /**
     * Fires for each props or state change
     */
    async componentDidUpdate(prevProps: ContactAvailabilitySummaryGridProps) {
        const oldUserIds = prevProps.contacts
            .map((c) => c.contact.userId)
            .sort()
            .join('');
        const newUserIds = this.props.contacts
            .map((c) => c.contact.userId)
            .sort()
            .join('');

        if (prevProps.key !== this.props.key) {
            this.setState({
                ...this.initialState
            });
        }
    }

    componentWillUnmount() {
        this.props.contactAvailabilityActions.clearSelectedContacts();
    }

    renderDayAvailability(
        cam: ContactAvailabilityStore.ContactAvailabilitySummaryModel,
        day: ContactAvailabilityStore.DaySlotModel
    ) {
        // find same day in occupancy intervals
        let occupancyForDay = cam.occupancy.occupancies.filter(
            (occupancy) => {
                return moment(occupancy.start).isSame(moment(day.start), 'day');
            }
        );

        if (occupancyForDay.length === 0) {
            let minsDiff = Utilities.differenceBetweenDatesSeconds(moment(day.start || ""), moment(day.end || ""))
            let occupied = Utilities.randomIntFromInterval(0, 1);
            occupancyForDay = [{
                "start": day.start,
                "end": day.end,
                "intervalMinutes": minsDiff,
                "occupiedMinutes": occupied ? minsDiff : 0,
                "occupiedPercentage": occupied ? 100 : 0
            }];
        }

        const isWeekend = day.start.isoWeekday() === 6 || day.start.isoWeekday() === 7;
        if (isWeekend && this.state.hideWeekends) {
            return null;
        }

        if (cam.contact.notSharing) {
            return (
                <div>
                    <SimpleTooltip
                        id={cam.contact.id + "-verifications"}
                        text={cam.contact.fullName + " is not sharing their availability"}
                    >
                        <i className="fa fa-lock text-danger fa-2x" />
                    </SimpleTooltip>
                </div>
            );
        }

        if (!occupancyForDay[0]) {
            return null;
        } else if (occupancyForDay[0].occupiedPercentage === 0) {
            return (
                <div>
                    <i className="fa fa-check text-success fa-2x" />
                </div>
            );
        } else if (occupancyForDay[0].occupiedPercentage === 100) { 
            return (
                <div>
                    <i className="fa fa-times text-danger fa-2x" />
                </div>
            );
        } else {
            const freeMinutes = occupancyForDay[0].intervalMinutes - occupancyForDay[0].occupiedMinutes
            return (
                <div>
                    <span>{Utilities.convertMinsToHrsMins(freeMinutes)} available</span>
                </div>  
            );
        }
    }

    /**
     * Renders the percentage
     * @param percentage User percentage free
     */
    renderPercentageFree(occupancyPercentage: number) {
        const percentage = Math.round(100 - occupancyPercentage);
        return (
            <span
                className={
                    percentage === 100
                        ? 'text-success'
                        : percentage > 25
                        ? 'text-warning'
                        : 'text-danger'
                }
            >
                <strong>{percentage}%</strong>
            </span>
        );
    }

    /**
     * Renders a row for each contact with their daily availability
     * @param cam Contact being rendered
     * @param days Days for which contact's availability should be shown
     */
    private renderContactAndDays(
        cam: ContactAvailabilityStore.ContactAvailabilitySummaryModel,
        days: ContactAvailabilityStore.DaySlotModel[],
        dontShowOldUpdates?: boolean,
        dontShowOneStarRated?: boolean,
        index?: number,
        userIsAgency?: boolean
    ) {
        let calendarLink = cam.contact.dummy ? "" : window.location.pathname.startsWith("/external") ? ("/external/contact/" + cam.contact.id + "?token=" + window.location.href.split("?token=")[1]) : ('/contact/' + cam.contact.id);
        if ((window.location.href.indexOf("?cypress") !== -1)) {
            calendarLink += "?cypress=true";
        }
        const demoAccount = DEMO_ACCOUNTS.indexOf(cam.contact.userId) !== -1;
        const hotLoaded = cam.contact.hotLoaded
        const identityVerified = cam.contact.verifications?.find(v => v.typeId === VerificationTypeId.Identity && !v.end);
        const activeRepresentation = cam.contact.verifications?.find(v => v.typeId === VerificationTypeId.Representation && !v.end);
        const activeVetting = cam.contact.verifications?.find(v => v.typeId === VerificationTypeId.Vetting && !v.end);
        const activeSuspension = cam.contact.verifications?.find(v => v.typeId === VerificationTypeId.Suspension && !v.end);
        const sharing = !cam.contact.notSharing;

        console.log(cam.contact.verifications, activeRepresentation)

        const now = new Date();

        if (dontShowOldUpdates && (!cam.contact.lastAvailabilityUpdateOn || Utilities.dateAdd(new Date(cam.contact.lastAvailabilityUpdateOn.toString()), "day", 30) < now)) {
            return null;
        }

        if (dontShowOneStarRated && cam.contact.rating?.stars === 1) {
            return null;
        }

        if (this.state.hiddenWorkers.indexOf(cam.contact.userId) !== -1) {
            return null;
        }
        
        return (
            <tr
                key={cam.contact.id}
                className={'contact ' + (this.isContactSelected(cam) ? 'selected' : '')}
                data-already-invited={cam.contact.alreadyInvitedByOrgId ? "true" : "false"}
                onClick={() => {
                    InternalTracker.trackEvent("Viewed Calendar -> Redirect to Contact", {
                        source: "availabilitygird",
                        contactId: cam.contact.id
                    })
                    // window.open(calendarLink);
                }}
                data-fullname={cam.contact.firstName + " " + cam.contact.lastName}
            >
                { (cam.contact.alreadyInvitedByOrgId) &&
                    <div className='already-invited-warning'>
                        <i className="fa fa-exclamation-triangle" />
                        <span>Already added/referred to {cam.contact.alreadyInvitedByOrgName}</span>
                    </div>
                }
                { (this.state.lastContactMarker && this.state.lastContactMarker === index) ?
                    <div id="last-contact-marker">
                        More Contacts Loaded Below
                    </div>
                    : null
                }
                { (!window.location.pathname.includes("/worker")) &&
                    <td 
                        className="layout horizontal center col-name"
                        style={(demoAccount || hotLoaded) ? { paddingBottom: 34 } : {}}
                    >
                        { (demoAccount || hotLoaded) ?
                            <div
                                className={demoAccount ? 'demo-account' : 'hot-loaded'}
                                onClick={(e) => {
                                    if (demoAccount) {
                                        e.stopPropagation();
                                        InternalTracker.trackEvent("Removed Demo Account")
                                        AvailabilityAPI.removeWorkers([cam.contact.userId], false)
                                        .then(() => {
                                            toast.success(`Contact Removed`);
                                            this.refreshGrid();
                                        })
                                        .catch(() => {
                                            toast.error(`Failed to remove Contact`);
                                        });
                                    }
                                }}
                            >
                                { demoAccount ? "Demo Account - Tap to remove" : "Just Added You" }
                            </div>
                            :
                            null
                        }
                        { (this.state.componentViewPreferences.image !== false) &&
                            <Image
                                src={cam.contact.profileImageUrl}
                                style={{
                                    width: 52,
                                    height: 52
                                }}
                                circle
                                alt={cam.contact.fullName}
                                data-report-blurred={cam.contact.reported}
                                data-user-id={cam.contact.userId}
                            />    
                        }
                        <div className="right">
                            <NavLink
                                to={sharing ? calendarLink : ""}
                                className="m-l-xs"
                                style={{
                                    display: 'block'
                                }}
                                target={((window as any).Cypress) ? "_self" : "_blank"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                
                                }}
                            >
                                { (this.state.componentViewPreferences.verificationStatus !== false) &&
                                    <SimpleTooltip
                                        id="contact-verified-status"
                                        text={cam.contact.verified ? "Profile Approved" : "Profile Awaiting Approval"}
                                    >
                                        <i
                                            className="fa fa-shield m-l-xs"
                                            style={{
                                                color: cam.contact.verified
                                                    ? theme.colours.green
                                                    : theme.colours.red,
                                                opacity: cam.contact.verified ? 1 : 0.6,
                                                left: (this.state.componentViewPreferences.image !== false) ? 8 : 0
                                            }}
                                        />   
                                    </SimpleTooltip>
                                }
                                <div>
                                    <strong 
                                        className="contact-name"
                                        data-name={cam.contact.fullName}
                                        data-report-blurred={cam.contact.reported}
                                        style={ (this.state.componentViewPreferences.image === false && this.state.componentViewPreferences.verificationStatus === false) ? {
                                            paddingLeft: 0
                                        } : (this.state.componentViewPreferences.verificationStatus === false && this.state.componentViewPreferences.image !== false) ? {
                                            paddingLeft: 12
                                        } : (this.state.componentViewPreferences.verificationStatus !== false && this.state.componentViewPreferences.image === false) ? {
                                            paddingLeft: 30
                                        } : { }}
                                        data-user-id={cam.contact.userId}
                                    >
                                        {cam.contact.fullName}
                                    </strong>
                                    <DropdownButton
                                        id={cam.contact.id + "-dropdown"}
                                        title={''}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation()
                                        }}
                                    >
                                        <MenuItem
                                            onSelect={(e) => {
                                                InternalTracker.trackEvent("Sent Nudge -> Redirect to Contact", {
                                                    contactId: cam.contact.id
                                                })
                                                window.open(calendarLink);
                                            }}
                                        >
                                            Send Nudge
                                        </MenuItem>
                                        <MenuItem
                                            onSelect={(e) => {
                                                InternalTracker.trackEvent("Viewed Calendar -> Redirect to Contact", {
                                                    source: "availabilitygird",
                                                    contactId: cam.contact.id
                                                })
                                                window.open(calendarLink);
                                            }}
                                        >
                                            View Calendar
                                        </MenuItem>
                                        <MenuItem
                                            onSelect={(e) => {
                                                InternalTracker.trackEvent("Offer Creation Started", {
                                                    source: "availabilitygird",
                                                    contactId: cam.contact.id
                                                })
                                                if (window.location.pathname.startsWith("/external")) {
                                                    window.open(calendarLink);
                                                } else {
                                                    if ((window as any).Cypress) {
                                                        window.location.href = "/offers#create/worker/" + cam.contact.userId;
                                                    } else {
                                                        window.open("/offers#create/worker/" + cam.contact.userId);
                                                    }
                                                }
                                            }}
                                        >
                                            Send Offer
                                        </MenuItem>
                                        <MenuItem
                                            onSelect={(e) => {
                                                this.startContactRemoval(cam);
                                            }}
                                        >
                                            Remove Contact
                                        </MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.toggleContactInSelection(cam)
                                            }}
                                        >
                                            {this.isContactSelected(cam) ? "Deselect" : "Select"} Contact
                                        </MenuItem>
                                        <MenuItem
                                            onSelect={() => {
                                                const chatBtn = document.getElementById("chat-btn");
                                                if ((window as any).openChatForUser && chatBtn) {
                                                    if (!document.querySelector('.floating-btn.shown')) {
                                                        chatBtn.click();
                                                    }
                                                    (window as any).openChatForUser(cam.contact.userId);
                                                }
                                            }}
                                        >
                                            Chat
                                        </MenuItem>
                                    </DropdownButton>
                                    {cam.contact.headline && this.state.componentViewPreferences.headline !== false &&
                                        <label
                                            data-report-blurred={cam.contact.reported}
                                            style={(this.state.componentViewPreferences.image === false) ? {
                                                paddingLeft: 0
                                            } : {}}
                                            data-user-id={cam.contact.userId}
                                        >{cam.contact.headline}</label>
                                    }
                                    {cam.contact.privateRating && this.state.componentViewPreferences.privateComment !== false &&        
                                        <label 
                                            className='rating-comment'
                                            style={(this.state.componentViewPreferences.image === false) ? {
                                                paddingLeft: 0
                                            } : {}}
                                        >
                                            <i className="fas fa-lock"></i>
                                            {cam.contact.privateRating}
                                        </label>
                                    }
                                    {cam.contact.publicRating && this.state.componentViewPreferences.publicComment !== false &&        
                                        <label
                                            className='rating-comment'
                                            style={(this.state.componentViewPreferences.image === false) ? {
                                                paddingLeft: 0
                                            } : {}}
                                        >
                                            <i className="fas fa-comment-alt"></i>
                                            {cam.contact.publicRating}
                                        </label>
                                    }
                                    { (
                                        (cam.contact.rating && this.state.componentViewPreferences.ownRating !== false) || 
                                        (cam.contact.avgRating && this.state.componentViewPreferences.avgRating !== false)
                                    ) &&
                                        <div 
                                            className='multi-stars-wrapper'
                                            style={(this.state.componentViewPreferences.image === false) ? {
                                                paddingLeft: 0
                                            } : {}}
                                        >
                                            { (cam.contact.rating && this.state.componentViewPreferences.ownRating !== false) &&
                                                <div 
                                                    className='stars-wrapper'
                                                    data-stars={cam.contact.rating}
                                                >
                                                    <ReactStars
                                                        value={cam.contact.rating}
                                                        count={5}
                                                        size={14}
                                                        edit={false}
                                                        activeColor={"#ffd700"}
                                                    />
                                                </div>
                                            }
                                            { (cam.contact.avgRating && cam.contact.avgRating != "0" && this.state.componentViewPreferences.avgRating !== false) &&
                                                <div 
                                                    className='stars-wrapper avg-rating'
                                                    data-stars={cam.contact.rating}
                                                >
                                                    <i className="fas fa-star"></i>
                                                    <span>{cam.contact.avgRating} from {cam.contact.totalRatings} ratings</span>
                                                </div>
                                            }
                                            { (cam.contact.verifications && cam.contact.verifications.find(v => v.organisationId || v.contactEmail)) &&
                                                <SimpleTooltip
                                                    id={cam.contact.id + "-verifications"}
                                                    text={
                                                        <div className='verification-tooltip'>
                                                            {
                                                                [{
                                                                    id: VerificationTypeId.Identity,
                                                                    label: "Profile Approved By",
                                                                }, {
                                                                    id: VerificationTypeId.Representation,
                                                                    label: "Represented By"
                                                                }, {
                                                                    id: VerificationTypeId.Representation,
                                                                    type: "past",
                                                                    label: "Previously Represented By"
                                                                }, {
                                                                    id: VerificationTypeId.Vetting,
                                                                    label: "Vetted By"
                                                                }, {
                                                                    id: VerificationTypeId.Suspension,
                                                                    label: "Temporarily Suspended By"
                                                                }].map((v) => {
                                                                    // @ts-ignore
                                                                    let verifications = cam.contact.verifications ? cam.contact.verifications.filter(ve => {
                                                                        return ve.organisationId &&
                                                                        ve.typeId === v.id &&
                                                                        (v.type === "past" ? (ve.end && (moment(ve.end).add(6, "months") > moment())) : !ve.end)
                                                                    }) : []

                                                                    // if any active of this type, then cannot be past even if there are ended in the past
                                                                    if (v.type === "past" && (cam.contact.verifications || []).find(ve => ve.typeId === v.id && !ve.end)) {
                                                                        return null;
                                                                    }

                                                                    if (!verifications?.length) {
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <div className="verification-type">
                                                                            <span>{v.label}</span>
                                                                            <div>
                                                                                { verifications && [...new Set(verifications.filter(ve => ve.organisationId).map(ve => ve.organisationId))].map(ve => {
                                                                                    const orgId = ve
                                                                                    const orgName = verifications?.find(v => 
                                                                                        (orgId && v.organisationId === orgId))?.organisationName

                                                                                    return (
                                                                                        <div>
                                                                                            <img width={22} height={22} src={CompanyApi.getOrganisationProfileImageUrl(orgId)} alt={orgName || ""} />
                                                                                        </div>
                                                                                    )
                                                                                }) }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                >
                                                    <div className='verifications'>
                                                        { [...new Set(
                                                            cam.contact.verifications.filter(v =>
                                                                (v.organisationId) && 
                                                                (v.typeId === VerificationTypeId.Representation || v.typeId === VerificationTypeId.Suspension)
                                                            ).map(v => (v.organisationId || "null")))
                                                        ].map((key) => {
                                                            const orgId = key
                                                            const orgName = cam.contact.verifications?.find(v => (orgId && v.organisationId === orgId))?.organisationName

                                                            const imageUrl = orgId ? CompanyApi.getOrganisationProfileImageUrl(orgId) : null
                                                            const suspended = cam.contact.verifications?.find(v => ((orgId && v.organisationId === orgId)) && v.typeId === VerificationTypeId.Suspension)
                                                            const activeRepresentation = cam.contact.verifications?.find(v => ((orgId && v.organisationId === orgId)) && v.typeId === VerificationTypeId.Representation && !v.endedAt)
                                                            const coolDownPeriod = !suspended && !activeRepresentation && cam.contact.verifications?.find(v => ((orgId && v.organisationId === orgId)) && v.typeId === VerificationTypeId.Representation && v.endedAt && moment(v.end).add(6, "months") > moment()) // there was a representation, where end date is not more than 6 months away from today

                                                            if ((imageUrl) && (suspended || activeRepresentation || coolDownPeriod)) {
                                                                return (
                                                                    <div className='verification-status-with-org'>
                                                                        <img src={imageUrl} alt={orgName || ""} />
                                                                        <div className='status' data-status={suspended ? 4 : activeRepresentation ? 3 : 10}>
                                                                            <i className={suspended ? "fas fa-exclamation" : activeRepresentation ? "fa fa-check" : "fas fa-hourglass" } />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            return null;
                                                        }) }
                                                    </div>
                                                </SimpleTooltip>
                                            }
                                        </div>
                                    }
                                    { (cam.contact.rating && cam.contact.rating.stars) &&
                                        <div 
                                            className='stars-wrapper'
                                            data-stars={cam.contact.rating.stars}
                                        >
                                            <ReactStars
                                                value={cam.contact.rating.stars}
                                                count={5}
                                                size={14}
                                                edit={false}
                                                activeColor="#ffd700"
                                            />
                                        </div>
                                    }
                                </div>
                                { ((cam.contact.matchedKeywords && cam.contact.matchedKeywords.length > 0) || (cam.contact.distance !== null && cam.contact.distance !== undefined) ) &&
                                    <div className="contact-skills">
                                        { cam.contact.matchedKeywords && cam.contact.matchedKeywords.map(skill => {
                                            return (
                                                <div className='contact-skill' data-skill={skill.match}>
                                                    {skill.match}
                                                </div>
                                            )
                                        }) }
                                        { (cam.contact.distance !== null && cam.contact.distance !== undefined && this.state.componentViewPreferences.distance !== false) &&
                                            <div className='contact-skill contact-distance' data-skill=''>
                                                {cam.contact.distance || "1"}{localStorage.getItem("distance") || "mi"} away
                                            </div>
                                        }
                                    </div>
                                }
                            </NavLink>
                            { (cam.contact.dummy) &&
                                <div className="clearfix">
                                    <div 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <span>Just a sample contact</span>
                                    </div>
                                </div>
                            }
                            { (
                                !cam.contact.dummy &&
                                sharing &&
                                (
                                    this.state.componentViewPreferences.nudge !== false ||
                                    this.state.componentViewPreferences.offer !== false ||
                                    this.state.componentViewPreferences.remove !== false ||
                                    this.state.componentViewPreferences.calendar !== false
                                )
                            ) &&
                                <div
                                    className="clearfix single-contact-options"
                                    style={(this.state.componentViewPreferences.image === false) ? {
                                        paddingLeft: 0
                                    } : {}}
                                >
                                    { (this.state.componentViewPreferences.offer !== false) &&
                                        <SimpleTooltip
                                            id="offer-contact-tt"
                                            text={'Sending an offer to this contact (opens in a new tab)'}
                                        >
                                            <div 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    InternalTracker.trackEvent("Offer Creation Started", {
                                                        source: "availabilitygird",
                                                        contactId: cam.contact.id
                                                    })
                                                    if (window.location.pathname.startsWith("/external")) {
                                                        window.open(calendarLink);
                                                    } else {
                                                        window.open("/offers#create/worker/" + cam.contact.userId);
                                                    }
                                                }}
                                            >
                                                <i 
                                                    className="fas fa-briefcase-medical"
                                                />
                                                {/* <span>Offer</span> */}
                                            </div>
                                        </SimpleTooltip>
                                    }
                                    { !window.location.pathname.startsWith("/external") && (this.state.componentViewPreferences.chat !== false) &&
                                        <SimpleTooltip
                                            id="chat-contact-tt"
                                            text={'Start messaging with this contact (opens on this page)'}
                                        >
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    const chatBtn = document.getElementById("chat-btn");
                                                    if ((window as any).openChatForUser && chatBtn) {
                                                        if (!document.querySelector('.floating-btn.shown')) {
                                                            chatBtn.click();
                                                        }
                                                        (window as any).openChatForUser(cam.contact.userId);
                                                    }
                                                }}
                                                className="chat-contact-btn"
                                            >
                                                <i
                                                    style={{ marginLeft: '5px' }}
                                                    className="fa fa-comments-o fa-hover"
                                                />
                                                {/* <span>Chat</span> */}
                                            </div>
                                        </SimpleTooltip>
                                    }
                                    <SimpleTooltip
                                        id="select-contact-tt"
                                        text={'Select this contact for bulk actions (sending offer or nudge)'}
                                    >
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.toggleContactInSelection(cam)
                                            }}
                                            data-selected={this.isContactSelected(cam)}
                                        >
                                            <i 
                                                className="fas fa-hand-pointer"
                                            ></i>
                                            {/* <span>{this.isContactSelected(cam) ? "Deselect" : "Select"}</span> */}
                                        </div>
                                    </SimpleTooltip>
                                    { (this.state.componentViewPreferences.calendar !== false) &&
                                        <SimpleTooltip
                                            id="calendar-contact-tt"
                                            text={'Open the calendar of this contact (in a new tab) for more details'}
                                        >
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    InternalTracker.trackEvent("Viewed Calendar -> Redirect to Contact", {
                                                        source: "availabilitygird",
                                                        contactId: cam.contact.id
                                                    })
                                                    window.open(calendarLink);
                                                }}
                                            >
                                                <i
                                                    className="fas fa-calendar-alt"
                                                />
                                                {/* <span>Calendar</span> */}
                                            </div>
                                        </SimpleTooltip>
                                    }
                                    { (this.state.componentViewPreferences.nudge !== false) &&  
                                        <ContactNudgeButton
                                            contactId={cam.contact.id}
                                            class=""
                                            hideLabel={true}
                                            onClick={() => {
                                                InternalTracker.trackEvent("Sent Nudge -> Redirect to Contact", {
                                                    contactId: cam.contact.id
                                                })
                                                window.open(calendarLink);
                                            }}
                                        />
                                    }
                                    { !window.location.pathname.startsWith("/external") && (this.state.componentViewPreferences.remove !== false) &&
                                        <SimpleTooltip
                                            id="remove-contact-tt"
                                            text={'Remove this contact from your list (this will make them disappear from your colleagues\' lists too)'}
                                        >
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.startContactRemoval(cam);
                                                }}
                                                className="remove-contact-btn"
                                            >
                                                <i
                                                    style={{ marginLeft: '5px' }}
                                                    className="fas fa-trash-alt fa-hover"
                                                />
                                                {/* <span>Remove</span> */}
                                            </div>
                                        </SimpleTooltip>
                                    }
                                    { (userIsAgency) &&
                                        <SimpleTooltip
                                            id="refer-contact-tt"
                                            text={'Refer this contact to another contact'}
                                        >
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({
                                                        referredContacts: [cam.contact]
                                                    })
                                                }}
                                                className="refer-contact-btn"
                                            >
                                                <i
                                                    style={{ marginLeft: '5px' }}
                                                    className="fas fa-share fa-hover"
                                                />
                                            </div>
                                        </SimpleTooltip>
                                    }
                                </div>
                            }
                        </div>
                    </td>
                }
                { (userIsAgency) &&
                    <td
                        className="align-middle own-verifications"
                        onClick={() => {
                            this.setState({
                                openContactId: cam.contact.id
                            })
                        }}
                    >
                        <SimpleTooltip
                            id={cam.contact.id + "-id-verification"}
                            text={identityVerified ? ("Your Organisation has Verified and Approved " + cam.contact.fullName + "") : ("Your Organisation has not Verified " + cam.contact.fullName)}
                        >
                            <i data-active={identityVerified ? "true" : "false"} className="fas fa-id-badge"></i>
                        </SimpleTooltip>
                        <SimpleTooltip
                            id={cam.contact.id + "-rep-verifications"}
                            text={activeRepresentation ? ("Your Organisation is currently representing " + cam.contact.fullName) : ("Your Organisation is not currently representing " + cam.contact.fullName)}
                        >
                            <i data-active={activeRepresentation ? "true" : "false"} className="fas fa-handshake"></i>
                        </SimpleTooltip>
                        <SimpleTooltip
                            id={cam.contact.id + "-vet-verifications"}
                            text={activeVetting ? ("Your Organisation has vetted " + cam.contact.fullName) : ("Your Organisation has not vetted " + cam.contact.fullName)}
                        >
                            <i data-active={activeVetting ? "true" : "false"} className="fas fa-clipboard-check"></i>
                        </SimpleTooltip>
                        <SimpleTooltip
                            id={cam.contact.id + "-vet-verifications"}
                            text={activeSuspension ? ("Your Organisation has temporarily raised issue about " + cam.contact.fullName) : ("Your Organisation has no active raised issue about " + cam.contact.fullName)}
                        >
                            <i data-type={4} data-active={activeVetting ? "true" : "false"} className="fas fa-exclamation-circle"></i>
                        </SimpleTooltip>
                    </td>
                }
                {days.map((day) => {
                    const isWeekend = day.start.isoWeekday() === 6 || day.start.isoWeekday() === 7
                    return (
                        <td
                            key={`${cam.contact.id}_${moment(day.start).format(
                                DateFormats.ShortDate
                            )}`}
                            className={
                                'align-middle text-center col-date ' +
                                (this.props.supportsDaySelection &&
                                this.isDaySelected(day)
                                    ? 'selected'
                                    : '') +
                                ((this.state.hideWeekends && isWeekend)
                                    ? " skip-weekend"
                                    : "")
                            }
                        >
                            {this.renderDayAvailability(cam, day)}
                        </td>
                    );
                })}
                { (window.location.pathname.includes("/worker")) ?
                    <td className="align-middle text-center col-match overallMatch">
                        {cam.contact.confidenceScore}%
                    </td>
                    :
                    <td className="align-middle text-center col-match overallMatch">
                        {this.renderPercentageFree(
                            cam.occupancy.totalOccupancyPercentage
                        )}
                    </td>
                }
                <td className="align-middle text-right col-update">
                    <div
                        style={{
                            display: 'block',
                            textAlign: 'center'
                        }}
                    >
                        <div 
                            style={{ 
                                lineHeight: '18px', 
                                fontWeight: 700,
                                color: cam.contact.lastAvailabilityUpdateOn && moment(cam.contact.lastAvailabilityUpdateOn).toDate() < Utilities.dateSub(new Date(), "month", 1) ? theme.colours.red2 : undefined
                            }}
                        >
                            { !this.state.ueUser && cam.contact.lastAvailabilityUpdateOn && moment(cam.contact.lastAvailabilityUpdateOn).toDate() < Utilities.dateSub(new Date(), "month", 1) ?
                                "More than 1 month ago" :
                                cam.contact.lastAvailabilityUpdateOn ? 
                                    Utilities.timeSince(moment(cam.contact.lastAvailabilityUpdateOn).toDate())
                                    : "Never"
                            }
                        </div>
                    </div>
                </td>
            </tr>
        );
    }

    /** Determines if the current day being rendered has been previously selected */
    private isDaySelected(
        dayBeingRendered: ContactAvailabilityStore.DaySlotModel
    ) {
        return (
            this.props.daysSelected.filter((day) =>
                day.start.isSame(dayBeingRendered.start)
            ).length > 0
        );
    }

    /** Determines if the current contact being rendered is currently selected */
    private isContactSelected(
        cam: ContactAvailabilityStore.ContactAvailabilitySummaryModel
    ) {
        return (
            this.props.contactsSelected.filter(
                (c) => c.contact.userId === cam.contact.userId
            ).length > 0
        );
    }

    get days() {
        return this.props.days;
    }

    get isDataStale() {
        return this.props.stale;
    }

    comparitors = {
        Alphabetical: (
            a: ContactAvailabilitySummaryModel,
            b: ContactAvailabilitySummaryModel
        ) => {
            {
                if (a.contact.fullName < b.contact.fullName) {
                    return -1;
                }
                if (a.contact.fullName > b.contact.fullName) {
                    return 1;
                }
                return 0;
            }
        },
        'Last updated': (
            a: ContactAvailabilitySummaryModel,
            b: ContactAvailabilitySummaryModel
        ) => {
            {
                const aDate = moment(a.contact.lastAvailabilityUpdateOn || Utilities.dateSub(new Date(), "year", 10));
                const bDate = moment(b.contact.lastAvailabilityUpdateOn || Utilities.dateSub(new Date(), "year", 10));

                if (!b.contact.lastAvailabilityUpdateOn) {
                    return -1;
                }
                if (aDate.valueOf() > bDate.valueOf()) {
                    return -1;
                }
                if (aDate.valueOf() < bDate.valueOf()) {
                    return 1;
                }
                return 0;
            }
        },
        Availability: (
            a: ContactAvailabilitySummaryModel,
            b: ContactAvailabilitySummaryModel
        ) => {
            {
                if (
                    a.occupancy.totalOccupancyPercentage <
                    b.occupancy.totalOccupancyPercentage
                ) {
                    return -1;
                }
                if (
                    a.occupancy.totalOccupancyPercentage >
                    b.occupancy.totalOccupancyPercentage
                ) {
                    return 1;
                }
                return 0;
            }
        }
    };

    sortByLastUpdated(a, b) {
        const aSeconds = a.contact.lastAvailabilityUpdateOn ? new Date(a.contact.lastAvailabilityUpdateOn).getTime() : 1000000000000;
        const bSeconds = b.contact.lastAvailabilityUpdateOn ? new Date(b.contact.lastAvailabilityUpdateOn).getTime() : 1000000000000;
        return aSeconds > bSeconds ? -1 : 1
    }

    get contacts() {
        const lastContacts = this.state.lastContacts;
        if (lastContacts.length !== this.props.contacts.length) {
            this.setState({
                lastContactMarker: lastContacts.length,
                lastContacts: this.props.contacts
            }, () => {
                const el = (window as any).justHotLoaded ? document.querySelector("#availability-table .contact:first-child") : document.getElementById("last-contact-marker");
                console.log(el);
                setTimeout(() => {
                    (window as any).justHotLoaded = false;
                }, 1000)
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                }
                setTimeout(() => {
                    this.setState({
                        lastContactMarker: 0
                    })
                }, 1500)
            })
        }
        return this.props.contacts.filter(
            (c) =>
                !this.state.filterValue ||
                ~c.contact.fullName
                    .toLowerCase()
                    .indexOf(this.state.filterValue.toLowerCase())
        );
    }

    handleStartDateChange = (date: Date) => {
        date.setUTCHours(0, 0, 0, 0);
        this.props.contactAvailabilityActions.changeStartDate(moment(date));
        this.setState({ calendarIsOpen: false });
        InternalTracker.trackEvent("Availability Filter Changed", {
            type: "start",
            value: date.toString()
        })

        document.body.click();
    }

    toggleCalendar = (e) => {
        e && e.preventDefault();
        this.setState({ calendarIsOpen: !this.state.calendarIsOpen });
    }

    render() {
        const showShowcaseContacts = window.location.pathname.startsWith("/external") && !window.location.pathname.includes("/rota/worker") && this.props.loaded && this.props.contacts.length === 0;
        const userIsAgency = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user") || "").organisationIsAgency
        const haveDemoWorkers = this.contacts.find(c => DEMO_ACCOUNTS.indexOf(c.contact.userId) !== -1);
        const params = this.props.searchParams;

        return (
            <ContactRemovalProvider
                displayConfirmationModal={this.state.workerToRemove != null}
                closeConfirmationModal={
                    () => {
                        this.setState({
                            lastRemovedContactId: this.state.workerToRemove ? this.state.workerToRemove.contact.userId : null
                        }, () => {
                            this.closeRemoveContactsConfirmationModal();
                        })
                    }
                }
                workersToRemove={
                    this.state.workerToRemove ? [this.state.workerToRemove] : []
                }
                onSuccess={() => {
                    console.log(this.state.lastRemovedContactId);
                    if (this.state.lastRemovedContactId) {
                        this.setState({
                            hiddenWorkers: [...new Set([
                                ...this.state.hiddenWorkers,
                                this.state.lastRemovedContactId
                            ])]
                        })
                    }
                    // this.refreshGrid();
                }}
                render={() => (
                    <React.Fragment>
                        { (this.state.referredContacts) &&
                            <ReferContactModal
                                contacts={this.state.referredContacts}
                                onClose={() => {
                                    this.setState({
                                        referredContacts: null
                                    })
                                    this.refreshGrid();
                                }}
                            />
                        }
                        { haveDemoWorkers &&
                            <button 
                                onClick={() => {
                                    InternalTracker.trackEvent("Removed Demo Contacts");
                                    AvailabilityAPI.removeWorkers(['bots'], false)
                                    .then(() => {
                                        toast.success(`All Demo Contacts have been Removed`);
                                        this.refreshGrid();
                                    })
                                    .catch(() => {
                                        toast.error(`Failed to remove Demo Contacts`);
                                    });
                                }}
                                style={{
                                    border: "none",
                                    background: theme.colours.red2,
                                    width: "100%",
                                    color: "white",
                                    padding: 8
                                }}
                            >
                                Tap to Remove All Demo Contacts
                            </button>
                        }
                        { (window.location.pathname.includes("/worker")) &&
                            <div className='timepreset-select' style={{ visibility: 'hidden' }}>
                                <span 
                                    data-selected={this.state.selectedWorkerPreset === "__default"}
                                    onClick={() => {
                                        this.setState({
                                            selectedWorkerPreset: "__default"
                                        })
                                    }}
                                >
                                    {Utilities.formatPreset({ name: "Default", startHour: 8, startMinute: 30, endHour: 15, endMinute: 30 })}
                                </span>
                                {/* {this.contacts && this.contacts.length > 0 && this.contacts[0].contact.timePresets && JSON.parse(this.contacts[0].contact.timePresets).map((preset) => {
                                    return (
                                        <span 
                                            data-selected={preset.name === this.state.selectedWorkerPreset}
                                            onClick={() => {
                                                this.setState({
                                                    selectedWorkerPreset: preset.name
                                                }, () => {
                                                    this.props.contactAvailabilityActions.changeTimePreset(preset);
                                                })
                                            }}
                                        >
                                            {Utilities.formatPreset(preset)}
                                        </span>
                                    )
                                })} */}
                            </div>
                        }
                        {(this.props.loaded || true) && (
                            <TableWrapper style={this.props.joyrideShowing ? { overflow: "hidden" } : {}}>
                                <table className="table table-striped table-responsive" id="availability-table">
                                    <thead>
                                        <tr>
                                            { (!window.location.pathname.includes("/worker")) &&
                                                <th 
                                                    className="text-center col-name"
                                                    style={{
                                                        minHeight: 67
                                                    }}
                                                >
                                                    <button
                                                        className='base-button block'
                                                        id="configure-view-btn"
                                                        onClick={() => {
                                                            InternalTracker.trackEvent("Availability Configure View Started")
                                                            this.setState({
                                                                configureViewModal: true
                                                            })
                                                        }}
                                                        style={{
                                                            display: "none"
                                                        }}
                                                    >Configure View</button>
                                                    { (!window.location.pathname.includes("/worker")) ?
                                                        <div className="filter start-date">
                                                            <div className="single-select">
                                                                <span
                                                                    data-selected={true}
                                                                >
                                                                    <OverlayTrigger
                                                                        rootClose
                                                                        container={document.getElementById(
                                                                            'calendar-toggle'
                                                                        )}
                                                                        trigger="click"
                                                                        placement="bottom"
                                                                        overlay={
                                                                            <Popover
                                                                                id="daypicker-popover"
                                                                                className="popover-no-margin"
                                                                            >
                                                                                <DayPickerWrapper>
                                                                                    <DayPicker
                                                                                        initialMonth={params.start.toDate()}
                                                                                        firstDayOfWeek={1}
                                                                                        selectedDays={params.start.toDate()}
                                                                                        onDayClick={
                                                                                            this.handleStartDateChange.bind(this)
                                                                                        }
                                                                                        onTodayButtonClick={
                                                                                            this.handleStartDateChange.bind(this)
                                                                                        }
                                                                                        todayButton="Today"
                                                                                        onBlur={this.toggleCalendar.bind(this)}
                                                                                    />
                                                                                </DayPickerWrapper>
                                                                            </Popover>
                                                                        }
                                                                    >
                                                                        <Button
                                                                            bsStyle="primary"
                                                                            bsSize="xsmall"
                                                                            onClick={this.toggleCalendar}
                                                                            ref={this.calendarToggle}
                                                                            style={{
                                                                                background: "none",
                                                                                color: "white",
                                                                                border: "none",
                                                                                fontSize: "1em"
                                                                            }}
                                                                        >
                                                                            {params.start.format(DateFormats.ShortDate)}
                                                                            <i 
                                                                                className="fa fa-calendar"
                                                                                style={{
                                                                                    marginLeft: 6
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                </span>
                                                                <span
                                                                    onClick={() => {
                                                                        this.handleStartDateChange(Utilities.dateAdd(this.props.searchParams.start.toDate(), "day", 1))
                                                                    }}
                                                                    className="skip-day"
                                                                >
                                                                    +1 Day
                                                                </span>
                                                                <span
                                                                    onClick={() => {
                                                                        this.handleStartDateChange(Utilities.dateAdd(this.props.searchParams.start.toDate(), "week", 1))
                                                                    }}
                                                                    className="skip-week"
                                                                >
                                                                    +1 Week
                                                                </span>
                                                                <span
                                                                    onClick={() => {
                                                                        this.handleStartDateChange(new Date())
                                                                    }}
                                                                    className="skip-day"
                                                                >
                                                                    Jump to Today
                                                                </span>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </th>
                                            }
                                            { (userIsAgency) &&
                                                <th style={{
                                                    wordBreak: "break-all",
                                                }}>Screening</th>
                                            }
                                            {this.renderDayColHeaders(
                                                this.days
                                            )}
                                            { (window.location.pathname.includes("/worker")) ?
                                                <th className="text-center col-match overallMatch">
                                                    Confidence Score
                                                </th>
                                                :
                                                <th className="text-center col-match overallMatch">
                                                    Overall Match
                                                </th>
                                            }
                                            <th className="text-center col-update">
                                                Last Update
                                            </th>
                                        </tr>
                                    </thead>
                                    { (localStorage.getItem("timesheetWorkersForLastPeriod") && !this.state.onlyShowContact && !showShowcaseContacts) ?
                                        <tbody>
                                            <React.Fragment>
                                                { (!window.location.pathname.includes("/worker")) &&
                                                    <tr>
                                                        <td 
                                                            colSpan={this.days.length + 3}
                                                            style={{
                                                                fontWeight: 600,
                                                                textAlign: "center",
                                                                fontSize: "1.2em",
                                                                padding: 16
                                                            }}
                                                        >
                                                            Contacts in the last Period
                                                        </td>
                                                    </tr> 
                                                }
                                                {this.contacts.filter(item => JSON.parse(localStorage.getItem("timesheetWorkersForLastPeriod") || "").indexOf(item.contact.userId) !== -1 ).sort(this.sortByLastUpdated).map((contact, contactI) =>
                                                    this.renderContactAndDays(
                                                        contact,
                                                        this.days,
                                                        undefined,
                                                        undefined,
                                                        contactI,
                                                        userIsAgency
                                                    )
                                                )}
                                                { (!window.location.pathname.includes("/worker")) &&
                                                    <tr>
                                                        <td 
                                                            colSpan={this.days.length + 3}
                                                            style={{
                                                                fontWeight: 600,
                                                                textAlign: "center",
                                                                fontSize: "1.2em",
                                                                padding: 16
                                                            }}
                                                        >
                                                            Other Contacts
                                                        </td>
                                                    </tr> 
                                                }
                                                {this.contacts.filter(item => JSON.parse(localStorage.getItem("timesheetWorkersForLastPeriod") || "").indexOf(item.contact.userId) === -1 ).map((contact, contactI) =>
                                                    this.renderContactAndDays(
                                                        contact,
                                                        this.days,
                                                        true,
                                                        true,
                                                        contactI,
                                                        userIsAgency
                                                    )
                                                )}
                                            </React.Fragment>
                                        </tbody>
                                        :
                                        <tbody>
                                            { (!showShowcaseContacts && this.props.loaded && this.contacts.filter(item => { return !this.state.onlyShowContact || (this.state.onlyShowContact && item.contact.userId === this.state.onlyShowContact) }).length === 0) &&
                                                <tr>
                                                    <td 
                                                        colSpan={8}
                                                        style={{
                                                            textAlign: 'center',
                                                            fontWeight: 700,
                                                            padding: '36px 0',
                                                            border: 'none',
                                                            fontSize: '1.2em'
                                                        }}
                                                    >
                                                        <div>
                                                            No contacts were found matching your search criteria
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            {!showShowcaseContacts && this.contacts.filter(item => { return !this.state.onlyShowContact || (this.state.onlyShowContact && item.contact.userId === this.state.onlyShowContact) }).map((contact, contactI) =>
                                                this.renderContactAndDays(
                                                    contact,
                                                    this.days,
                                                    undefined,
                                                    undefined,
                                                    contactI,
                                                    userIsAgency
                                                )
                                            )}
                                            {showShowcaseContacts && SHOWCASE_CONTACTS.map((contact, contactI) =>
                                                this.renderContactAndDays(
                                                    contact,
                                                    this.days,
                                                    undefined,
                                                    undefined,
                                                    contactI,
                                                    userIsAgency 
                                                )
                                            )}
                                            { (this.state.onlyShowContact && this.contacts.length > 1) &&
                                                <td 
                                                    colSpan={this.days.length + 3}
                                                    style={{
                                                        fontWeight: 600,
                                                        textAlign: "center",
                                                        fontSize: "1.2em",
                                                        padding: 16
                                                    }}
                                                    onClick={() => {
                                                        localStorage.removeItem("timesheetWorkerSelected");
                                                        InternalTracker.trackEvent("Availability Show All Contacts")
                                                        this.setState({
                                                            onlyShowContact: null
                                                        })
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            backgroundColor: theme.colours.blue2,
                                                            color: 'white',
                                                            padding: "8px 20px",
                                                            borderRadius: 52,
                                                            border: "none",
                                                        }}
                                                    >
                                                        Show all Contacts
                                                    </button>
                                                </td>
                                            }
                                        </tbody>
                                    }
                                </table>
                            </TableWrapper>
                        )}
                        <Spinner
                            view="availability"
                            text={localStorage.getItem("last-availability-search-order") === "2" ? "This might take a while" : this.contacts && this.contacts.length > 0 ? "Loading next page" : "Loading Availability"}
                            hide={this.props.loaded}
                        />
                        <Modal
                            show={this.state.configureViewModal}
                            onHide={() => { this.setState({ configureViewModal: false }) }}
                        >
                            <div
                                style={{
                                    padding: 20
                                }}
                            >
                                <h2
                                    style={{
                                        textAlign: 'center',
                                        margin: '0 0 12px 0',
                                        fontWeight: 700,
                                        fontSize: '1.2em'
                                    }}
                                >
                                    Select what to show
                                </h2>
                                <div className='options'>
                                    { [
                                        { 
                                            id: "image",
                                            label: "Image",
                                            icon: "fas fa-image"
                                        },
                                        { 
                                            id: "verificationStatus",
                                            label: "Verification Status",
                                            icon: "fas fa-shield-alt"
                                        },
                                        { 
                                            id: "headline",
                                            label: "Headline",
                                            icon: "fas fa-address-card"
                                        },
                                        { 
                                            id: "privateComment",
                                            label: "Private Comment",
                                            icon: "fas fa-lock"
                                        },
                                        { 
                                            id: "publicComment",
                                            label: "Public Comment",
                                            icon: "fas fa-comment-alt"
                                        },
                                        { 
                                            id: "ownRating",
                                            label: "Your Rating",
                                            icon: "fas fa-star"
                                        },
                                        { 
                                            id: "avgRating",
                                            label: "Average Rating",
                                            icon: "fas fa-user-friends"
                                        },
                                        { 
                                            id: "nudge",
                                            label: "Nudge",
                                            icon: "fa fa-hand-point-right"
                                        },
                                        { 
                                            id: "chat",
                                            label: "Chat",
                                            icon: "fa fa-comments-o"
                                        },
                                        { 
                                            id: "offer",
                                            label: "Offer",
                                            icon: "fas fa-briefcase-medical"
                                        },
                                        { 
                                            id: "remove",
                                            label: "Remove",
                                            icon: "fa fa-user-times"
                                        },
                                        { 
                                            id: "calendar",
                                            label: "Calendar",
                                            icon: "fas fa-calendar-alt"
                                        },
                                        {
                                            id: "representedBy",
                                            label: "Represented By",
                                            icon: "fas fa-people-arrows"
                                        },
                                        {
                                            id: "distance",
                                            label: "Distance",
                                            icon: "fas fa-map-marker-alt"
                                        }
                                    ].map(item => {
                                        return (
                                            <div 
                                                className='option'
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'left',
                                                    fontSize: '1.1em'
                                                }}
                                            >
                                                <input
                                                    aria-label={"show" + item.id}
                                                    type="checkbox"
                                                    id={item.id}
                                                    name={"show" + item.id}
                                                    onChange={() => {
                                                        let componentViewPreferencesUpdated = this.state.componentViewPreferences;
                                                        componentViewPreferencesUpdated[item.id] = componentViewPreferencesUpdated[item.id] === false ? true : false;
                                                        this.setState({
                                                            componentViewPreferences: componentViewPreferencesUpdated
                                                        })
                                                        localStorage.setItem("availabilityViewPreferences", JSON.stringify(componentViewPreferencesUpdated))
                                                    }}
                                                    checked={this.state.componentViewPreferences[item.id] !== false}
                                                    style={{
                                                        margin: 0
                                                    }}
                                                />
                                                <i 
                                                    className={item.icon}
                                                    style={{
                                                        margin: '0 12px'
                                                    }}
                                                />
                                                <label 
                                                    className="m-l-xs" 
                                                    htmlFor={"show" + item.id}
                                                    style={{
                                                        margin: 0
                                                    }}
                                                >
                                                    {item.label}
                                                </label>
                                            </div>
                                        )
                                    }) }
                                    <button 
                                        className='main base-button block'
                                        style={{
                                            marginTop: 15
                                        }}
                                        onClick={() => {
                                            InternalTracker.trackEvent("Availability Configure View Saved", this.state.componentViewPreferences)
                                            this.setState({ configureViewModal: false })
                                        }}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </Modal>
                        { (this.state.openContactId) &&
                            <ContactProfileModal
                                // @ts-ignore
                                contactId={this.state.openContactId}
                                onClosed={() => {
                                    this.setState({
                                        openContactId: ""
                                    })
                                }}
                            />
                        }
                    </React.Fragment>
                )}
            />
        );
    }
}

const ForceWidth = (width: number) => `
    width: ${width}px !important;
    min-width: ${width}px !important;
    max-width: ${width}px !important;
`;

/**
 * Refer to the following for information about sticky table headers
 * https://css-tricks.com/position-sticky-and-table-headers/
 * https://stackoverflow.com/questions/50361698/px;
 * https://uxdesign.cc/position-stuck-96c9f55d9526
 */
const TableWrapper = styled.div`
    /* Enables sticky scrolling headers when scrolling within the container */
    max-height: 100%;
    overflow-x: auto;
    max-height: calc(100vh - 280px);

    .start-date {

        span {
            background-color: ${theme.colours.blue2} !important;
            color: white;
            border-radius: 4px;
            padding: 4px 8px;
            font-size: 13px;
            display: inline-block;
            margin: 0 4px 4px 0;
            cursor: pointer;

            &:not(:first-child) {
                padding: 4.5px 8px;
            }
        }

        button {
            padding: 0;
        }
    }

    @media print {
        max-height: none !important;
        overflow-x: visible !important;
        width: 100% !important;
        .overallMatch {
            display: none !important;
        }
    }

    th {
        position: sticky !important;
        top: 0;
        //z-index: 1;
        background: white;
    }

    table {
        margin-bottom: 0;
        border-collapse: separate;
        border: none;
        position: relative;

        thead {
            position: sticky;
            top: 0;
            z-index: 1;
        }

        .rating-comment {
            overflow: hidden;
            text-overflow: ellipsis;
            height: 22px;
            margin-top: 2px;

            i {
                margin-right: 8px
            }

            &:hover {
                // background-color: #e8e8e8;
                height: unset;
                // padding: 7px 12px;
                // margin: 8px 0 4px 12px;
                // border-radius: 6px;
            }
        }

        label {
            font-weight: 400;
            padding-left: 12px;
            padding-top: 4px;
            margin: 0;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .demo-account, .hot-loaded {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: ${theme.colours.blue2};
            color: white;
            text-align: center;
            padding: 3px 0;

            &.hot-loaded {
                background: ${theme.colours.green2};
            }
        }

        .own-verifications {
            text-align: center;

            i {
                font-size: 16px;
                display: block;

                &[data-active="true"] {
                    color: ${theme.colours.green2};

                    &[data-type="4"] {
                        color: ${theme.colours.red2};
                    }
                }

                &[data-active="false"] {
                    color: ${theme.colours.amber};

                    &[data-type="4"] {
                        color: ${theme.colours.grey};
                    }
                }

                &:not(:last-child) {
                    margin-bottom: 6px;
                }
            }
        }

        .col-name {
            min-height: 54px;
            display: flex;
            position: relative;
            min-width: 200px;

            @media print {
                min-width: 150px !important;
            }
        }

        .right {
            position: relative;

            a {
                margin: 0;
            }

            .multi-stars-wrapper {
                padding-left: 12px;
                padding-top: 4px;
            }

            .contact-skills {
                padding-left: 12px;
                margin: 6px 0 -12px 0;

                &>div {
                    white-space: nowrap;
                    border-radius: 5px;
                    background: ${theme.colours.blue2};
                    display: inline-block;
                    padding: 2px 8px;
                    overflow: hidden;
                    margin: 0 8px 6px 0;
                    color: white;

                    &.contact-distance {
                        background: ${theme.colours.darkBlue};
                    }
                }
            }

            .fa-shield {
                position: absolute;
                font-size: 24px;
                top: -3px;
            }

            strong, label {
                padding-left: 12px;
                display: block;
            }

            strong {
                padding-left: 36px;
                overflow: hidden;
            }

            .react-stars {
                padding-top: 2px;
            }

            .represented-by {
                display: inline-flex;

                &>div {
                    margin-right: 5px;

                    .rep-img {
                        width: 22px !important;
                        height: 22px !important;
                        border-radius: 100%;
                        display: block;
                    }
    
                    &:last-child {
                        margin-right: 0
                    }
                }
            }

            .verification-tooltip {

                width: 22px !important;
                height: 22px !important;
            }

            .verifications {
                display: inline-flex;

                &>div {
                    position: relative;

                    &>img {
                        width: 22px !important;
                        height: 22px !important;
                        border-radius: 100%;
                        display: block;
                        margin-left: 5px;
                    }

                    .status {
                        position: absolute;
                        padding: 2px;
                        border-radius: 100%;
                        bottom: -4px;
                        right: -4px;
                        width: 14px;
                        height: 14px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    
                        i {
                            font-size: 10px;
                            color: white;
                            position: relative;
                            top: unset !important;
                            left: unset !important;
                        }

                        &[data-status="3"] {
                            background: ${theme.colours.green2};
                        }

                        &[data-status="4"] {
                            background: ${theme.colours.red2};
                        }

                        &[data-status="10"] {
                            background: ${theme.colours.amber};
                        }
                    }
                }
            }

            & > div {
                padding: 12px 0px 0px 12px;
                display: flex;
                align-items: center;
                justify-content: left;
                flex-wrap: wrap;
            }
        }

        .clearfix {
            text-align: center;
             
            &>div {
                background: #666;
                color: white;
                border: none;
                padding: 4px 10px;
                border-radius: 8px;
                font-size: 1em;
                cursor: pointer;
                display: inline-block;
                margin: 0 6px 6px 0;

                i {
                    color: white !important;
                    opacity: 1 !important;
                    margin: 0 !important;
                }

                &[data-selected="true"] {
                    background: ${theme.colours.blue2};
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .col-date {

            .time {
                display: block;
                font-size: 9px;
                margin-top: 4px;
                background: ${theme.colours.darkBlue};
                color: white;
                padding: 2px;
                border-radius: 3px;
            }

            div {
                text-align: center;

                i {
                    display: block;
                }

                span {
                    display: block;
                    font-size: 0.96em;
                    font-weight: 500;
                    margin-top: 2px;
                }
            }

            &.weekend {
                background-color: #ffeab3;
                
                &.skip-weekend {
                    writing-mode: tb-rl;
                    text-orientation: upright;
                    padding: 0;
                    font-size: 10px;
                }
            }
        }

        .col-match, .col-date {
            ${ForceWidth(74)}
            font-size: 11px;
            line-height: 12px;
            vertical-align: middle;

            &.skip-weekend {
                ${ForceWidth(10)};
                background-color: #ffeab3;
            }
        }

        .col-update {
            font-size: 11px;
            line-height: 12px;
            vertical-align: middle;
            ${ForceWidth(74)}
        }

        .dropdown {
            display: none;
        }
    }

    .contact {
        position: relative;

        #last-contact-marker {
            position: absolute;
            top: -38px;
            width: 100%;
            background: ${theme.colours.blue2};
            padding: 10px;
            text-align: center;
            z-index: 10;
            color: white;
        }

        &[data-already-invited="true"] {

            .already-invited-warning {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgb(200 61 61 / 70%);
                color: white;
                font-size: 18px;
                padding: 20px;
                font-weight: 700;

                i {
                    margin-right: 20px;
                }
            }
        }
    }

    @media (max-width: 1100px) {
        table {
            .col-name {
                min-width: 170px;
                position: relative;

                &>img {
                    position: absolute;
                    left: 8px;
                    top: 8px;
                }

                .fa-shield {
                    font-size: 16px;
                    top: 10px !important;
                    left: 25px !important;
                }

                strong {
                    padding-left: 44px;
                    height: 38px;
                    padding-right: 22px;
                    text-align: left;
                }
            }

            .right .multi-stars-wrapper {
                padding-left: 0;
            }

            .dropdown {
                display: block;
                font-size: 10px;
                margin-top: 2px;
                position: absolute;
                right: 6px;
                top: 18px;
                margin: 0;

                &>button {
                    padding: 0px 4px;
                }
            }
            
            .contact {
                position: relative;

                i {
                    top: 28px !important;
                    left: 28px !important;
                }

                & > td:first-child {
                    display: block;
                    
                    &>img {
                        width: 36px !important;
                        height: 36px !important;
                    }

                    label {
                        -webkit-line-clamp: 3;
                        padding-left: 0;
                        width: 100%;
                        max-height: 55px;
                    }

                    .contact-skills {
                        padding-left: 0;
                    }

                    .contact-skills, label {
                        font-size: 12px;
                        text-align: left;

                        div {
                            overflow: hidden;
                            max-width: 144px;

                            &:not(:last-child) {
                                margin-bottom: 3px;
                            }
                        }
                    }

                    .clearfix {
                        display: none;
                    }

                    .react-stars {
                        padding-left: 0;
                    }
                }
            }            
        }
    }

    /* Borders */
    table th {
        border-top: 1px solid #ddd !important;
        border-right: 1px solid #ddd !important;
        border-bottom: 1px solid #ddd !important;
    }

    table td {
        border-right: 1px solid #ddd !important;
        border-bottom: 1px solid #ddd !important;
    }

    table th:first-child,
    table td:first-child {
        border-left: 1px solid #ddd !important;
    }
`;

const DayPickerWrapper = styled.div`
    .DayPicker-wrapper {
        z-index: 2;
        padding-bottom: 0;
        background: white;
    }

    .DayPicker-Footer {
        padding: 5px 0;
        font-weight: bold;
        text-align: center;
        background: #f0f0f0;
        border-top: 1px solid #aeaeae;
        cursor: pointer;

        button {
            width: 100%;
            color: #000;
        }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: #34495e;
    }
`;

export default connect(
    (state: ApplicationState) => state.contactAvailability,
    (dispatch) => {
        return {
            contactAvailabilityActions: bindActionCreators(
                ContactAvailabilityStore.actionCreators,
                dispatch
            )
        };
    }
)(AvailabilityGrid);
