/* eslint-disable */
import afetch from '../AuthenticatedFetch';
import AppConfig from '../../components/config/Config';
import { OrganisationSyncResponse } from './ResponseTypes';
import UnauthenticatedFetch from 'src/api/UnauthenticatedFetch';

const apiUrls = {
    sync(lastSynced: number) {
        return `${AppConfig.Settings.api.externalUri}/organisationsync?lastSyncTimestamp=${lastSynced}`;
    }
};

const apiCalls = {
    sync(lastSynced: number) {
        return afetch.request<null, OrganisationSyncResponse>(
            apiUrls.sync(lastSynced),
            'GET',
            null,
            'Unable to get latest updates'
        );
    }
};

export default apiCalls;
